import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BusinessInsert} from "../models/businessinsert";
import {BusinessRevContent} from "../models/businessReview";
import {BusinessMicroPage} from "../models/businessMicroPage";
import {BusinessThreshold} from "../models/businessThreshold";
import {BusinessRevSetUp} from "../models/businessRevSetUp";
import {environment} from "../models/enviroment";

@Injectable({
  providedIn: 'root'
})
export class BusinessesService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get(environment.appUrl + `/api/businesses`);
  }

  getCoords(address: string) {
    return this.http.get(environment.appUrl + `/api/businesses/getcoords/${address}`);
  }

  register(business: BusinessInsert) {
    return this.http.post(environment.appUrl + '/api/businesses/new', business);
  }

  update(business: BusinessInsert) {
    return this.http.put(environment.appUrl + '/api/businesses/edit', business);
  }

  delete(id: number) {
    return this.http.delete(environment.appUrl + `/api/businesses/delete/${id}`);
  }

  show(id: number) {
    return this.http.get(environment.appUrl + `/api/businesses/show/${id}`);
  }

  getCurrent() {
    return this.http.get(environment.appUrl + `/api/businesses/mybusiness`);
  }

  getByKey(token: string) {
    return this.http.get(environment.appUrl + `/api/businesses/getbykey/${token}`);
  }

  updateReviewContent(business: BusinessRevContent) {
    return this.http.put(environment.appUrl + '/api/businesses/editrevcontent', business);
  }

  updateMicroPage(business: BusinessMicroPage) {
    return this.http.put(environment.appUrl + '/api/businesses/editmicropage', business);
  }

  updateThreshold(business: BusinessThreshold) {
    return this.http.put(environment.appUrl + '/api/businesses/editthreshold', business);
  }

  updateReviewSetUp(business: BusinessRevSetUp) {
    return this.http.put(environment.appUrl + '/api/businesses/editlinks', business);
  }

  updateSocialSharing(urls: string[]) {
    return this.http.put(environment.appUrl + '/api/businesses/editsocialsharing', urls);
  }

  addSocialSharing(form: string[]) {
    return this.http.put(environment.appUrl + '/api/businesses/addsocialsharing', form);
  }

  loadExtReviewPage(option: string, token: string) {
    return this.http.get(environment.appUrl + `/api/businesses/loadextpage/${option}/${token}`);
  }

  loadAlert(type: string) {
    return this.http.get(environment.appUrl + `/api/businesses/loadalert/${type}`);
  }

  storeAlert(alert) {
    return this.http.post(environment.appUrl + '/api/businesses/alerts/store', alert);
  }

  getGroups() {
    return this.http.get(environment.appUrl + `/api/business/groups`);
  }

  createGroup(params: {agency_id: number, name: string}) {
    return this.http.post(environment.appUrl + `/api/business/create-group`, params);
  }

  updateGroup(params: {name: string}, groupId: number) {
    return this.http.put(environment.appUrl + `/api/business/update-group/` + groupId, params);
  }

  deleteGroup(groupId: number, businessId = '') {
    return this.http.delete(environment.appUrl + `/api/business/update-group/` + groupId + '/' + businessId);
  }
}
