import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as $ from "jquery";
import {first} from "rxjs/operators";
import {EmailTemplatesService} from "../_services/email-templates.service";
import {Emailtemplate} from "../models/emailtemplate";
import {NewCustomerComponent} from "../new-customer/new-customer.component";
import {NewEmailTemplateComponent} from "../new-email-template/new-email-template.component";
import Swal from "sweetalert2";
import {environment} from "../models/enviroment";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent implements OnInit, OnDestroy {

  @ViewChild(NewEmailTemplateComponent, null) newCustomerModal: NewEmailTemplateComponent;

  templates: Emailtemplate[] = [];
  table = null;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions = {};
  dtTrigger = new Subject();

  constructor(private templService: EmailTemplatesService) { }

  ngOnInit() {
    this.loadEmailTemplates();
    this.loadWindowFunctions();
    this.dtOptions = {
      // Declare the use of the extension in the dom parameter
      dom: 'LBlfrtip',
      // Configure the buttons
      buttons: [
        'copy',
        'print',
        {
          extend: 'excelHtml5',
          title: 'email-templates-excel',
        },
      ],
      responsive: true,
    };
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  openModal() {
    $('.new-template-modal').modal('show');
  }

  get self(): TemplatesComponent {
    return this;
  }

  deleteAll() {
    let todelete = [];
    $('.template_check:checkbox:checked').each(function() {
      todelete.push(parseInt(this.id.split('_')[1]));
    });
    console.log(todelete);
    Swal.queue([{
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/templates/delete-sel', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({selected: todelete}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your email templates has been deleted.'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to delete your email templates.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0])
        this.loadEmailTemplates();
    });
  }

  deleteTemplate(id: string) {
    console.log('delete in', id);

    Swal.queue([{
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/templates/delete/' + id, {
          method: 'get',
          headers: new Headers({
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
        }).then(data => {
          console.log(data);
          console.log(JSON.parse(localStorage.currentUser).token);
          Swal.insertQueueStep({
            type: 'success',
            title: 'Your email template has been deleted.'
          });
        })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to delete your email template.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0])
        this.loadEmailTemplates();
    });
  }

  editTemplate(id: number) {
    console.log('edit in', id);

    this.newCustomerModal.loadTemplate(id);
    this.openModal();
  }

  loadWindowFunctions() {
    window['deleteTemplate'] = (id: string) => {
      console.log('delete in', id);

      Swal.queue([{
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(environment.appUrl + '/api/templates/delete/' + id, {
            method: 'get',
            headers: new Headers({
              Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
            }),
          }).then(data => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your email template has been deleted.'
            });
          })
            .catch((error) => {
              console.log(error);
              Swal.insertQueueStep({
                type: 'error',
                title: 'Unable to delete your email template.'
              });
            });
        }
      }]).then((result) => {
        if (result.value[0])
          this.loadEmailTemplates();
      });

    };
    window['editTemplate'] = (id: number) => {
      console.log('edit in', id);

      this.newCustomerModal.loadTemplate(id);
      this.openModal();
    };
  }

  checkAll() {
    console.log('in');
    if ($('#checkalltemplates')[0]['checked']) {
      console.log('in true');
      $('.template_check').each(function() {
        $(this).attr('checked', 'true');
      });
    } else {
      console.log('false');
      $('.template_check').each(function() {
        $(this).removeAttr('checked');
      });
    }
  }

  loadEmailTemplates() {
    $('.preloader').show();
    this.templService.getAll().pipe(first())
      .subscribe(
        data => {
          if (data && data['data'])
            this.templates = data['data'];
          console.log('success', this.templates);

          if (this.table !== null) {
            this.table.destroy();
          }

          if (this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              // Destroy the table first
              dtInstance.destroy();
              // Call the dtTrigger to rerender again
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
          console.log('dtOptions', this.dtOptions);
          $('.preloader').hide();
        },
        error => {
          console.log('error', error);
          $('.preloader').hide();
        }
      );
  }

}
