import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Emailtemplate} from "../models/emailtemplate";
import {environment} from "../models/enviroment";

@Injectable({
  providedIn: 'root'
})
export class EmailTemplatesService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Emailtemplate[]>(environment.appUrl + `/api/templates`);
  }

  register(template: Emailtemplate) {
    return this.http.post(environment.appUrl + `/api/templates/new`, template);
  }

  update(template: Emailtemplate) {
    return this.http.put(environment.appUrl + '/api/templates/edit', template);
  }

  delete(id: number) {
    return this.http.delete(environment.appUrl + `/api/templates/delete/${id}`);
  }

  show(id: number) {
    return this.http.get(environment.appUrl + `/api/templates/show/${id}`);
  }

  showFromName(name: string) {
    return this.http.get(environment.appUrl + `/api/templates/showname/${name}`);
  }

  replaceHtml(html) {
    return this.http.post(environment.appUrl + `/api/templates/replace`, html);
  }

}
