import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import * as $ from "jquery";
import {first} from "rxjs/operators";
import {environment} from "../models/enviroment";
import Swal from "sweetalert2";
import {EmailTemplatesService} from "../_services/email-templates.service";
import * as moment from 'moment';

@Component({
  selector: 'app-email-records',
  templateUrl: './email-records.component.html',
  styleUrls: ['./email-records.component.css']
})
export class EmailRecordsComponent implements OnInit, OnDestroy {

  emails = [];
  spinner = false;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions = {};
  dtTrigger = new Subject();

  daterange: any = null;

  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  public filters: { [key: string]: Object; }[] = [
    { Name: 'initial_feedback', Code: 'initial_feedback' },
    { Name: 'feedback_reminder', Code: 'feedback_reminder' },
    { Name: 'positive_feedback', Code: 'positive_feedback' },
    { Name: 'negative_feedback', Code: 'negative_feedback' },
    { Name: 'positive_email', Code: 'positive_email' },
    { Name: 'negative_email', Code: 'negative_email' },
    { Name: 'positive_feedback_quick', Code: 'positive_feedback_quick' },
    { Name: 'negative_feedback_quick', Code: 'negative_feedback_quick' },
  ];
  public localFields: Object = { text: 'Name', value: 'Code' };
  public localWaterMark: string = 'Filter by template ...';

  public filters2: { [key: string]: Object; }[] = [
      { Name: 'Success', Code: 'Success' },
      { Name: 'Failed', Code: 'Failed' },
    ];
  public localFields2: Object = { text: 'Name', value: 'Code' };
  public localWaterMark2: string = 'Filter by status ...';



  constructor(
    private httpClient: HttpClient,
    private alertService: AlertService,
    private ngZone: NgZone,
    private tmplService: EmailTemplatesService,
  ) { }

  ngOnInit() {
    this.dtOptions = {
      // Declare the use of the extension in the dom parameter
      dom: 'LBlfrtip',
      // Configure the buttons
      buttons: [
        'copy',
        'print',
        {
          extend: 'excelHtml5',
          title: 'email-records-excel',
        },
      ],
      responsive: true,
    };
    this.loadEmailRecords();
  }

  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') );
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  deleteRecord(id: string) {
    console.log('delete in', id);

    Swal.queue([{
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/records/delete/email-record/' + id, {
          method: 'get',
          headers: new Headers({
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
        }).then(data => {
          console.log(data);
          console.log(JSON.parse(localStorage.currentUser).token);
          Swal.insertQueueStep({
            type: 'success',
            title: 'Your email record has been deleted.'
          });
        })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'We are unable to delete your record. Please try again later!'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0]) {
        this.loadEmailRecords();
      }
    });
  }

  showPreview(html: string) {
    const previewOpen = window.open('', 'previewOpen', 'width=700, height=500');
    previewOpen.document.body.innerHTML = html;
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  deleteAll() {
    let todelete = [];
    $('.email_check:checkbox:checked').each(function() {
      todelete.push(parseInt(this.id.split('_')[1]));
    });
    console.log(todelete);
    Swal.queue([{
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/records/email/delete-sel', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({selected: todelete}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your email records has been deleted.'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'We are unable to delete your email records. Please try again later.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0]) {
        this.loadEmailRecords();
      }
    });
  }

  checkAll() {
    console.log('in');
    if ($('#checkallrecords')[0]['checked']) {
      console.log('in true');
      $('.email_check').each(function(){
        $(this).attr('checked', 'true');
      });
    } else {
      console.log('false');
      $('.email_check').each(function(){
        $(this).removeAttr('checked');
      });
    }
  }

  refreshRecords(){
    this.ngZone.run( () => {
      this.spinner = true;
    });
    let status = []; let text = ''; let templ = [];
    $('#options_status option:selected').each(function () {
      status.push($(this).val());
    });
    $('#options_template option:selected').each(function () {
      templ.push($(this).val());
    });
    text = $('#searchrecord').val().toString();
    let filters = {
      text: text,
      dates: this.daterange,
      status: status,
      template: templ,
    };
    this.httpClient.post(environment.appUrl + '/api/records/business/emails/business/filters', filters).pipe(first())
      .subscribe(
        data => {
          if (data && data['records'])
            this.emails = data['records'];
          console.log('success', this.emails);

          if (this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              // Destroy the table first
              dtInstance.destroy();
              // Call the dtTrigger to rerender again
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
          console.log('dtOptions', this.dtOptions);
        },
        error => {
          console.log('error', error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  loadEmailRecords() {
    $('.preloader').show();
    this.httpClient.get(environment.appUrl + '/api/records/business/emails/business').pipe(first())
      .subscribe(
        data => {
          if (data && data['records'])
            this.emails = data['records'];
          console.log('success', this.emails);

          if (this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              // Destroy the table first
              dtInstance.destroy();
              // Call the dtTrigger to rerender again
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
          console.log('dtOptions', this.dtOptions);
          $('.preloader').hide();
        },
        error => {
          console.log('error', error);
          $('.preloader').hide();
        }
      );
  }

}
