import {Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {first} from "rxjs/operators";
import {CategoriesService} from "../_services/categories.service";
import {BusinessesService} from "../_services/businesses.service";
import {Category} from "../models/category";
import {environment} from "../models/enviroment";
import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

@Component({
  selector: 'app-micro-page-setup',
  templateUrl: './micro-page-setup.component.html',
  styleUrls: ['./micro-page-setup.component.css']
})
export class MicroPageSetupComponent implements OnInit {

  public spinner = false;
  public encrypted = '';

  loading = false;
  submmited = false;
  microForm: FormGroup;

  categories: Category[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private catService: CategoriesService,
    private busService: BusinessesService,
    private ngZone: NgZone,
    private socialAuthService: AuthService,
  ) { }

  ngOnInit() {
    this.microForm = this.formBuilder.group({
      business_id: ['', []],
      email: ['', [Validators.required, Validators.email]],
      category: ['', Validators.required],
      description: ['', []],
      facebook: ['', []],
      monstart: ['00:00', []],
      monend: ['00:00', []],
      tuestart: ['00:00', []],
      tueend: ['00:00', []],
      wedstart: ['00:00', []],
      wedend: ['00:00', []],
      thustart: ['00:00', []],
      thuend: ['00:00', []],
      fristart: ['00:00', []],
      friend: ['00:00', []],
      satstart: ['00:00', []],
      satend: ['00:00', []],
      sunstart: ['00:00', []],
      sunend: ['00:00', []],
    });

    this.loadCategories();
    this.loadForm();
  }

  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.socialAuthService.signOut();
  }

  onSubmit() {
    this.submmited = true;
    if (this.microForm.invalid) {
      return;
    }
    this.loading = true;
    console.log(this.microForm.getRawValue());
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.busService.updateMicroPage(this.microForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Update Successful', false);
          this.loading = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  loadCategories() {
    this.catService.getAll().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.categories = data['data'];
        },
        error => {
          console.log(error);
        }
      );
  }

  loadForm() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.busService.getCurrent().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['business']) {
            let business = data['business'];
            this.microForm.controls.business_id.setValue(business.id);
            this.microForm.controls.category.setValue(business.business_category_id);
            this.microForm.controls.description.setValue(business.business_description);
            this.microForm.controls.email.setValue(business.email);
            let hours = business.business_hours;
            hours = JSON.parse(hours);
            console.log(hours);
            if (hours !== null) {
              this.microForm.controls.monstart.setValue(hours[0].split('-')[0]);
              this.microForm.controls.monend.setValue(hours[0].split('-')[1]);

              this.microForm.controls.tuestart.setValue(hours[1].split('-')[0]);
              this.microForm.controls.tueend.setValue(hours[1].split('-')[1]);

              this.microForm.controls.wedstart.setValue(hours[2].split('-')[0]);
              this.microForm.controls.wedend.setValue(hours[2].split('-')[1]);

              this.microForm.controls.thustart.setValue(hours[3].split('-')[0]);
              this.microForm.controls.thuend.setValue(hours[3].split('-')[1]);

              this.microForm.controls.fristart.setValue(hours[4].split('-')[0]);
              this.microForm.controls.friend.setValue(hours[4].split('-')[1]);

              this.microForm.controls.satstart.setValue(hours[5].split('-')[0]);
              this.microForm.controls.satend.setValue(hours[5].split('-')[1]);

              this.microForm.controls.sunstart.setValue(hours[6].split('-')[0]);
              this.microForm.controls.sunend.setValue(hours[6].split('-')[1]);
            }

            this.encrypted = "<a target='_blank' href='"+ environment.url +"/public/micropage/"+ data['encrypted'] +"'>"+ environment.url +"/public/micropage/" + data['encrypted'] + '</a>';

            $('#public_profile').html('<iframe src="'+ environment.url +"/public/micropage/"+ data['encrypted'] +"'>"+ environment.url +"/public/micropage/" + data['encrypted'] +'"></iframe>');
            $('#public_form').html('<iframe src="'+ environment.url +"/public/review/"+ data['encrypted'] +"'>"+ environment.url +"/public/micropage/" + data['encrypted'] +'"></iframe>');
            $('#public_review_list').html('<iframe src="'+ environment.url +"/public/reviews/"+ data['encrypted'] +"'>"+ environment.url +"/public/micropage/" + data['encrypted'] +'"></iframe>');
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  get f() { return this.microForm.controls; }

}
