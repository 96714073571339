import {Component, Input, NgZone, OnInit} from '@angular/core';
import {TemplatesComponent} from "../templates/templates.component";
import {NewEmailTemplateComponent} from "../new-email-template/new-email-template.component";
import * as $ from "jquery";
import {first} from "rxjs/operators";
import collect from "collect.js";
import {SocialMediasService} from "../_services/social-medias.service";
import {BusinessRevPagesComponent} from "../business-rev-pages/business-rev-pages.component";
import {PositiveReviewAlertComponent} from "../positive-review-alert/positive-review-alert.component";

@Component({
  selector: 'app-modal-merge-fields',
  templateUrl: './modal-merge-fields.component.html',
  styleUrls: ['./modal-merge-fields.component.css']
})
export class ModalMergeFieldsComponent implements OnInit {

  private _parent: NewEmailTemplateComponent ;
  @Input() set parent(value: NewEmailTemplateComponent ) {
    this._parent = value;
  }

  private _parent2: BusinessRevPagesComponent ;
  @Input() set parent2(value: BusinessRevPagesComponent ) {
    this._parent2 = value;
  }

  private _parent3: PositiveReviewAlertComponent ;
  @Input() set parent3(value: PositiveReviewAlertComponent ) {
    this._parent3 = value;
  }

  public spinner = false;
  public socialmedias = [];

  constructor(
    private socialService: SocialMediasService,
    private ngZone: NgZone,
    ) { }

  ngOnInit() {
    this.loadSocialMedias();
  }

  closeMergeFieldsModal() {
    $('.merge-fields:checkbox:checked').each(function() {
      $(this).parent().parent().html('<div class="custom-control custom-checkbox mr-sm-2">\n' +
        '                    <input type="checkbox" class="custom-control-input merge-fields" name="mergefields[]"  id="'+ this.id +'" value="check">\n' +
        '                    <label class="custom-control-label" for="'+ this.id +'"></label>\n' +
        '                  </div>');
    });
    $('.merge-fields-modal').modal('hide');
  }

  Insert(value: string) {
    if (this.parent) {
      if (this.parent.where === 'sms') {
        this.parent.templForm.controls.smscontent.setValue(this.parent.templForm.controls.smscontent.value + '\n' + value);
      } else {
        this.parent.templForm.controls.emailcontent.setValue(this.parent.templForm.controls.emailcontent.value + '\n' + value);
      }
    } else if(this.parent2) {
      if (this.parent2.where === 'positive') {
        this.parent2.contForm.controls.posreviewpage.setValue(this.parent2.contForm.controls.posreviewpage.value + '\n' + value);
      } else {
        this.parent2.contForm.controls.negreviewpage.setValue(this.parent2.contForm.controls.negreviewpage.value + '\n' + value);
      }
    } else {
      this.parent3.alertForm.controls.content.setValue(this.parent3.alertForm.controls.content.value + '\n' + value);
    }
  }

  InsertAllSelected() {
    let checked = [];
    $('.merge-fields:checkbox:checked').each(function(){
      checked.push(this.id);
    });
    console.log(checked);
    if (this.parent) {
      if (this.parent.where === 'sms') {
        for (let i in checked) {
          this.parent.templForm.controls.smscontent.setValue(this.parent.templForm.controls.smscontent.value + '\n' + '$' + checked[i]);
        }
      } else {
        for (let i in checked) {
          this.parent.templForm.controls.emailcontent.setValue(this.parent.templForm.controls.emailcontent.value + '\n' + '$' + checked[i]);
        }
      }
    } else if(this.parent2) {
      if (this.parent2.where === 'positive') {
        for (let i in checked) {
          this.parent2.contForm.controls.posreviewpage.setValue(this.parent2.contForm.controls.posreviewpage.value + '\n' + '$' + checked[i]);
        }
      } else {
        for (let i in checked) {
          this.parent2.contForm.controls.negreviewpage.setValue(this.parent2.contForm.controls.negreviewpage.value + '\n' + '$' + checked[i]);
        }
      }
    } else {
      for (let i in checked) {
        this.parent3.alertForm.controls.content.setValue(this.parent3.alertForm.controls.content.value + '\n' + checked[i]);
      }
    }

    this.closeMergeFieldsModal();
  }

  loadSocialMedias() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.socialService.getAllForRview().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          let arr = collect(data['medias']);
          if (data && data['data']) {
            for(let i in data['data']) {
              let value = arr.where('social_media_id', data['data'][i].id).first();
              if (typeof value !== 'undefined') {
                value = value['url'];
                this.socialmedias.push(data['data'][i]);
              } else {
                value = '';
              }
              console.log(value);
            }
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  get parent(): NewEmailTemplateComponent {
    return this._parent;
  }

  get parent2(): BusinessRevPagesComponent {
    return this._parent2;
  }

  get parent3(): PositiveReviewAlertComponent {
    return this._parent3;
  }

}
