import {Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry} from "ngx-file-drop";
import {AgencyService} from "../_services/agency.service";
import {first} from "rxjs/operators";
import * as $ from "jquery";
import {environment} from "../models/enviroment";

@Component({
  selector: 'app-white-label-settings',
  templateUrl: './white-label-settings.component.html',
  styleUrls: ['./white-label-settings.component.css']
})
export class WhiteLabelSettingsComponent implements OnInit {

  labelSettings: FormGroup;
  loading = false;
  submmited = false;
  public spinner = false;
  public headercolor = '';
  public barcolor = '';
  public bgroundcolor = '';

  filesubmmit = false;
  filepercent = 0;

  file = null;
  public imagePath;
  imgURL: any;
  public message: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private agencyService: AgencyService,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    console.log('label settings in');

    this.labelSettings = this.formBuilder.group({
      agency_id: ['', []],
      title: ['', []],
      description: ['', []],
      headercolor: ['', []],
      barcolor: ['', []],
      bgroundcolor: ['', []],
      favicon: ['', []],
      hidenicon: ['', []],
    });

    this.loadForm();
  }

  get f() { return this.labelSettings.controls; }

  onSubmit() {
    console.log('label submit');
    this.submmited = true;
    if (this.labelSettings.invalid) {
      return;
    }
    this.loading = true;
    console.log(this.labelSettings.getRawValue());

    this.agencyService.updateWhiteLabel(this.labelSettings.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Update Successful', false);
          this.ngZone.run( () => {
            this.loading = false;
            this.submmited = false;
          });
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.ngZone.run( () => {
            this.loading = false;
            this.submmited = false;
          });
        },
        () => {
          this.ngZone.run( () => {
            this.loading = false;
            this.submmited = false;
          });
        }
      );

  }

  loadForm() {
    this.spinner = true;
    this.agencyService.getCurrent().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['agency']) {
            let agency = data['agency'];
            this.labelSettings.controls.agency_id.setValue(agency.id);
            this.labelSettings.controls.headercolor.setValue(agency.site_head_color);
            this.headercolor = agency.site_head_color;
            this.labelSettings.controls.barcolor.setValue(agency.site_bar_color);
            this.barcolor = agency.site_bar_color;
            this.labelSettings.controls.bgroundcolor.setValue(agency.site_background_color);
            this.bgroundcolor = agency.site_background_color;
            this.labelSettings.controls.title.setValue(agency.site_title);
            this.labelSettings.controls.description.setValue(agency.settings_description);
            this.labelSettings.controls.hidenicon.setValue(agency.fav_icon_image_url);
            this.imgURL = agency.fav_icon_image_url;
            this.spinner = false;
          }
        },
        error => {
          console.log(error);
          this.spinner = false;
        }
      );
  }

  onFileChanged(event) {
    this.file = event.target.files[0];
    console.log(this.file);

    if (this.file.type.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    } else {
      this.message = null;
    }

    let reader = new FileReader();
    this.imagePath = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
    this.filesubmmit = true;
    const uploadData = new FormData();
    uploadData.append('myFile', this.file, this.file.name);
    this.httpClient.post(environment.appUrl + '/api/businesses/logo-upload', uploadData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(event => {
      this.filepercent += 25;
      $('.iconupload').html('<div class="progress-bar bg-info logoupload" style="width: '+ this.filepercent +'%; height:10px;" role="progressbar">'+ this.filepercent +'%</div>');
      console.log(event); // handle event here
      if (event['body'] && event['body'].logo) {
        this.labelSettings.controls.hidenicon.setValue(event['body'].logo);
        this.filesubmmit = false;
        this.filepercent = 0;
      }
    });
  }

}
