import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {CustomersService} from "../_services/customers.service";
import {first} from "rxjs/operators";
import {BusinessesService} from "../_services/businesses.service";

@Component({
  selector: 'app-customer-after-review-page',
  templateUrl: './customer-after-review-page.component.html',
  styleUrls: ['./customer-after-review-page.component.css']
})
export class CustomerAfterReviewPageComponent implements OnInit {

  option = '';
  token = '';
  public spinner = false;
  public content = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private ngZone: NgZone,
    private custService: CustomersService,
    private busService: BusinessesService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.option = params['option'];
      this.token = params['token'];
      console.log(this.option);

      this.loadPage();
    });
  }

  loadPage() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.busService.loadExtReviewPage(this.option, this.token).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['content']) {
            this.ngZone.run( () => {
              this.content = data['content'];
            });
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

}
