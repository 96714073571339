import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {BusinessesService} from "../_services/businesses.service";
import {first} from "rxjs/operators";
import {UploadAdapter} from "../models/uploadAdapter";
import * as $ from "jquery";
import {NewUserComponent} from "../new-user/new-user.component";
import {PreviewEmailPageComponent} from "../preview-email-page/preview-email-page.component";
import {environment} from "../models/enviroment";
import {EmailTemplatesService} from "../_services/email-templates.service";

@Component({
  selector: 'app-business-rev-pages',
  templateUrl: './business-rev-pages.component.html',
  styleUrls: ['./business-rev-pages.component.css']
})
export class BusinessRevPagesComponent implements OnInit {

  @ViewChild(PreviewEmailPageComponent, null) previewModal: PreviewEmailPageComponent;

  public ckconfig = {
    // include any other configuration you want
    extraPlugins: [ this.TheUploadAdapterPlugin ]
  };
  public where: string = '';
  public editorConfig = {
    placeholder: 'Type the content here!',
  };

  loading = false;
  submmited = false;
  contForm: FormGroup;
  public spinner = false;
  file = null;

  ckeConfig: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private businessService: BusinessesService,
    private ngZone: NgZone,
    private tmplService: EmailTemplatesService,
  ) {  }

  public onReady( editor ) {
    console.log('TheUploadAdapterPlugin called');

  }

  openMergeFieldsModal(type: string) {
    this.where = type;
    $('.merge-fields-modal').modal('show');
  }

  openPreviewModal(type: string) {
    this.where = type;
    /*this.previewModal.html_replaced = '';
    if (type === 'positive')
      this.previewModal.html = this.contForm.controls.posreviewpage.value;
    else
      this.previewModal.html = this.contForm.controls.negreviewpage.value;
    $('.preview-email-page').modal('show');
    this.previewModal.replaceHtml();*/
    let html = '';
    if (type === 'positive')
      html = this.contForm.controls.posreviewpage.value; // Get the value of text area and run HTML code
    else
      html = this.contForm.controls.negreviewpage.value; // Get the value of text area and run HTML code

    this.ngZone.run( () => {
      this.spinner = true;
    });
    const uploadData = new FormData();
    uploadData.append('html', html);
    this.tmplService.replaceHtml(uploadData).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['html']) {
            const previewOpen = window.open("", "previewOpen", "width=700, height=500");
            previewOpen.document.body.innerHTML = data['html'];
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  ngOnInit() {
    console.log('rev pages in');
    this.ckeConfig = {
      extraPlugins: 'justify',
      filebrowserImageBrowseUrl: environment.appUrl + '/ckfinder/browser?command=QuickUpload&type=Images',
      filebrowserImageUploadUrl: environment.appUrl + '/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images'
    };
    this.contForm = this.formBuilder.group({
      business_id: ['', []],
      posreviewpage: ['', []],
      negreviewpage: ['', []],
      revstatment: ['', Validators.required],
    });

    this.loadForm();
  }

  onChange($event: any): void {
    console.log("onChange");

  }

  TheUploadAdapterPlugin(editor) {
    console.log('TheUploadAdapterPlugin called');

  }

  get self(): BusinessRevPagesComponent {
    return this;
  }

  loadForm() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.businessService.getCurrent().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['business']) {
            let business = data['business'];
            this.contForm.controls.business_id.setValue(business.id);
            this.contForm.controls.revstatment.setValue(business.review_statment);
            this.contForm.controls.posreviewpage.setValue(business.ext_positive_rev_content);
            this.contForm.controls.negreviewpage.setValue(business.ext_negative_rev_content);
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  onSubmit() {
    console.log('submit rev pages in');
    this.submmited = true;
    if (this.contForm.invalid) {
      return;
    }
    this.loading = true;
    console.log(this.contForm.getRawValue());
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.businessService.updateReviewContent(this.contForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Update Successful', false);
          this.loading = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );

  }

  get f() { return this.contForm.controls; }

}
