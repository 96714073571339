import {Component, ViewChild} from '@angular/core';
import {User} from "./models/user";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "./_services/authentication.service";
import * as $ from 'jquery';
import Swal from "sweetalert2";
import {environment} from "./models/enviroment";
import {UserProfileModalComponent} from "./user-profile-modal/user-profile-modal.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  @ViewChild(UserProfileModalComponent, null) editUserModal: UserProfileModalComponent;

  title = 'REFORCE';
  currentUser: User;
  showLogin = true;
  public type = '';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ) {
    console.log(this.route.snapshot.queryParams)
    this.route.queryParams.subscribe(params => {
      console.log(params);
      if (typeof params['single_sign_on_code'] !== 'undefined' && params['single_sign_on_code'] !== null && params['single_sign_on_code'] !== '') {
        this.authenticationService.getByToken(params['single_sign_on_code']).subscribe(
          data => {
            console.log(data);
            this.router.navigate(['/dashboard']);
          }, error => {
            console.log(error);
          }
        );
      }
    });
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    if (this.authenticationService.currentUserValue) {
      this.showLogin = false;
    } else {
      this.showLogin = true;
    }
  }

  get self(): AppComponent {
    return this;
  }

  logout() {
    this.authenticationService.logout();
    this.showLogin = true;
    this.router.navigate(['/login']);
  }


  editUser() {
    this.editUserModal.addForm.controls.user_id.setValue(JSON.parse(localStorage.currentUser).id);
    this.editUserModal.loadUser(JSON.parse(localStorage.currentUser).id);
    $('.edit-user-modal').modal('show');
  }

  leaveGhosting() {
    Swal.queue([{
      title: 'Are you sure?',
      text: 'You are going to leave the current business!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, leave it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/businesses/unghost', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({selected: JSON.parse(localStorage.currentUser).id}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            let aux = JSON.parse(localStorage.currentUser);
            aux['business'] = null;
            localStorage.currentUser = JSON.stringify(aux);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your leave the current business!'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to leave your businesses.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0]) {
        location.href = '/dashboard';
      }
    });
  }

}
