import {PreviewEmailPageComponent} from "../preview-email-page/preview-email-page.component";

// declare var google: any;
import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {first} from "rxjs/operators";
import {BusinessesService} from "../_services/businesses.service";
import {AuthService, FacebookLoginProvider, GoogleLoginProvider, LoginOpt} from "angularx-social-login";
import {} from 'googlemaps';
import {YelpSearchBusinessComponent} from "../yelp-search-business/yelp-search-business.component";
import * as $ from "jquery";
import {} from 'googlemaps';

@Component({
  selector: 'app-review-setup',
  templateUrl: './review-setup.component.html',
  styleUrls: ['./review-setup.component.css']
})
export class ReviewSetupComponent implements OnInit {

  loading = false;
  submmited = false;
  revForm: FormGroup;
  public spinner = false;
  showmap = false;

  fbLoginOptions: LoginOpt = {
    scope: 'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages',
    return_scopes: true,
    enable_profile_selector: true,
    offline_access: true,
    prompt: 'consent',
  }; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

  googleLoginOptions: LoginOpt = {
    scope: 'profile email https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/plus.business.manage',
    offline_access: true,
    prompt: 'consent',
    return_scopes: true,
    enable_profile_selector: true,
  }; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig

  @ViewChild(YelpSearchBusinessComponent, null) yelpModal: YelpSearchBusinessComponent;

  @ViewChild('map', null) mapElement: any;
  map: google.maps.Map;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private busService: BusinessesService,
    private ngZone: NgZone,
    private socialAuthService: AuthService,
  ) { }

  ngOnInit() {
    this.revForm = this.formBuilder.group({
      business_id: ['', []],
      googleplus: ['', []],
      googleplus_id: ['', []],
      googleplus_auth_code: ['', []],
      facebook: ['', []],
      facebook_id: ['', []],
      yelp: ['', []],
      place_id: ['', []],
    });
    this.loadForm();
    // this.initMap();
  }

  get self(): ReviewSetupComponent {
    return this;
  }

  loadMap() {
    this.showmap = true;
    $('#search_location').show();
    /*const mapProperties = {
      center: new google.maps.LatLng(35.2271, -80.8431),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement,    mapProperties);
    console.log('map', this.map);*/
  }

  closeMap() {
    $('#search_location').hide();
    this.showmap = false;
  }

  openModal() {
    $('.yelp-search-business').modal('show');
    this.yelpModal.loadYelpBusinesses();
  }

  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID, this.googleLoginOptions).then(
      (userData) => {
        console.log('userData', userData);
        if (typeof userData.authToken !== 'undefined') {
          this.revForm.controls.googleplus.setValue(userData.authToken);
          this.revForm.controls.googleplus_id.setValue(userData.id);
          this.revForm.controls.googleplus_auth_code.setValue(userData.authorizationCode);
        }
      }
    );
  }

  signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID, this.fbLoginOptions).then(
      (userData) => {
        console.log('userData', userData);
        if (typeof userData.authToken !== 'undefined') {
          this.revForm.controls.facebook.setValue(userData.authToken);
          this.revForm.controls.facebook_id.setValue(userData.id);
        }
      }
    );
  }

  signOut(): void {
    this.socialAuthService.signOut();
  }

  onSubmit() {
    this.submmited = true;
    if (this.revForm.invalid) {
      return;
    }
    this.loading = true;
    console.log(this.revForm.getRawValue());
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.busService.updateReviewSetUp(this.revForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Update Successful', false);
          this.loading = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  loadForm() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.busService.getCurrent().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['business']) {
            let business = data['business'];
            this.revForm.controls.business_id.setValue(business.id);
            this.revForm.controls.facebook.setValue(business.facebook_link);
            this.revForm.controls.facebook_id.setValue(business.facebook_id);
            this.revForm.controls.googleplus.setValue(business.google_link);
            this.revForm.controls.googleplus_id.setValue(business.google_id);
            this.revForm.controls.googleplus_auth_code.setValue(business.google_auth_code);
            this.revForm.controls.yelp.setValue(business.yelp_link);
            this.revForm.controls.place_id.setValue(business.place_id);
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  get f() { return this.revForm.controls; }

  initMap() {
    var map = new google.maps.Map(document.getElementById('map'), {
      center: {lat: -33.8688, lng: 151.2195},
      zoom: 13
    });

    var input = document.getElementById('pac-input');

    console.log('GOOGLE MAPS: ', google.maps);

    var autocomplete = new google.maps.places.Autocomplete(input as HTMLInputElement);
    autocomplete.bindTo('bounds', map);

    // Specify just the place data fields that you need.
    autocomplete.setFields(
      ['place_id', 'geometry', 'name', 'formatted_address']);

    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    var infowindow = new google.maps.InfoWindow();
    var infowindowContent = document.getElementById('infowindow-content');
    infowindow.setContent(infowindowContent);

    var marker = new google.maps.Marker({map: map});

    marker.addListener('click', function() {
      infowindow.open(map, marker);
    });

    autocomplete.addListener('place_changed', function() {
      infowindow.close();

      var place = autocomplete.getPlace();

      if (!place.geometry) {
        return;
      }

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }

      // Set the position of the marker using the place ID and location.
      // marker.setPlace({
      //   placeId: place.place_id,
      //   location: place.geometry.location
      // });

      marker.setVisible(true);

      infowindowContent.children['place-name'].textContent = place.name;
      infowindowContent.children['place-id'].textContent = place.place_id;
      infowindowContent.children['place-address'].textContent =
        place.formatted_address;
      infowindow.open(map, marker);
    });
  }


}
