import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Agency} from "../models/agency";
import {Category} from "../models/category";
import {environment} from "../models/enviroment";

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Category[]>(environment.appUrl + '/api/categories');
  }

}
