import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import * as $ from "jquery";
import {CustomersComponent} from "../customers/customers.component";
import Swal from "sweetalert2";

@Component({
  selector: 'app-export-customers',
  templateUrl: './export-customers.component.html',
  styleUrls: ['./export-customers.component.css']
})
export class ExportCustomersComponent implements OnInit {

  private _parent: CustomersComponent ;
  @Input() set parent(value: CustomersComponent ) {
    this._parent = value;
  }

  loading = false;
  submmited = false;
  expForm: FormGroup;

  public filters: { [key: string]: Object; }[] = [
    { Name: '1 Star', Code: '1 Star' },
    { Name: '2 Star', Code: '2 Star' },
    { Name: '3 Star', Code: '3 Star' },
    { Name: '4 Star', Code: '4 Star' },
    { Name: '5 Star', Code: '5 Star' },
    { Name: 'No feedback left', Code: 'No feedback left' },
    { Name: 'Not in feedback sequence', Code: 'Not in feedback sequence' },
    { Name: 'In feedback sequence', Code: 'in feedback sequence' },
    { Name: 'Opt-Out', Code: 'Opt-Out' },
  ];
  // maps the local data column to fields property
  public localFields: Object = { text: 'Name', value: 'Code' };
  // set the placeholder to MultiSelect Dropdown input element
  public localWaterMark: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.expForm = this.formBuilder.group({
      options: ['', []],
    });
  }

  get f() { return this.expForm.controls; }

  onSubmit() {
    this.submmited = true;

    if (this.expForm.invalid) {
      //Swal.fire('Oops...', 'Please select at least one filter!', 'error');
      return;
    }
    this.loading = true;
    let customers = [];
    console.log('options', this.expForm.controls.options.value);
    if (this.expForm.controls.options.value.length > 0) {
      for (let ind in this.parent.customers) {
        let getin = false;
        if (this.parent.customers[ind][6].indexOf('Star') !== -1) {
          if (this.expForm.controls.options.value.indexOf(this.parent.customers[ind][6]) !== -1) {
            customers.push(this.parent.customers[ind]);
            getin = true;
          }
        }
        if (this.expForm.controls.options.value.indexOf('No feedback left') !== -1 && !getin) {
          if (this.parent.customers[ind][6].indexOf('Recommended') !== -1) {
            customers.push(this.parent.customers[ind]);
            getin = true;
          }
        }
        if (this.expForm.controls.options.value.indexOf('In feedback sequence') !== -1 && !getin) {
          if (this.parent.customers[ind][6].indexOf('Waiting') !== -1) {
            customers.push(this.parent.customers[ind]);
            getin = true;
          }
        }
        if (this.expForm.controls.options.value.indexOf('Not in feedback sequence') !== -1 && !getin) {
          if (this.parent.customers[ind][6].indexOf('Never Contacted') !== -1) {
            customers.push(this.parent.customers[ind]);
            getin = true;
          }
        }
        if (this.expForm.controls.options.value.indexOf('Opt-Out') !== -1 && !getin) {
          if (this.parent.customers[ind][6].indexOf('Opt-Out') !== -1)
            customers.push(this.parent.customers[ind]);
        }
      }
    } else {
      customers = JSON.parse(JSON.stringify( this.parent.customers));
    }

    console.log('customers', customers);
    this.exportCSVFile(['Cust. ID', 'Feedback Date', 'First Name', 'Last Name', 'Email', 'Phone', 'Status', 'Employee'], customers, 'customers-export');
    this.loading = false;
  }

  get parent(): CustomersComponent {
    return this._parent;
  }

  openModal() {
    $('.export-customers-modal').modal('show');
  }

  closeModal() {
    $('.export-customers-modal').modal('hide');
    this.submmited = false;
    this.expForm.reset();
  }

  convertToCSV(objArray) {
    let array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '')
          line += ',';
        line += (index !== '8') ? array[i][index] : '';
      }
      str += line + '\r\n';
    }
    return str;
  }

  exportCSVFile(headers, items, fileTitle) {
    if (headers) {
      items.unshift(headers);
    }

    // Convert Object to JSON
    let jsonObject = JSON.stringify(items);

    let csv = this.convertToCSV(jsonObject);

    let exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

}
