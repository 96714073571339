import {Component, Input, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import * as $ from "jquery";
import { CheckBoxSelectionService, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import {first} from "rxjs/operators";
import collect from "collect.js";
import {AgencyService} from "../_services/agency.service";
import {UserService} from "../_services/user.service";
import {BusinessEmployeesComponent} from "../business-employees/business-employees.component";
import {UsersComponent} from "../users/users.component";
import Swal from "sweetalert2";
import {environment} from "../models/enviroment";

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css'],
  providers: [CheckBoxSelectionService]
})
export class NewUserComponent implements OnInit {

  private _parent: UsersComponent ;
  @Input() set parent(value: UsersComponent ) {
    this._parent = value;
  }

  private _userid: number ;
  @Input() set userid(value: number ) {
    this._userid = value;
  }

  public spinner:boolean = false;
  loading = false;
  submmited = false;
  addForm: FormGroup;

  public filters: { [key: string]: Object; }[] = [
    /*{ Name: 'Test Agency 1', Code: '1', category: 'Agencies' },
    { Name: 'Test Agency 2', Code: '2', category: 'Agencies' },
    { Name: 'Test Business 1', Code: '3', category: 'Businesses' },
    { Name: 'Test Business 2', Code: '4', category: 'Businesses' },
    { Name: 'Test Business 3', Code: '5', category: 'Businesses' },*/
  ];
  // maps the local data column to fields property
  public localFields: Object = { groupBy: 'category', text: 'Name', value: 'Code' };
  // set the placeholder to MultiSelect Dropdown input element
  public localWaterMark: string = 'Select entities';
  enableGroupCheckBox: boolean = true;
  allowFiltering: boolean = true;
  filterBarPlaceholder: string = 'Select access ...';
  showSelectAll: boolean = true;
  public mode: string;
  public height: string = '200px';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private agencyService: AgencyService,
    private userService: UserService,
    private ngZone: NgZone,
) { }

  ngOnInit() {
    this.mode = 'CheckBox';
    this.addForm = this.formBuilder.group({
      user_id: ['', []],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
      entities: ['', Validators.required],
      password: ['', Validators.required],
      passwordconf: ['', Validators.required],
      status: ['', []],
    }, {
      validator: this.MustMatch('password', 'passwordconf')
    });
    this.loadAccesses(0);
  }

  loadUser(id: number) {
    if (id >= 0 && id.toString() !== '') {
      console.log('load user in', id);
      this.userService.getUser(id).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data && data['user']) {
              this.addForm.controls.firstname.setValue(data['user']['firstname']);
              this.addForm.controls.lastname.setValue(data['user']['lastname']);
              this.addForm.controls.email.setValue(data['user']['email']);
              this.addForm.controls.role.setValue(data['user']['user_role']);
              this.addForm.controls.status.setValue(data['user']['status']);
              this.addForm.controls.password.setValue('12345678');
              this.addForm.controls.passwordconf.setValue('12345678');

              let entities = [];
              console.log('assigments', data['user']['assigments']);
              if (data['user']['assigments'].length > 0) {
                for(let i in data['user']['assigments']) {
                  if (data['user']['assigments'][i]['assigment_type'].indexOf('Agency') !== -1) {
                    entities.push('a_' + data['user']['assigments'][i]['assigned_to']);
                  } else {
                    entities.push('b_' + data['user']['assigments'][i]['assigned_to']);
                  }
                }
                console.log(entities);
                this.addForm.controls.entities.setValue(entities);
              }
              console.log("spinner false", this);
            }
          },
          error => {
            console.log(error);
          },
          () => {
            this.ngZone.run( () => {
              this.spinner = false;
            });
          }
        );
    }
  }

  get f() { return this.addForm.controls; }

  loadAccesses(id: number) {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    if (this.filters.length === 0)
      this.filters = [];
    this.agencyService.getAllSons().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['agencies'] && data['businesses']) {
            if (this.filters.length === 0) {
              for (let i in data['agencies'])
                this.filters.push({
                  Name: data['agencies'][i].agency_name,
                  Code: 'a_' + data['agencies'][i].id,
                  category: 'Agencies',
                });

              for (let i in data['businesses'])
                this.filters.push({
                  Name: data['businesses'][i].business_name,
                  Code: 'b_' + data['businesses'][i].id,
                  category: 'Businesses',
                });
            }
            this.loadUser(id);
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  onSubmit() {
    this.submmited = true;

    if (this.addForm.invalid) {
      return;
    }
    this.loading = true;
    console.log(this.addForm.getRawValue());

    this.userService.registerAdv(this.addForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Registration Successful', false);
          this.parent.loadUsers();
          this.closeModal();
          this.loading = false;
        },
        error => {
          console.log('We can\'t edit your profile. Please check all required fields and the email shouldn\'t be in use.');
          this.alertService.error('We can\'t submit the form. Please check all required fields and the email shouldn\'t be in use.');
          this.loading = false;
          this.submmited = false;
        }
      );
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  openModal() {
    $('.add-user-modal').modal('show');
  }

  closeModal() {
    $('.add-user-modal').modal('hide');
    this.submmited = false;
    this.addForm.reset();
  }

  get parent(): UsersComponent {
    return this._parent;
  }

  get userid(): number {
    return this._userid;
  }
}
