import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Employee} from "../models/employee";
import {User} from "../models/user";
import {environment} from "../models/enviroment";

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Employee[]>(environment.appUrl + `/api/employees`);
  }

  getAllAdv() {
    return this.http.get<Employee[]>(environment.appUrl + `/api/employees/all`);
  }

  register(employee: Employee) {
    return this.http.post(environment.appUrl + `/api/employees/new`, employee);
  }

  update(employee: Employee) {
    return this.http.put(environment.appUrl + '/api/employees/edit', employee);
  }

  delete(id: number) {
    return this.http.get(environment.appUrl + `/api/employees/delete/${id}`);
  }

  show(id: number) {
    return this.http.get(environment.appUrl + `/api/employees/show/${id}`);
  }

}
