import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertService} from "../_services/alert.service";
import 'cropperjs';
import {FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry} from "ngx-file-drop";
import {HttpClient} from "@angular/common/http";
import {first} from "rxjs/operators";
import {AgencyService} from "../_services/agency.service";
import * as $ from "jquery";
import {environment} from "../models/enviroment";

@Component({
  selector: 'app-logo-settings',
  templateUrl: './logo-settings.component.html',
  styleUrls: ['./logo-settings.component.css']
})
export class LogoSettingsComponent implements OnInit {

  logoSettings: FormGroup;
  loading = false;
  submmited = false;
  public spinner = false;

  filesubmmit = false;
  filepercent = 0;

  file = null;
  public imagePath;
  imgURL: any;
  public message: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private httpClient: HttpClient,
    private agencyService: AgencyService,
  ) { }

  ngOnInit() {
    console.log('logo settings in');

    this.logoSettings = this.formBuilder.group({
      agency_id: ['', []],
      banner: ['', Validators.required],
      anchorlink: ['', Validators.required],
      hidenbanner: ['', []],
    });
    this.loadForm();
  }

  onFileChanged(event) {
    this.file = event.target.files[0];
    console.log(this.file);

    if (this.file.type.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    } else {
      this.message = null;
    }

    let reader = new FileReader();
    this.imagePath = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
    this.filesubmmit = true;
    const uploadData = new FormData();
    uploadData.append('myFile', this.file, this.file.name);
    this.httpClient.post(environment.appUrl + '/api/businesses/logo-upload', uploadData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(event => {
      this.filepercent += 25;
      $('.bannerupload').html('<div class="progress-bar bg-primary logoupload" style="width: '+ this.filepercent +'%; height:10px;" role="progressbar">'+ this.filepercent +'%</div>');
      console.log(event); // handle event here
      if (event['body'] && event['body'].logo) {
        this.logoSettings.controls.hidenbanner.setValue(event['body'].logo);
        this.logoSettings.controls.anchorlink.setValue(event['body'].logo);
        this.filesubmmit = false;
        this.filepercent = 0;
      }
    });
  }

  loadForm() {
    this.spinner = true;
    this.agencyService.getCurrent().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['agency']) {
            let agency = data['agency'];
            this.logoSettings.controls.agency_id.setValue(agency.id);
            this.logoSettings.controls.anchorlink.setValue(agency.banner_url);
            this.logoSettings.controls.hidenbanner.setValue(agency.banner);
            this.imgURL = agency.banner;
            this.spinner = false;
          }
        },
        error => {
          console.log(error);
          this.spinner = false;
        }
      );
  }

  onSubmit() {
    console.log('logo settings submit in');

    this.submmited = true;
    if (this.logoSettings.invalid) {
      return;
    }

    this.loading = true;
    // next service to save the settings
    console.log(this.logoSettings.getRawValue());

    this.agencyService.updateBanner(this.logoSettings.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Update Successful', false);
          this.loading = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }

  get f() { return this.logoSettings.controls; }

}
