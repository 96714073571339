import {Component, NgZone, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {BusinessesService} from "../_services/businesses.service";
import {environment} from "../models/enviroment";
import {first} from "rxjs/operators";

@Component({
  selector: 'app-public-reviews',
  templateUrl: './public-reviews.component.html',
  styleUrls: ['./public-reviews.component.css']
})
export class PublicReviewsComponent implements OnInit {

  public show = true;
  public token = '';
  public spinner = false;
  business = null;
  city = null;
  state = null;
  country = null;
  reviews = [];
  hours = [];
  overall_rating = 0;
  total_reviews = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private ngZone: NgZone,
    private busService: BusinessesService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.token = params['token'];
      console.log(this.token);
    });
    this.loadGoogleUser();
  }

  loadGoogleUser() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.httpClient.get(environment.appUrl + '/api/reviews/google/' + this.token).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if(typeof data['yelp_reviews'] !== 'undefined' && typeof data['yelp_reviews'][0] !== 'undefined') {
            let yelp = data['yelp_reviews'][0]['reviews'];
            this.reviews = [];
            for (let ind in yelp) {
              if (yelp[ind].authorization === 1) {
                let newrev = {
                  rating: new Array(yelp[ind].rating),
                  text: yelp[ind].text,
                  created: yelp[ind].time_created,
                  url: yelp[ind].url,
                  image: yelp[ind].user.image_url,
                  name: yelp[ind].user.name,
                  profile: yelp[ind].user.profile_url,
                  icon_class: 'fab fa-yelp',
                };
                this.overall_rating += newrev.rating.length;
                this.total_reviews++;
                this.reviews.push(newrev);
              }
            }
          }

          const reforce = data['reforce'];
          if (reforce.length > 0) {
            for (let ind in reforce) {
              if (reforce[ind].authorization === 1) {
                let newrev = {
                  rating: new Array(reforce[ind].rating_star),
                  text: reforce[ind].rating_description,
                  created: reforce[ind].created_at,
                  url: '',
                  image: '../../assets/images/generic-avatar.png',
                  name: reforce[ind].customer.first_name + ' ' + reforce[ind].customer.last_name,
                  profile: '',
                  icon_class: 'far fa-comment',
                };
                this.overall_rating += newrev.rating.length;
                this.total_reviews++;
                this.reviews.push(newrev);
              }
            }
          }

          let google_rev = data['allreviews'];
          if(typeof google_rev !== 'undefined' && typeof google_rev[0] !== 'undefined') {
            for (let ind in google_rev) {
              if (google_rev[ind].authorization === 1) {
                let newrev = {
                  rating: new Array((google_rev[ind]['starRating'] === 'FIVE') ? 5 : (google_rev[ind]['starRating'] === 'FOUR' ? 4 : (google_rev[ind]['starRating'] === 'THREE' ? 3 : (google_rev[ind]['starRating'] === 'TWO' ? 2 : 1)))),
                  text: google_rev[ind].comment,
                  created: google_rev[ind]['createTime'],
                  url: '',
                  image: google_rev[ind]['reviewer']['profilePhotoUrl'],
                  name: google_rev[ind]['reviewer']['displayName'],
                  profile: '',
                  icon_class: 'fab fa-google',
                };
                this.overall_rating += newrev.rating.length;
                this.total_reviews++;
                this.reviews.push(newrev);
              }
            }
          }

          let facebook_rev = data['facebook'];
          if (typeof facebook_rev !== 'undefined' && typeof facebook_rev[0] !== 'undefined') {
            for (let ind in facebook_rev) {
              if (facebook_rev[ind].authorization === 1) {
                let newrev = {
                  rating: new Array((facebook_rev[ind]['recommendation_type'] === 'positive') ? 5 : 1),
                  text: facebook_rev[ind]['review_text'],
                  created: facebook_rev[ind]['created_time'],
                  url: '',
                  image: '../../assets/images/generic-avatar.png',
                  name: facebook_rev[ind]['reviewer']['name'],
                  profile: '',
                  icon_class: 'fab fa-facebook',
                };
                this.overall_rating += newrev.rating.length;
                this.total_reviews++;
                this.reviews.push(newrev);
              }
            }
          }

          this.overall_rating = (this.total_reviews > 0) ? this.overall_rating / this.total_reviews : 0;
          console.log(this.reviews);
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

}
