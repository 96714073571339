import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {AceEditorComponent} from "ng2-ace-editor";
import 'brace';
import 'brace/mode/css';
import 'brace/theme/chrome';
import 'brace/theme/solarized_dark';
import 'brace/theme/twilight';
import {first} from "rxjs/operators";
import {AgencyService} from "../_services/agency.service";

@Component({
  selector: 'app-custom-css',
  templateUrl: './custom-css.component.html',
  styleUrls: ['./custom-css.component.css']
})
export class CustomCssComponent implements OnInit {

  loading = false;
  submmited = false;
  cssForm: FormGroup;
  public spinner = false;

  text: string = '';
  options: any = { maxLines: 1000, printMargin: false };

  onChange(code) {
    console.log('new code', code);
    this.cssForm.controls.code.setValue(code);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpCLient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private agencyService: AgencyService,
  ) {}

  ngOnInit() {
    console.log('custom css init');

    this.cssForm = this.formBuilder.group({
      agency_id: ['', []],
      code: ['', []],
    });

    this.loadForm();
  }

  loadForm() {
    this.spinner = true;
    this.agencyService.getCurrent().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['agency']) {
            let agency = data['agency'];
            this.cssForm.controls.agency_id.setValue(agency.id);
            this.cssForm.controls.code.setValue(agency.custom_css);
            this.text = agency.custom_css;
            this.spinner = false;
          }
        },
        error => {
          console.log(error);
          this.spinner = false;
        }
      );
  }

  get f() { return this.cssForm.controls; }

  onSubmit() {
    console.log('css submit in');
    this.submmited = true;
    if (this.cssForm.invalid) {
      return;
    }
    this.loading = true;
    console.log(this.cssForm.getRawValue());

    this.agencyService.updateCustomCss(this.cssForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Update Successful', false);
          this.loading = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
        }
      );

  }

}
