import {Component, Input, NgZone, OnInit} from '@angular/core';
import {NewEmailTemplateComponent} from "../new-email-template/new-email-template.component";
import {BusinessRevPagesComponent} from "../business-rev-pages/business-rev-pages.component";
import {EmailTemplatesService} from "../_services/email-templates.service";
import * as $ from "jquery";
import {first} from "rxjs/operators";

@Component({
  selector: 'app-preview-email-page',
  templateUrl: './preview-email-page.component.html',
  styleUrls: ['./preview-email-page.component.css']
})
export class PreviewEmailPageComponent implements OnInit {

  private _parent: NewEmailTemplateComponent ;
  @Input() set parent(value: NewEmailTemplateComponent ) {
    this._parent = value;
  }

  private _parent2: BusinessRevPagesComponent ;
  @Input() set parent2(value: BusinessRevPagesComponent ) {
    this._parent2 = value;
  }

  public spinner = false;
  public html = '';
  public html_replaced = '';

  constructor(
    private tmplService: EmailTemplatesService,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {}

  replaceHtml() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    const uploadData = new FormData();
    uploadData.append('html', this.html);
    this.tmplService.replaceHtml(uploadData).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['html']) {
            this.html_replaced = data['html'];
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  get parent(): NewEmailTemplateComponent {
    return this._parent;
  }

  get parent2(): BusinessRevPagesComponent {
    return this._parent2;
  }

}
