import {Component, Input, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import * as $ from "jquery";
import {AgenciesComponent} from "../agencies/agencies.component";
import {BusinessComponent} from "../business/business.component";
import {Country} from "../models/country";
import {CategoriesService} from "../_services/categories.service";
import {first} from "rxjs/operators";
import {CountriesService} from "../_services/countries.service";
import {Category} from "../models/category";
import {StatesService} from "../_services/states.service";
import {State} from "../models/state";
import {CitiesService} from "../_services/cities.service";
import {City} from "../models/city";
import {BusinessesService} from "../_services/businesses.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-new-business',
  templateUrl: './new-business.component.html',
  styleUrls: ['./new-business.component.css']
})
export class NewBusinessComponent implements OnInit {

  private _parent: BusinessComponent ;
  private toEditGroup = '';
  @Input() set parent(value: BusinessComponent ) {
    this._parent = value;
  }

  public spinner = false;

  loading = false;
  submmited = false;
  agencyForm: FormGroup;
  countries: Country[] = [];
  categories: Category[] = [];
  states: State[] = [];
  cities: City[] = [];

  public filters: { [key: string]: Object; }[] = [];
  // maps the local data column to fields property
  public localFields: Object = { text: 'Name', value: 'Code' };
  // set the placeholder to MultiSelect Dropdown input element
  public localWaterMark: string = 'Select Sales Representatives ...';
  groups: Array<any> = [];
  showEditGroup = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private catService: CategoriesService,
    private countService: CountriesService,
    private stateService: StatesService,
    private cityervice: CitiesService,
    private busService: BusinessesService,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.agencyForm = this.formBuilder.group({
      business_id: ['', []],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      accountemail: ['', Validators.required],
      password: ['', Validators.required],
      confpassword: ['', Validators.required],
      businessname: ['', Validators.required],
      category: ['', Validators.required],
      streetaddress: ['', Validators.required],
      streetaddress2: ['', []],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zip: ['', Validators.required],
      phone: ['', Validators.required],
      webaddress: ['', []],
      salesrep: ['', []],
      status: ['', []],
      business_group_id: ['', Validators.required]
    }, {
      validator: this.MustMatch('password', 'confpassword')
    });
    this.loadCategories();
    //this.loadCountries();
    this.getGroups();
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  loadBusiness(id: number) {
    if (id >= 0 && id.toString() !== '') {
      this.getGroups();
      this.ngZone.run( () => {
        this.spinner = true;
      });
      console.log('load user in', id);
      this.busService.show(id).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data && data['business']) {
              this.agencyForm.controls.business_id.setValue(id);
              this.agencyForm.controls.firstname.setValue(data['business']['first_name']);
              this.agencyForm.controls.lastname.setValue(data['business']['last_name']);
              this.agencyForm.controls.email.setValue(data['business']['email']);
              this.agencyForm.controls.accountemail.setValue(data['business']['email']);
              this.agencyForm.controls.businessname.setValue(data['business']['business_name']);
              this.agencyForm.controls.category.setValue(data['business']['business_category_id']);
              this.agencyForm.controls.streetaddress.setValue(data['business']['address_line1']);
              this.agencyForm.controls.streetaddress2.setValue(data['business']['address_line2']);
              this.agencyForm.controls.country.setValue(data['business']['country']);
              this.agencyForm.controls.state.setValue(data['business']['state']);
              this.agencyForm.controls.city.setValue(data['business']['city']);
              this.agencyForm.controls.phone.setValue(data['business']['phone']);
              this.agencyForm.controls.webaddress.setValue(data['business']['company_web_address']);
              //this.agencyForm.controls.salesrep.setValue(data['business']['business_name']);
              //this.loadStates();
              //this.loadCities();
              this.agencyForm.controls.zip.setValue(data['business']['zip']);
              this.agencyForm.controls.password.setValue('123456789');
              this.agencyForm.controls.confpassword.setValue('123456789');
              this.agencyForm.controls.status.setValue(data['business']['status']);
              this.agencyForm.controls.business_group_id.setValue([data['business']['business_group_id']]);
            }
          },
          error => {
            console.log(error);
          },
          () => {
            this.ngZone.run( () => {
              this.spinner = false;
            });
          }
        );
    }
  }

  loadCategories() {
    this.catService.getAll().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.categories = data['data'];
        },
        error => {
          console.log(error);
        }
      );
  }

  loadCountries() {
    this.countService.getAll().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.countries = data['data'];
        },
        error => {
          console.log(error);
        }
      );
  }

  copyEmail() {
    if (this.agencyForm.controls.email.value !== '')
      this.agencyForm.controls.accountemail.setValue(this.agencyForm.controls.email.value);
  }

  loadStates() {
    if (this.agencyForm.controls.country.value !== '') {
      this.stateService.getAll(this.agencyForm.controls.country.value).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            this.states = data['data'];
          },
          error => {
            console.log(error);
          }
        );
    }
  }

  loadCities() {
    if (this.agencyForm.controls.state.value !== '') {
      this.cityervice.getAll(this.agencyForm.controls.state.value).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            this.cities = data['data'];
            if(this.agencyForm.controls.zip.value === '')
              this.agencyForm.controls.zip.setValue('');
          },
          error => {
            console.log(error);
            this.agencyForm.controls.zip.setValue('');
          }
        );
    }
  }

  loadZipCode() {
    if (typeof $(".cities option:selected").attr('datazip') !== 'undefined') {
      console.log($(".cities option:selected").attr('datazip'));
      this.agencyForm.controls.zip.setValue($(".cities option:selected").attr('datazip'));
    } else {
      this.agencyForm.controls.zip.setValue('');
    }
  }

  onSubmit() {
    this.submmited = true;
    if (this.agencyForm.invalid) {
      return;
    }
    // this.agencyForm.controls.business_group_id.setValue(this.agencyForm.controls.business_group_id.value[0]);
    this.loading = true;
    console.log(this.agencyForm.getRawValue());
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.busService.register(this.agencyForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Registration Successful', false);
          this.parent.loadBusinesses();
          this.closeModal();
          this.loading = false;
        },
        error => {
          console.log('We can\'t edit your profile. Please check all required fields and the email shouldn\'t be in use.');
          this.alertService.error('We can\'t submit the form. Please check all required fields and the email shouldn\'t be in use.');
          this.loading = false;
          this.submmited = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
          this.closeModal();
        }
      );
  }

  get parent(): BusinessComponent {
    return this._parent;
  }

  get f() { return this.agencyForm.controls; }

  openModal() {
    $('.new-business-modal').modal('show');
  }

  closeModal() {
    $('.new-business-modal').modal('hide');
    this.submmited = false;
    this.agencyForm.reset();
  }

  getGroups() {
    this.busService.getGroups().subscribe(
      (response) => {
        this.groups = response['groups'];
      },
      (error) => { console.log('getGroups', error); }
    );
  }

  editGroup() {
    if (!isNaN(this.agencyForm.value.business_group_id[0])) {
      this.showEditGroup = true;
      this.toEditGroup = this.groups.find((item) => item.id === this.agencyForm.value.business_group_id[0]).name;
    }
  }

  saveGroup() {
    this.busService.updateGroup({name: this.toEditGroup}, this.agencyForm.value.business_group_id[0]).subscribe(
      (response) => {
        this.groups.find((item) => item.id === this.agencyForm.value.business_group_id[0]).name = this.toEditGroup;
        this.getGroups();
        this.showEditGroup = false;
      },
      (error) => { console.log('updateGroup', error); this.showEditGroup = false; }
    );
  }
}
