import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {CustomersService} from "../_services/customers.service";
import {first} from "rxjs/operators";

@Component({
  selector: 'app-customer-unsuscribe',
  templateUrl: './customer-unsuscribe.component.html',
  styleUrls: ['./customer-unsuscribe.component.css']
})
export class CustomerUnsuscribeComponent implements OnInit {
  token = '';
  public spinner = false;
  success = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private ngZone: NgZone,
    private custService: CustomersService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.token = params['token'];
      console.log(this.token);

      this.unsuscribe();

    });
  }

  unsuscribe() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.custService.unsubscribe(this.token).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['customer']) {

          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
            this.success = true;
          });
        }
      );
  }

}
