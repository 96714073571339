import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {State} from "../models/state";
import {City} from "../models/city";
import {environment} from "../models/enviroment";

@Injectable({
  providedIn: 'root'
})
export class CitiesService {

  constructor(private http: HttpClient) { }

  getAll(state_id: number) {
    return this.http.get<City[]>(environment.appUrl + `/api/cities/${state_id}`);
  }
}
