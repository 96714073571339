import {Component, Input, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {EmployeesService} from "../_services/employees.service";
import {CustomersComponent} from "../customers/customers.component";
import {BusinessEmployeesComponent} from "../business-employees/business-employees.component";
import * as $ from "jquery";
import {first} from "rxjs/operators";

@Component({
  selector: 'app-new-employee',
  templateUrl: './new-employee.component.html',
  styleUrls: ['./new-employee.component.css']
})
export class NewEmployeeComponent implements OnInit {

  private _parent: BusinessEmployeesComponent ;
  @Input() set parent(value: BusinessEmployeesComponent ) {
    this._parent = value;
  }
  public spinner = false;

  loading = false;
  submmited = false;
  emplForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private emplService: EmployeesService,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.emplForm = this.formBuilder.group({
      id: ['', []],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      title: ['', Validators.required],
      office: ['', Validators.required],
    });
  }

  loadEmployee(id: number) {
    if (id >= 0 && id.toString() !== '') {
      this.ngZone.run( () => {
        this.spinner = true;
      });
      console.log('load emp in', id);
      this.emplService.show(id).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data && data['employee']) {
              this.emplForm.controls.id.setValue(id);
              this.emplForm.controls.firstname.setValue(data['employee']['firstname']);
              this.emplForm.controls.lastname.setValue(data['employee']['lastname']);
              this.emplForm.controls.email.setValue(data['employee']['email']);
              this.emplForm.controls.phone.setValue(data['employee']['phone']);
              this.emplForm.controls.title.setValue(data['employee']['title']);
              this.emplForm.controls.office.setValue(data['employee']['office']);
            }
          },
          error => {
            console.log(error);
          },
          () => {
            this.ngZone.run( () => {
              this.spinner = false;
            });
          }
        );
    }
  }

  onSubmit() {
    this.submmited = true;
    if (this.emplForm.invalid) {
      return;
    }
    this.loading = true;
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.emplService.register(this.emplForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Registration Successful', false);
          this.parent.loadEmployees();
          this.closeModal();
          this.loading = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  openModal() {
    $('.new-employee-modal').modal('show');
  }

  closeModal() {
    $('.new-employee-modal').modal('hide');
    this.submmited = false;
    this.emplForm.reset();
  }

  get f() { return this.emplForm.controls; }

  get parent(): BusinessEmployeesComponent {
    return this._parent;
  }

}
