import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {State} from "../models/state";
import {SocialMedia} from "../models/SocialMedia";
import {environment} from "../models/enviroment";

@Injectable({
  providedIn: 'root'
})
export class SocialMediasService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<SocialMedia[]>(environment.appUrl + `/api/socialmedias`);
  }

  getAllForRview() {
    return this.http.get<SocialMedia[]>(environment.appUrl + `/api/socialmedias/promotions`);
  }

}
