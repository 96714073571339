import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../models/user";
import {catchError, map, tap} from "rxjs/operators";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {environment} from "../models/enviroment";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(
    private http: HttpClient
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    console.log('login service in');
    return this.http.post<any>('/users/authenticate', {username, password})
      .pipe(map(user => {
        console.log('login service pipe in');
        if (user && user.token) {
          console.log('login service pipe match in');
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        } else {
          console.log('login service pipe out');
        }
        return user;
      }));
  }

  login2(username: string, password: string) {
    console.log('login service in');
    return this.http.post<any>(environment.appUrl + '/api/login', {email: username, password: password}, {headers: this.headers})
      .pipe(
        map(data => {
          console.log('login service pipe in', data);
          let user = null;
          if (data && data['data'].api_token) {
            console.log('login service pipe match in');
            user = {
              id: data['data'].id,
              username: data['data'].email,
              firstName: data['data'].name,
              lastName: data['data'].name,
              token: data['data'].api_token,
              business: data['business'],
              agency: data['agency'],
            };
            console.log('user', user);
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
          } else {
            console.log('login service pipe out');
          }

          return user;
        })
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return 'Something bad happened; please try again later.';
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  getByToken(token: string) {
    return this.http.get<any>(environment.appUrl + '/api/user/all-data', {headers: {Authorization: `Bearer ${token}`}}).pipe(
      map(data => {
        console.log('login service pipe in', data);
        let user = null;
        if (data && data['data']) {
          console.log('login service pipe match in');
          user = {
            id: data['data'].id,
            username: data['data'].email,
            firstName: data['data'].name,
            lastName: data['data'].name,
            token: token,
            business: data['business'],
            agency: data['agency'],
          };
          console.log('user', user);
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        } else {
          console.log('login service pipe out');
        }
        return user;
      })
    );
  }

}
