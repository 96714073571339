import {Component, Input, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import * as $ from "jquery";
import {first} from "rxjs/operators";
import {CustomersService} from "../_services/customers.service";
import {CustomersComponent} from "../customers/customers.component";
import {environment} from "../models/enviroment";

@Component({
  selector: 'app-import-csv-customers',
  templateUrl: './import-csv-customers.component.html',
  styleUrls: ['./import-csv-customers.component.css']
})
export class ImportCsvCustomersComponent implements OnInit {

  private _parent: CustomersComponent ;
  @Input() set parent(value: CustomersComponent ) {
    this._parent = value;
  }
  spinner = false;
  filesubmmit = false;
  fileprecent = 0;

  loading = false;
  submmited = false;
  impForm: FormGroup;

  file = null;
  public imagePath;
  imgURL: any;
  public message: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private custService: CustomersService,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.impForm = this.formBuilder.group({
      period: ['', []],
      sendoption: ['', []],
      file: ['', []],
      hidenfile: ['', []],
    });
  }

  get parent(): CustomersComponent {
    return this._parent;
  }

  get f() { return this.impForm.controls; }

  onSubmit() {
    this.submmited = true;
    if (this.impForm.invalid) {
      return;
    }
    this.loading = true;
    console.log(this.impForm.getRawValue());
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.custService.importCsv(this.impForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (!data['error']) {
            const totalToImport = data['totalToImport'];
            const imported = data['imports'].length;
            let warnText = (data['warnings'].length > 0) ? 'Some customers were not imported because: \r\n' : '';
            for (const warn of data['warnings']) {
              warnText += '- ' + warn + '\r\n';
            }
            this.alertService.success('Update Successful.' + ((data['warnings'].length > 0) ? '\r\n' + 'Imported: ' + imported + '.\r\n' + 'Not Imported: ' + (totalToImport - imported) + '.\r\n' + warnText : ''), false);
            this.closeModal();
            this.parent.loadCustomers();
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  onFileChanged(event) {
    this.file = event.target.files[0];
    console.log(this.file);

    if (this.file.type.match(/vnd.ms-excel\/*/) == null) {
      this.message = 'Only csv are supported.';
      return;
    } else {
      this.message = null;
    }

    let reader = new FileReader();
    this.imagePath = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }

    this.filesubmmit = true;
    const uploadData = new FormData();
    uploadData.append('myFile', this.file, this.file.name);
    this.httpClient.post(environment.appUrl + '/api/customers/csv-upload', uploadData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(event => {
      this.fileprecent += 25;
      $('.csvupload').html('<div class="progress-bar bg-primary logoupload" style="width: '+ this.fileprecent +'%; height:10px;" role="progressbar">'+ this.fileprecent +'%</div>');
      console.log(event); // handle event here
      if (event['body'] && event['body'].url) {
        this.impForm.controls.hidenfile.setValue(event['body'].url);
        this.filesubmmit = false;
        this.fileprecent = 0;
      }
    });
  }

  openModal() {
    $('.import-customers-modal').modal('show');
  }

  closeModal() {
    $('.import-customers-modal').modal('hide');
    this.submmited = false;
    this.impForm.reset();
  }

}
