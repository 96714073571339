import {isString} from "util";

export const default_templates = {
  initial_feedback: {
    subject: 'Rate your Experience',
    content_internal_on: '<p>Dear $first_name,</p>' +
      '<p>Thank you for choosing $businessname!</p>' +
      '<p>We strive to deliver an outstanding experience and value your feedback about your recent experience with us. ' +
      'Would you be willing to take a moment to tell us about it?</p>' +
      '<p>If so, please click the button to leave feedback!</p>' +
      '<p><a target="_blank" href="$feedbacklink" class="btn btn-outline-primary" style="padding: 5px; border: 1px solid rebeccapurple; color: rebeccapurple" >Feedback</a></p>',
    content_internal_off: '<p>Dear $first_name,</p>' +
      '<p>Thank you for choosing $businessname!</p>' +
      '<p>We strive to deliver an outstanding experience and value your feedback about your recent experience with us. ' +
      'Would you be willing to take a moment to tell us about it?</p>' +
      '<p>If so, please click the button to leave feedback!</p>' +
      '<p><a target="_blank" href="$reviewurl" class="btn btn-outline-primary" style="padding: 5px; border: 1px solid rebeccapurple; color: rebeccapurple">Feedback</a></p>',
  },
  feedback_reminder: {
    subject: 'Don\'t Forget to Tell Us About Your Experience',
    content_internal_on: '<p>Dear $first_name,</p>' +
      '<p>Thank you for allowing $businessname to serve you. Your feedback is very important to us.</p>' +
      '<p>Would you be willing to take a moment to tell us about your experience? If so, please click here to complete your review.</p>' +
      '<p><a target="_blank" href="$reviewurl" class="btn btn-outline-primary" style="padding: 5px; border: 1px solid rebeccapurple; color: rebeccapurple">Feedback</a></p>',
    content_internal_off: '<p>Dear $first_name,</p>' +
      '<p>Thank you for allowing $businessname to serve you. Your feedback is very important to us.</p>' +
      '<p>Would you be willing to take a moment to tell us about your experience? If so, please click here to complete your review.</p>' +
      '<p><a target="_blank" href="$feedbacklink" class="btn btn-outline-primary" style="padding: 5px; border: 1px solid rebeccapurple; color: rebeccapurple">Feedback</a></p>',
  },
  positive_feedback: {
    subject: 'Thank You for Sharing Your Experience',
    content: '<p>Dear $first_name,</p>' +
      '<p>Thank you for telling us about your experience with us! We pride ourselves on delivering you the service you deserve.</p>' +
      '<p>Could you do us a huge favor? We\'d love it if you shared your experience with others. Click one of the links below to post what you told us. ' +
      'It only takes a few seconds, and we\'d really appreciate it!</p>' +
      '<p></p>' +
      '<table style="width: 100%"><tr><td style="text-align: center">$googlepluslocal</td>' +
      '<td style="text-align: center">$facebook</td>' +
      '<td style="text-align: center">$yelp</td></tr></table>',
  },
  negative_feedback: {
    subject: 'We\'re Sorry to Hear About Your Poor Experience',
    content: '<p>We\'re very sorry to hear about your poor experience with us. Thank you for taking the time to tell us about it. ' +
      'We\'ll be in touch shortly to make good on your experience.</p>' +
      '<p>If you would like to contact me immediately, please email me at  $businessemail</p>' +
      '<p>Thank you for allowing $businessname to serve you. Your feedback is very important to us.</p>',
  },
  negative_email: {
    subject: 'You’ve Received Some Negative Feedback from One of Your Customers',
    content: '<p>One of your valued customers just shared their feedback with you and there may be some concerns you may want to address. ' +
      'While their feedback may be less than favorable, the good news is that over 95% of customers will return to your business if you can ' +
      'address their concerns promptly. The fact they took the time to leave you feedback means they are likely willing to give you a second chance. ' +
      'Don’t delay, see what they had to say.</p>',
  },
  positive_email: {
    subject: '',
    content: '',
  },
  customer_sugestion: {
    subject: '',
    content: '',
  },
  reforce_review_answer: {
    subject: '',
    content: '',
  },
  design_1: {
    before: '<div width="100%" style="background: #f8f8f8; padding: 0px 0px; font-family:arial; line-height:28px; height:100%;  width: 100%; color: #514d6a;">\n' +
      '                  <div style="max-width: 700px; padding:50px 0;  margin: 0px auto; font-size: 14px">\n' +
      '                    <table border="0" cellpadding="0" cellspacing="0" style="width: 100%">\n' +
      '                      <tbody>\n' +
      '                         <tr>\n' +
      '                           <td style="vertical-align: top; padding: 50px; background-color: #a864a8" align="center"><a target="_blank"><img width="100" src="' + ((typeof localStorage.currentUser !== 'undefined' && JSON.parse(localStorage.currentUser).business !== null) ? ((typeof JSON.parse(localStorage.currentUser).business === 'string') ? JSON.parse(JSON.parse(localStorage.currentUser).business).business_logo : JSON.parse(localStorage.currentUser).business.business_logo) : '') + '" alt="Business Logo" style="border:none"></a> </td>\n' +
      '                         </tr>\n' +
      '                         <tr>\n' +
      '                           <td style="vertical-align: top; padding: 10px; background-color: white" align="center"><img width="50" src="https://reforce-files.s3.amazonaws.com/logos/icon5.png" alt="Business Logo" style="border:none"></td>\n' +
      '                         </tr>\n' +
      '                      </tbody>\n' +
      '                    </table>\n' +
      '                    <div style="padding: 20px; background: #fff;">',
    after: '</div>\n' +
      '                    <div style="text-align: center; font-size: 12px; color: white; background-color: dimgray">\n' +
      '                      <div class="row"><div class="col-md-4"></div><div class="col-md-4 text-center">$facebook $googlepluslocal $yelp</div><div class="col-md-4"></div></div>' +
      '                         <p>Copyright ' + (new Date().getFullYear()) + '<br>' +
      '                         All Rights Reserved</p>' +
      '                         <p> $businessaddress <br>\n' +
      '                           $businessname<br>\n' +
      '                        <a href="$unslink" style="color: #b2b2b5; text-decoration: underline;">Unsubscribe</a> </p>\n' +
      '                    </div>\n' +
      '                  </div>\n' +
      '                </div>',
  },
  design_2: {
    before: '<div width="100%" style="background: #f8f8f8; padding: 0px 0px; font-family:arial; line-height:28px; height:100%;  width: 100%; color: #514d6a;">\n' +
      '                <div style="max-width: 700px; padding:50px 0;  margin: 0px auto; font-size: 14px">\n' +
      '                  <table border="0" cellpadding="0" cellspacing="0" style="width: 100%;">\n' +
      '                    <tbody>\n' +
      '                    <tr>\n' +
      '                       <td style="width: 75%; padding: 20px; background-color: #32004b; color: white; text-align: center"><a target="_blank"><img width="100" src="' + ((typeof localStorage.currentUser !== 'undefined' && JSON.parse(localStorage.currentUser).business !== null) ? ((typeof JSON.parse(localStorage.currentUser).business === 'string') ? JSON.parse(JSON.parse(localStorage.currentUser).business).business_logo : JSON.parse(localStorage.currentUser).business.business_logo) : '') + '" alt="Business Logo" style="border:none"></a></td>' +
      '                       <td style="width: 25%; padding: 20px; background-color: #8560a8; text-align: center"><img src="https://reforce-files.s3.amazonaws.com/logos/029-digital-campaign-1.png" width="50"></td>' +
      '                    </tr>\n' +
      '                    </tbody>\n' +
      '                  </table>\n' +
      '                  <div style="padding: 20px; background: #fff;">\n',
    after: '</div>\n' +
      '                  <table border="0" cellpadding="0" cellspacing="0" style="width: 100%; background-color: #8560a8; color: white">\n' +
      '                    <tbody>\n' +
      '                    <tr>\n' +
      '                      <td style="text-align: left; width: 60%">' +
      '                         <div style="text-align: left; font-size: 12px; margin-top: 10px; margin-left: 20px">\n' +
      '                         <p>Copyright ' + (new Date().getFullYear()) + '<br>' +
      '                         All Rights Reserved</p>' +
      '                           <p> $businessname <br>\n' +
      '                               $businessaddress <br>\n' +
      '                           <a href="$unslink" style="text-decoration: underline;">Unsubscribe</a> </p>\n' +
      '                         </div>\n' +
      '                       </td>\n' +
      '                      <td style="text-align: center">' +
      '                        <table border="0" cellpadding="0" cellspacing="0" style="width: 100%">\n' +
      '                          <tbody>\n' +
      '                          <tr style="color: black">\n' +
      '                            <td style="width: 10%"></td>\n' +
      '                            <td class="text-center">$facebook</td>\n' +
      '                            <td class="text-center">$googlepluslocal</td>\n' +
      '                            <td class="text-center">$yelp</td>\n' +
      '                            <td style="width: 10%"></td>\n' +
      '                          </tr>\n' +
      '                          </tbody>\n' +
      '                        </table>\n' +
      '                       </td></tr>\n' +
      '                    </tbody>\n' +
      '                  </table>\n' +
      '                </div>',
  },
  design_3: {
    before: '<div width="100%" style="background: #f8f8f8; padding: 0px 0px; font-family:arial; line-height:28px; height:100%;  width: 100%; color: #514d6a;">\n' +
      '                <div style="max-width: 700px; padding:50px 0;  margin: 0px auto; font-size: 14px">\n' +
      '                  <table border="0" cellpadding="0" cellspacing="0" style="width: 100%">\n' +
      '                    <tbody>\n' +
      '                    <tr>\n' +
      '                      <td colspan="5" style="vertical-align: top; padding-bottom:5px;" align="center">\n' +
      '                        <a target="_blank"><img width="100" src="' + ((typeof localStorage.currentUser !== 'undefined' && JSON.parse(localStorage.currentUser).business !== null) ? ((typeof JSON.parse(localStorage.currentUser).business === 'string') ? JSON.parse(JSON.parse(localStorage.currentUser).business).business_logo : JSON.parse(localStorage.currentUser).business.business_logo) : '') + '" alt="Business Logo" style="border:none"></a>\n' +
      '                        <hr>\n' +
      '                        <div style="text-align: center; font-size: 12px; color: #b2b2b5; margin-top: 20px">\n' +
      '                          <p> $businessname <br> $businessaddress </p>\n' +
      '                        </div>\n' +
      '                      </td>\n' +
      '                    </tr>\n' +
      '                    </tbody>\n' +
      '                  </table>\n' +
      '                  <table border="0" cellpadding="0" cellspacing="0" style="width: 100%;">\n' +
      '                    <tbody>\n' +
      '                    <tr>\n' +
      '                      <td style="background:#00c0c8; padding:20px; color:#fff; text-align:center;">\n' +
      '                        <table border="0" cellpadding="0" cellspacing="0" style="width: 100%">\n' +
      '                          <tbody>\n' +
      '                          <tr style="color: white">\n' +
      '                            <td style="width: 20%"></td>\n' +
      '                            <td class="text-center">$facebook</td>\n' +
      '                            <td class="text-center">$googlepluslocal</td>\n' +
      '                            <td class="text-center">$yelp</td>\n' +
      '                            <td style="width: 20%"></td>\n' +
      '                          </tr>\n' +
      '                          </tbody>\n' +
      '                        </table>\n' +
      '                      </td>\n' +
      '                    </tr>\n' +
      '                    </tbody>\n' +
      '                  </table>\n' +
      '                  <div style="padding: 40px; background: #fff;">',
    after: '</div>\n' +
      '                  <div style="text-align: center; font-size: 12px; color: #b2b2b5; margin-top: 20px">\n' +
      '                         <p>Copyright ' + (new Date().getFullYear()) + '<br>' +
      '                         All Rights Reserved</p>' +
      '                    <p> <a href="$unslink" style="color: #b2b2b5; text-decoration: underline;">Unsubscribe</a> </p>\n' +
      '                  </div>\n' +
      '                </div>\n' +
      '              </div>',
  },
};
