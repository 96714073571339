import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-alert-settings',
  templateUrl: './email-alert-settings.component.html',
  styleUrls: ['./email-alert-settings.component.css']
})
export class EmailAlertSettingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  get getPos(): string {
    return 'positive';
  }

  get getNeg(): string {
    return 'negative';
  }

}
