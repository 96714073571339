import {Component, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import * as $ from "jquery";
import {CustomersComponent} from "../customers/customers.component";
import {TemplatesComponent} from "../templates/templates.component";
import {first} from "rxjs/operators";
import {EmailTemplatesService} from "../_services/email-templates.service";
import {UploadAdapter} from "../models/uploadAdapter";
import {PreviewEmailPageComponent} from "../preview-email-page/preview-email-page.component";
import {environment} from "../models/enviroment";
import {TemplateDesignsComponent} from "../template-designs/template-designs.component";
import Swal from "sweetalert2";

@Component({
  selector: 'app-new-email-template',
  templateUrl: './new-email-template.component.html',
  styleUrls: ['./new-email-template.component.css']
})
export class NewEmailTemplateComponent implements OnInit {

  private _parent: TemplatesComponent ;
  @Input() set parent(value: TemplatesComponent ) {
    this._parent = value;
  }

  @ViewChild(PreviewEmailPageComponent, null) previewModal: PreviewEmailPageComponent;
  @ViewChild(TemplateDesignsComponent, null) designsModal: TemplateDesignsComponent;

  public spinner = false;
  loading = false;
  submmited = false;
  templForm: FormGroup;
  where: string = '';
  ckeConfig: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private templateService: EmailTemplatesService,
    private ngZone: NgZone,
    private tmplService: EmailTemplatesService,
  ) { }

  public onReady( editor ) {
    console.log('TheUploadAdapterPlugin called');
  }

  ngOnInit() {
    this.ckeConfig = {
      extraPlugins: 'justify',
      filebrowserImageBrowseUrl: environment.appUrl + '/ckfinder/browser?command=QuickUpload&type=Images',
      filebrowserImageUploadUrl: environment.appUrl + '/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images',
      allowedContent: true,
      toolbar: [
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ], items: [ 'Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ], items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
        { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
        '/',
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
        { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
        { name: 'insert', items: [ 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
        '/',
        { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
        { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
        { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
        { name: 'others', items: [ '-' ] },
        { name: 'about', items: [ 'About' ] }
      ],
      toolbarGroups: [
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ] },
        { name: 'forms' },
        '/',
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
        { name: 'links' },
        { name: 'insert' },
        '/',
        { name: 'styles' },
        { name: 'colors' },
        { name: 'tools' },
        { name: 'others' },
        { name: 'about' }
      ],
    };
    this.templForm = this.formBuilder.group({
      id: ['', []],
      emailtemplate: ['', Validators.required],
      subject: ['', Validators.required],
      emailcontent: ['', []],
      smscontent: ['', []],
      sendername: ['', Validators.required],
      senderemail: ['', [Validators.required, Validators.email]],
      startdate: ['', []],
      enddate: ['', []],
      status: ['', Validators.required],
    });
  }

  onChange($event: any): void {
    console.log("onChange");
  }

  loadTemplateFromName() {
    let name = this.templForm.controls.emailtemplate.value;
    if (this.templForm.controls.emailtemplate.value !== '') {
      this.ngZone.run( () => {
        this.spinner = true;
      });
      this.templateService.showFromName(this.templForm.controls.emailtemplate.value).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data && data['template'] && data['template']['email_template_name']) {
              this.templForm.controls.id.setValue(data['template']['id']);
              this.templForm.controls.subject.setValue(data['template']['email_subject']);
              this.templForm.controls.emailcontent.setValue(data['template']['email_content']);
              this.templForm.controls.smscontent.setValue(data['template']['sms_text']);
              this.templForm.controls.sendername.setValue(data['template']['sender_name']);
              this.templForm.controls.senderemail.setValue(data['template']['sender_email']);
              this.templForm.controls.startdate.setValue(data['template']['start_date']);
              this.templForm.controls.enddate.setValue(data['template']['end_date']);
              this.templForm.controls.status.setValue(data['template']['status']);
            } else {
              this.templForm.reset();
              this.templForm.controls.emailtemplate.setValue(name);
            }
          },
          error => {
            console.log(error);
          },
          () => {
            this.ngZone.run( () => {
              this.spinner = false;
            });
          }
        );
    }
  }

  loadTemplate(id: number) {
    if (id >= 0 && id.toString() !== '') {
      this.ngZone.run( () => {
        this.spinner = true;
      });
      console.log('load user in', id);
      this.templateService.show(id).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data && data['template']) {
              this.templForm.controls.id.setValue(id);
              this.templForm.controls.emailtemplate.setValue(data['template']['email_template_name']);
              this.templForm.controls.subject.setValue(data['template']['email_subject']);
              this.templForm.controls.emailcontent.setValue(data['template']['email_content']);
              this.templForm.controls.smscontent.setValue(data['template']['sms_text']);
              this.templForm.controls.sendername.setValue(data['template']['sender_name']);
              this.templForm.controls.senderemail.setValue(data['template']['sender_email']);
              this.templForm.controls.startdate.setValue(data['template']['start_date']);
              this.templForm.controls.enddate.setValue(data['template']['end_date']);
              this.templForm.controls.status.setValue(data['template']['status']);
            }
          },
          error => {
            console.log(error);
          },
          () => {
            this.ngZone.run( () => {
              this.spinner = false;
            });
          }
        );
    }
  }

  get f() { return this.templForm.controls; }

  onSubmit() {
    this.submmited = true;

    if (this.templForm.invalid) {
      return;
    }
    this.loading = true;
    console.log(this.templForm.getRawValue());
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.templateService.register(this.templForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Registration Successful', false);
          this.parent.loadEmailTemplates();
          this.closeModal();
          this.loading = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  openMergeFieldsModal(type: string) {
    this.where = type;
    $('.merge-fields-modal').modal('show');
  }

  openDesignsModal() {
    if (this.templForm.controls.emailtemplate.value !== '') {
      this.designsModal.loadTemplates();
      $('.template-designs-modal').modal('show');
    } else {
      Swal.fire('Missing Step ...', 'Please select from which email template you would like the design.', 'warning');
    }
  }

  openPreviewModal(type: string) {
    this.where = type;
    /*this.previewModal.html_replaced = '';
    if (type === 'sms')
      this.previewModal.html = this.templForm.controls.smscontent.value;
    else
      this.previewModal.html = this.templForm.controls.emailcontent.value;
    $('.preview-email-page').modal('show');
    this.previewModal.replaceHtml();*/
    let html = '';
    if (type === 'sms')
      html = this.templForm.controls.smscontent.value; // Get the value of text area and run HTML code
    else
      html = this.templForm.controls.emailcontent.value; // Get the value of text area and run HTML code

    this.ngZone.run( () => {
      this.spinner = true;
    });
    const uploadData = new FormData();
    uploadData.append('html', html);
    this.tmplService.replaceHtml(uploadData).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['html']) {
            const previewOpen = window.open("", "previewOpen", "width=700, height=500");
            previewOpen.document.body.innerHTML = data['html'];
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  closeMergeFieldsModal() {
    $('.merge-fields-modal').modal('hide');
  }

  openModal() {
    $('.new-template-modal').modal('show');
  }

  closeModal() {
    $('.new-template-modal').modal('hide');
    this.submmited = false;
    this.templForm.reset();
  }

  get parent(): TemplatesComponent {
    return this._parent;
  }

  get self(): NewEmailTemplateComponent {
    return this;
  }

}
