import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as $ from "jquery";
import 'datatables.net';
import 'datatables.net-bs4';
import {first} from "rxjs/operators";
import {BusinessesService} from "../_services/businesses.service";
import {NewUserComponent} from "../new-user/new-user.component";
import {NewBusinessComponent} from "../new-business/new-business.component";
import Swal from "sweetalert2";
import {environment} from "../models/enviroment";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css']
})
export class BusinessComponent implements OnInit, OnDestroy {

  @ViewChild(NewBusinessComponent, null) newBusinessModal: NewBusinessComponent;

  businesses = [];
  public table = null;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions = {};
  dtTrigger = new Subject();

  constructor(private busService: BusinessesService) { }

  ngOnInit() {
    this.loadBusinesses();
    this.loadWindowFunctions();
    this.dtOptions = {
      // Declare the use of the extension in the dom parameter
      dom: 'LBlfrtip',
      // Configure the buttons
      buttons: [
        'copy',
        'print',
        'excel',
      ],
      responsive: true,
    };
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  openModal() {
    $('.new-business-modal').modal('show');
  }

  get self(): BusinessComponent {
    return this;
  }

  deleteAll() {
    let todelete = [];
    $('.business_check:checkbox:checked').each(function() {
      todelete.push(parseInt(this.id.split('_')[1]));
    });
    console.log(todelete);
    Swal.queue([{
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/businesses/delete-sel', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({selected: todelete}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your businesses has been deleted.'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to delete your businesses.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0])
        this.loadBusinesses();
    });
  }

  deleteBusiness(id: string) {
    console.log('delete in', id);
    Swal.queue([{
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/businesses/delete/' + id, {
          method: 'get',
          headers: new Headers({
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
        }).then(data => {
          console.log(data);
          console.log(JSON.parse(localStorage.currentUser).token);
          Swal.insertQueueStep({
            type: 'success',
            title: 'Your business has been deleted.'
          });
        })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to delete your business.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0])
        this.loadBusinesses();
    });
  }

  editBusiness(id: number) {
    console.log('edit in', id);

    this.newBusinessModal.loadBusiness(id);
    this.openModal();
  }

  manageBusiness(id: string) {
    console.log('business_id', id);
    Swal.queue([{
      title: 'Are you sure?',
      text: 'You are going to ghost the current business account!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, ghost it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/businesses/ghost', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({selected: id}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            let aux = JSON.parse(localStorage.currentUser);
            aux['business'] = JSON.stringify(data['business']);
            localStorage.currentUser = JSON.stringify(aux);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your businesses has been ghosted.'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to ghost your businesses.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0]) {
        location.href = '/dashboard';
      }
    });
  }

  resendCredentials(id: string) {
    console.log('business_id', id);
    Swal.queue([{
      title: 'Are you sure?',
      text: 'You are about to send a reset password link to the current client!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, send it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/businesses/reset-password', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({selected: id}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            Swal.insertQueueStep({
              type: 'success',
              title: 'The reset link has been send it.'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to send reset link.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0]) {
        //location.href = '/dashboard';
      }
    });
  }

  loadWindowFunctions() {
    window['deleteBusiness'] = (id: string) => {
      console.log('delete in', id);
      Swal.queue([{
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(environment.appUrl + '/api/businesses/delete/' + id, {
            method: 'get',
            headers: new Headers({
              Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
            }),
          }).then(data => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your business has been deleted.'
            });
          })
            .catch((error) => {
              console.log(error);
              Swal.insertQueueStep({
                type: 'error',
                title: 'Unable to delete your business.'
              });
            });
        }
      }]).then((result) => {
        if (result.value[0])
          this.loadBusinesses();
      });
    };
    window['editBusiness'] = (id: number) => {
      console.log('edit in', id);

      this.newBusinessModal.loadBusiness(id);
      this.openModal();
    };
    window['manageBusiness'] = (id: string) => {
      console.log('edit in', id);

    };
    window['resendCredentials'] = (id: string) => {
      console.log('edit in', id);

    };
  }

  checkAll() {
    console.log('in');
    if ($('#checkallbusinesses')[0]['checked']) {
      console.log('in true');
      $('.business_check').each(function(){
        $(this).attr('checked', 'true');
      });
    } else {
      console.log('false');
      $('.business_check').each(function() {
        $(this).removeAttr('checked');
      });
    }
  }

  loadBusinesses() {
    $('.preloader').show();
    this.busService.getAll().pipe(first())
      .subscribe(
        data => {
          if (data && data['data'])
            this.businesses = data['data'];
          console.log('success', this.businesses);

          if (this.table !== null) {
            this.table.destroy();
          }

          if (this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              // Destroy the table first
              dtInstance.destroy();
              // Call the dtTrigger to rerender again
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
          console.log('dtOptions', this.dtOptions);
          $('.preloader').hide();
        },
        error => {
          console.log('error', error);
          $('.preloader').hide();
        }
      );
  }

}
