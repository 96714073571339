import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {User} from "../models/user";
import {environment} from "../models/enviroment";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<User[]>(environment.appUrl + '/api/users');
  }

  getAllAgency() {
    return this.http.get<[]>(environment.appUrl + '/api/users/agency');
  }

  getUser(id: number) {
    return this.http.get<[]>(environment.appUrl + `/api/users/get/${id}`);
  }

  register(user: User) {
    return this.http.post(environment.appUrl + '/api/users/new', user);
  }

  registerAdv(user) {
    return this.http.post(environment.appUrl + '/api/users/newadv', user);
  }

  updateProfile(user) {
    return this.http.post(environment.appUrl + '/api/users/updprofile', user);
  }

  update(user: User) {
    return this.http.put(environment.appUrl + '/api/users/edit', user);
  }

  resetPsw(form) {
    return this.http.post(environment.appUrl + '/api/reset-password', form);
  }

  delete(id: number) {
    return this.http.delete(environment.appUrl + `/api/users/delete/${id}`);
  }

  show(id: number) {
    return this.http.get(environment.appUrl + `/api/users/show/${id}`);
  }

  changePass(form) {
    return this.http.post(environment.appUrl + '/api/change-password', form);
  }

}
