import {Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {first} from "rxjs/operators";
import {BusinessesService} from "../_services/businesses.service";

@Component({
  selector: 'app-feedback-threshold',
  templateUrl: './feedback-threshold.component.html',
  styleUrls: ['./feedback-threshold.component.css']
})
export class FeedbackThresholdComponent implements OnInit {

  loading = false;
  submmited = false;
  feedForm: FormGroup;
  public spinner = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private busService: BusinessesService,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.feedForm = this.formBuilder.group({
      business_id: ['', []],
      posfeedthreshold: ['', Validators.required],
      automemailattempts: ['', Validators.required],
      numdaysemailfreq: ['', Validators.required],
      numfollowemailattempts: ['', Validators.required],
      numdaysfollowfreq: ['', Validators.required],
    });
    this.loadForm();
  }

  loadForm() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.busService.getCurrent().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['business']) {
            let business = data['business'];
            this.feedForm.controls.business_id.setValue(business.id);
            this.feedForm.controls.posfeedthreshold.setValue(business.feedback_threshold);
            this.feedForm.controls.automemailattempts.setValue(business.automate_denail_attempts);
            this.feedForm.controls.numdaysemailfreq.setValue(business.email_frequency);
            this.feedForm.controls.numfollowemailattempts.setValue(business.automated_post_feedback_email_attempts);
            this.feedForm.controls.numdaysfollowfreq.setValue(business.post_feedback_email_frequency);
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  onSubmit() {
    this.submmited = true;
    if (this.feedForm.invalid) {
      return;
    }
    this.loading = true;
    console.log(this.feedForm.getRawValue());
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.busService.updateThreshold(this.feedForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Update Successful', false);
          this.loading = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  get f() { return this.feedForm.controls; }

}
