import {Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {first} from "rxjs/operators";
import {SocialMediasService} from "../_services/social-medias.service";
import {SocialMedia} from "../models/SocialMedia";
import * as $ from 'jquery';
import 'collect.js';
import collect, {Collection} from "collect.js";
import {BusinessesService} from "../_services/businesses.service";
import {environment} from "../models/enviroment";

@Component({
  selector: 'app-social-sharing',
  templateUrl: './social-sharing.component.html',
  styleUrls: ['./social-sharing.component.css']
})
export class SocialSharingComponent implements OnInit {

  loading = false;
  submmited = false;
  public spinner = false;

  loading2 = false;
  submmited2 = false;

  socialForm: FormGroup;
  newSources: FormGroup;
  id = -1;

  neSourcesCont = 0;

  socialmedias: SocialMedia[] = [];
  selSocialMedias: string[] = [];
  errors: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private socialService: SocialMediasService,
    private busService: BusinessesService,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.socialForm = this.formBuilder.group({
      business_id: ['', []],
    });
    this.newSources = this.formBuilder.group({
      business_id: ['', []],
    });
    this.loadSocialMedias();
    this.loadWindowsFunctions();
  }

  onSubmit() {
    this.errors = [];
    this.submmited = true;
    if (this.socialForm.invalid) {
      return;
    }
    this.loading = true;
    console.log(this.socialForm.getRawValue());
    let selected = [];
    $('.customSwitches').each(function() {
        if (this['checked'])
          selected.push(this.id);
    });
    console.log(selected);
    let result = [];
    for (let i in selected) {
      let id = selected[i].split('_')[1];
      if (this.socialForm.controls['smedia_'+id].value.toString().trim() === '') {
        this.errors.push(id);
      } else {
        result[id] = this.socialForm.controls['smedia_'+id].value.toString().trim();
      }
    }
    console.log(this.errors);
    if (this.errors.length > 0) {
      this.loading = false;
      return;
    } else {
      this.ngZone.run( () => {
        this.spinner = true;
      });
      this.busService.updateSocialSharing(this.socialForm.value).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            this.alertService.success('Update Successful', false);
            this.loading = false;
          },
          error => {
            console.log(error);
            this.alertService.error(error);
            this.loading = false;
          },
          () => {
            this.ngZone.run( () => {
              this.spinner = false;
            });
          }
        );

    }
  }

  resetNewSourcesForm() {
    for(let index in this.newSources.controls) {
      if (index.indexOf('media') !== -1)
        this.newSources.removeControl(index);
    }
    $('#newSourcesContent').html('');
    this.neSourcesCont = 0;
  }

  onSubmitNewSources() {
    this.submmited2 = true;
    console.log(this.newSources.getRawValue());
    let conf = false;
    for(let index in this.newSources.controls) {
      if (index.indexOf('addmedialogo_') !== -1) {
        let id =  index.split('_')[1];
        if (!conf && (this.newSources.controls['medialogo_'+id].value.trim() === '' || this.newSources.controls['addmediaurl_'+id].value.trim() === '' || this.newSources.controls['addmedianame_'+id].value.trim() === '')) {
          let conf = confirm('There are empty fields. Do you want to continue?');
          if(!conf) {
            this.submmited2 = false;
            return;
          }
        }
      }
    }
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.loading2 = true;
    this.busService.addSocialSharing(this.newSources.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Update Successful', false);
          this.resetNewSourcesForm();
          this.addNewSources(data['medias'], data['business_media']);
          this.loading2 = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading2 = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  addNewSources(medias, business_media) {
    for (let i in medias) {
      this.socialmedias.push(medias[i]);
      this.selSocialMedias.push(medias[i].id);
      this.socialForm.addControl( 'smedia_' + medias[i].id, this.formBuilder.control(business_media[i].url, []));
    }
  }

  loadWindowsFunctions() {
    window['deleteNewSourceRow'] = (id: string) => {
      console.log('delete in', id);
      this.newSources.removeControl('addmedialogo_' + id);
      this.newSources.removeControl('medialogo_' + id);
      this.newSources.removeControl('addmediaurl_' + id);
      this.newSources.removeControl('addmedianame_' + id);
      $('#newsource_' + id).remove();
    };
  }

  onFileChanged(event) {
    console.log(event);
    console.log(event.target.files[0]);
    console.log(event.target.attributes[2].nodeValue);
    if (event.target.files[0].type.match(/image\/*/) == null) {

      return;
    } else {

    }

    const uploadData = new FormData();
    this.id = event.target.attributes[2].nodeValue.split('_')[1];
    $('.logoupload' + this.id).css('display', 'block');
    console.log($('.logoupload' + this.id).css('display'));

    let percent = 0;
    uploadData.append('myFile', event.target.files[0], event.target.files[0].name);
    this.httpClient.post(environment.appUrl + '/api/businesses/social-icon-upload', uploadData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(event1 => {
      percent += 20;
      $('.logoupload' + this.id).html('<div class="progress-bar bg-info logoupload" style="width: '+ percent +'%; height:10px;" role="progressbar">'+ percent +'%</div>');
      console.log(event1); // handle event here
      if (event1['body'] && event1['body'].logo) {
        console.log(this.newSources.getRawValue());
        console.log(this.id);
        this.newSources.controls['medialogo_'+this.id].setValue(event1['body'].logo);
        $('.logoupload' + this.id).css('display', 'none');
      }
    });
  }

  onUrlChange(event) {
    let id = event.target.attributes[2].nodeValue.split('_')[1];
    console.log($(event.target));
    this.newSources.controls['addmediaurl_'+id].setValue($(event.target).val());
  }

  onNameChange(event) {
    let id = event.target.attributes[2].nodeValue.split('_')[1];
    console.log($(event.target));
    this.newSources.controls['addmedianame_'+id].setValue($(event.target).val());
  }

  addNewSourceControl() {
    this.newSources.addControl( 'addmedialogo_' + this.neSourcesCont, this.formBuilder.control('', []));
    this.newSources.addControl( 'medialogo_' + this.neSourcesCont, this.formBuilder.control('', []));
    this.newSources.addControl( 'addmediaurl_' + this.neSourcesCont, this.formBuilder.control('', []));
    this.newSources.addControl( 'addmedianame_' + this.neSourcesCont, this.formBuilder.control('', []));
    let content = $('#newSourcesContent');
    content.append('' +
      '<div class="row" id="newsource_'+ this.neSourcesCont +'">' +
      '                <div class="col-md-3">' +
      '                  <input type="file" class="form-control addmedialogos" formControlName="addmedialogo_' + this.neSourcesCont + '">' +
      '                  <input type="hidden" formControlName="medialogo_' + this.neSourcesCont + '"> ' +
      '                  <div class="progress m-t-20 logoupload' + this.neSourcesCont + '" style="display: none" >' +
      '                    <div class="progress-bar bg-info" style="width: 0%; height:10px;" role="progressbar">0%</div>\n' +
      '                  </div>' +
      '                </div>' +
      '                <div class="col-md-3">' +
      '                  <input type="text" class="form-control addmedianames" formControlName="addmedianame_'+ this.neSourcesCont +'">' +
      '                </div>' +
      '                <div class="col-md-4">' +
      '                  <input type="text" class="form-control addmediaurls" formControlName="addmediaurl_'+ this.neSourcesCont +'">' +
      '                </div>' +
      '                <div class="col-md-2">' +
      '                  <button type="button" onClick="window.deleteNewSourceRow('+ this.neSourcesCont +')" class="btn btn-danger">Delete</button>' +
      '                </div>' +
      '              </div>' +
      '');
    let _self = this;
    $('.addmedialogos').on('change', function(event){
      _self.onFileChanged(event);
    });
    $('.addmediaurls').on('keyup', function(event){
      _self.onUrlChange(event);
    });
    $('.addmedianames').on('keyup', function(event){
      _self.onNameChange(event);
    });
    this.neSourcesCont++;
  }

  checkOption(id: string) {
    return (this.selSocialMedias.indexOf(id) !== -1);
  }

  disableInput(id: string) {
    if (this.selSocialMedias.indexOf(id) === -1)
      this.socialForm.controls['smedia_'+id].disable();
  }

  onCheck(id: string) {
    console.log('in', $('#customSwitch_'+id)[0]['checked']);
    if ($('#customSwitch_'+id)[0]['checked']) {
      console.log('enable');
      this.selSocialMedias.push(id);
      this.socialForm.controls['smedia_'+id].enable();
    } else {
      this.selSocialMedias.splice(this.selSocialMedias.indexOf(id), 1);
      this.socialForm.controls['smedia_'+id].disable();
    }
  }

  loadSocialMedias() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.socialService.getAllForRview().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          let arr = collect(data['medias']);
          if (data && data['data']) {
            this.socialForm.controls.business_id.setValue(data['business']);
            this.newSources.controls.business_id.setValue(data['business']);
            this.socialmedias = data['data'];
            for(let i in data['data']) {
              let value = arr.where('social_media_id', data['data'][i].id).first();
              if (typeof value !== 'undefined') {
                value = value['url'];
                this.selSocialMedias.push(data['data'][i].id);
              } else {
                value = '';
              }
              console.log(value);
              this.socialForm.addControl( 'checkbox_smedia_' + data['data'][i].id, this.formBuilder.control(value, []));
              this.socialForm.addControl( 'smedia_' + data['data'][i].id, this.formBuilder.control(value, []));
            }
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  get f() { return this.socialForm.controls; }

  selectAll() {
    for (const soc of this.socialmedias) {
      // @ts-ignore
      if (this.selSocialMedias.indexOf(soc.id) === -1) {
        // @ts-ignore
        this.selSocialMedias.push(soc.id);
        this.socialForm.controls['smedia_' + soc.id].enable()
        this.socialForm.controls['checkbox_smedia_' + String(soc.id)].setValue(true);
      }
    }
  }

  unselectAll() {
    for (const soc of this.socialmedias) {
      // @ts-ignore
      if (this.selSocialMedias.indexOf(soc.id) !== -1) {
        // @ts-ignore
        this.selSocialMedias.splice(this.selSocialMedias.indexOf(soc.id), 1);
        this.socialForm.controls['smedia_' + soc.id].disable();
        this.socialForm.controls['checkbox_smedia_' + String(soc.id)].setValue(false);
      }
    }
  }
}
