import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {User} from "../models/user";
import {Agency} from "../models/agency";
import {AgencyTools} from "../models/agencytools";
import {AgencyBanner} from "../models/agencybanner";
import {AgencyWhiteLabel} from "../models/agencywhitelabel";
import {AgencyCustomCss} from "../models/AgencyCustomCss";
import {environment} from "../models/enviroment";
import {AgencyEmailSettings} from "../models/AgencyEmailSettings";

@Injectable({
  providedIn: 'root'
})
export class AgencyService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Agency[]>(environment.appUrl + '/api/agencies');
  }

  getAllSons() {
    return this.http.get<[]>(environment.appUrl + '/api/agencies/sons');
  }

  register(agency: Agency) {
    return this.http.post(environment.appUrl + '/api/agencies/new', agency);
  }

  update(agency: Agency) {
    return this.http.put(environment.appUrl + '/api/agencies/edit', agency);
  }

  updateInfo(agency: AgencyTools) {
    return this.http.put(environment.appUrl + '/api/agencies/edit', agency);
  }

  updateBanner(agency: AgencyBanner) {
    return this.http.put(environment.appUrl + '/api/agencies/editbanner', agency);
  }

  updateWhiteLabel(agency: AgencyWhiteLabel) {
    return this.http.put(environment.appUrl + '/api/agencies/editwhitelabel', agency);
  }

  updateCustomCss(agency: AgencyCustomCss) {
    return this.http.put(environment.appUrl + '/api/agencies/editcustomcss', agency);
  }

  delete(id: number) {
    return this.http.delete(environment.appUrl + `/api/agencies/delete/${id}`);
  }

  show(id: number) {
    return this.http.get(environment.appUrl + `/api/agencies/show/${id}`);
  }

  getCurrent() {
    return this.http.get(environment.appUrl + `/api/agencies/myagency`);
  }

  updateEmailSettings(agencyEmailSettings: AgencyEmailSettings) {
    return this.http.post(environment.appUrl + '/api/agencies/emailsettings', agencyEmailSettings);
  }

  getEmailSettings() {
    return this.http.get(environment.appUrl + '/api/agencies/emailsettings');
  }
}
