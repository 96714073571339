import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import { AuthenticationService } from '../_services/authentication.service';
import {AlertService} from "../_services/alert.service";

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private alert: AlertService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = this.authenticationService.currentUserValue;
    let user = JSON.parse(localStorage.getItem('currentUser'));
    if (typeof user !== 'undefined' && user !== null && typeof user['username'] !== 'undefined'
      && user['username'] !== null && user['username'] === 'accounting@thinkstrategic.com' && request.method !== 'GET') {
      this.alert.error('You don\'t have enough permissions for this action.');
      return throwError(null);
    }
    if (currentUser && currentUser.token && !request.headers.has('Authorization')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`
        }
      });
    }
    console.log('http intersect');
    return next.handle(request);
  }
}
