import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {first} from "rxjs/operators";
import {UserService} from "../_services/user.service";
import {AlertService} from "../_services/alert.service";

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {

  public token = '';
  public email = '';
  newPass: FormGroup;
  loading =  false;
  submmited =  false;
  returnUrl: string;
  showLogin = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private userServ: UserService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.token = params['token'];
      this.email = params['email'];
      console.log(this.token);
    });
    console.log("login init");
    this.newPass = this.formBuilder.group({
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
      token: [this.token, []],
      email: [this.email, []],
    });
  }

  get f() { return this.newPass.controls; }

  onSubmit() {
    this.submmited = true;

    if (this.newPass.invalid) {
      console.log('this.newPass.invalid', this.newPass.errors);
      return;
    }
    console.log('login comp in');
    this.loading = true;
    this.userServ.changePass(this.newPass.value)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (typeof data['error'] !== 'undefined') {
            this.alertService.error(data['error']);
          } else {
            this.alertService.success('Password changed!');
            location.href = 'login';
          }
        },
        error => {
          console.log('login comp data out');
          this.alertService.error('Oops.. We wasn\'t able to change your password. Please try again.');
        },
        () => {
          this.ngZone.run( () => {
            this.loading = false;
            this.submmited = false;
          });
        }
      );
  }

}
