import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-tools',
  templateUrl: './business-tools.component.html',
  styleUrls: ['./business-tools.component.css']
})
export class BusinessToolsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  showIframePreview(id: string) {
    console.log($($('#' + id).text()).attr('src'));
    const previewOpen = window.open($($('#' + id).text()).attr('src'), "previewOpen", "width=700, height=500");
  }

}
