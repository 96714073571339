import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Customer} from "../models/customer";
import {environment} from "../models/enviroment";

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Customer[]>(environment.appUrl + `/api/customers`);
  }

  getAllFilter(filters) {
    return this.http.post(environment.appUrl + `/api/customers/filters`, filters);
  }

  register(customer: Customer) {
    return this.http.post(environment.appUrl + `/api/customers/new`, customer);
  }

  importCsv(customers) {
    return this.http.post(environment.appUrl + `/api/customers/importcsv`, customers);
  }

  update(customer: Customer) {
    return this.http.put(environment.appUrl + '/api/customers/edit', customer);
  }

  delete(id: number) {
    return this.http.delete(environment.appUrl + `/api/customers/delete/${id}`);
  }

  show(id: number) {
    return this.http.get(environment.appUrl + `/api/customers/show/${id}`);
  }

  sendReview(option: string, token: string) {
    return this.http.get(environment.appUrl + `/api/customers/sendreview/${option}/${token}`);
  }

  sendStarReview(starForm) {
    return this.http.post(environment.appUrl + `/api/customers/sendstarreview/`, starForm);
  }

  unsubscribe(token: string) {
    return this.http.get(environment.appUrl + `/api/customers/unsuscribe/${token}`);
  }

  addToRevSequence(customers) {
    return this.http.post(environment.appUrl + `/api/customers/sequence`, customers);
  }

}
