import {Component, NgZone, OnInit} from '@angular/core';
import {icon, latLng, marker, tileLayer} from "leaflet";
import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet';
import {ActivatedRoute, Route, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {first} from "rxjs/operators";
import {BusinessesService} from "../_services/businesses.service";
import {environment} from "../models/enviroment";
declare var FB: any;

@Component({
  selector: 'app-public-micro-page',
  templateUrl: './public-micro-page.component.html',
  styleUrls: ['./public-micro-page.component.css']
})
export class PublicMicroPageComponent implements OnInit {

  public options = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 5,
    center: latLng(46.879966, -121.726909)
  };
  public map: L.Map;

  public show = true;
  public token = '';
  public spinner = false;
  spinner2 = false;
  spinner3 = false;
  spinner4 = false;
  business = null;
  city = null;
  state = null;
  country = null;
  reviews = [];
  hours = [];
  overall_rating = 0;
  total_reviews = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private ngZone: NgZone,
    private busService: BusinessesService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.token = params['token'];
      console.log(this.token);
    });

    this.loadBusiness();
    this.loadGoogleUser();

    /*(window as any).fbAsyncInit = function() {
      FB.init({
        appId      : '394951114363257',
        cookie     : true,
        xfbml      : true,
        version    : 'v3.1'
      });
      FB.AppEvents.logPageView();
    };

    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));*/
  }

  goToReviewForm() {
    this.router.navigate(['public/review/' + this.token]);
  }

  transformTime(time: any) {
    if (time === '' || typeof time === 'undefined' || time === null) {
      return time;
    }
    let hour = (time.split(':'))[0];
    let min = (time.split(':'))[1];
    const part = hour > 12 ? 'pm' : 'am';
    min = (min + '').length === 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length === 1 ? `0${hour}` : hour;
    return `${hour}:${min} ${part}`;
  }

  loadBusiness() {
    this.ngZone.run( () => {
      this.spinner = true;
      this.spinner3 = true;
    });
    console.log('token', this.token);
    this.busService.getByKey(this.token).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['business']) {
            this.business = data['business'];
            this.state = data['state_name'];
            this.city = data['city_name'];
            this.country = data['country_name'];
            this.hours = JSON.parse(data['business']['business_hours']);

            this.show = true;
            this.map = L.map("map").setView([data['business']['lat'], data['business']['lng']], 14);

            let summit = marker([ data['business']['lat'], data['business']['lng'] ], {
              icon: icon({
                iconSize: [ 25, 41 ],
                iconAnchor: [ 13, 41 ],
                iconUrl: 'leaflet/marker-icon.png',
                shadowUrl: 'leaflet/marker-shadow.png'
              }),
            }).bindPopup("<b>" + data['business']['business_name'] + "</b>").openPopup();

            L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
              attribution:
                '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(this.map);
            this.map.addLayer(summit);
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
            this.spinner3 = false;
          });
        }
      );
  }

  loadGoogleUser() {
    this.ngZone.run( () => {
      this.spinner2 = true;
      this.spinner4 = true;
    });
    this.httpClient.get(environment.appUrl + '/api/reviews/google/' + this.token).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if(typeof data['yelp_reviews'] !== 'undefined' && typeof data['yelp_reviews'][0] !== 'undefined') {
            let yelp = data['yelp_reviews'][0]['reviews'];
            this.reviews = [];
            for (let ind in yelp) {
              if (yelp[ind].authorization === 1) {
                let newrev = {
                  rating: new Array(yelp[ind].rating),
                  text: yelp[ind].text,
                  created: yelp[ind].time_created,
                  url: yelp[ind].url,
                  image: yelp[ind].user.image_url,
                  name: yelp[ind].user.name,
                  profile: yelp[ind].user.profile_url,
                  icon_class: 'fab fa-yelp',
                };
                this.overall_rating += parseInt(yelp[ind].rating);
                this.total_reviews++;
                this.reviews.push(newrev);
              }
            }
          }

          const reforce = data['reforce'];
          if (reforce.length > 0) {
            for (let ind in reforce) {
              if (reforce[ind].authorization === 1) {
                let newrev = {
                  rating: new Array(reforce[ind].rating_star),
                  text: reforce[ind].rating_description,
                  created: reforce[ind].created_at,
                  url: '',
                  image: '../../assets/images/generic-avatar.png',
                  name: reforce[ind].customer.first_name + ' ' + reforce[ind].customer.last_name,
                  profile: '',
                  icon_class: 'far fa-comment',
                };
                this.overall_rating += parseInt(reforce[ind].rating_star);
                this.total_reviews++;
                this.reviews.push(newrev);
              }
            }
          }

          let google_rev = data['allreviews'];
          if(typeof google_rev !== 'undefined' && typeof google_rev[0] !== 'undefined') {
            for (let ind in google_rev) {
              if (google_rev[ind].authorization === 1) {
                let newrev = {
                  rating: new Array((google_rev[ind]['starRating'] === 'FIVE') ? 5 : (google_rev[ind]['starRating'] === 'FOUR' ? 4 : (google_rev[ind]['starRating'] === 'THREE' ? 3 : (google_rev[ind]['starRating'] === 'TWO' ? 2 : 1)))),
                  text: google_rev[ind].comment,
                  created: google_rev[ind]['createTime'],
                  url: '',
                  image: google_rev[ind]['reviewer']['profilePhotoUrl'],
                  name: google_rev[ind]['reviewer']['displayName'],
                  profile: '',
                  icon_class: 'fab fa-google',
                };
                this.overall_rating += newrev.rating.length;
                this.total_reviews++;
                this.reviews.push(newrev);
              }
            }
          }

          let facebook_rev = data['facebook'];
          if (typeof facebook_rev !== 'undefined' && typeof facebook_rev[0] !== 'undefined') {
            for (let ind in facebook_rev) {
              if (facebook_rev[ind].authorization === 1) {
                let newrev = {
                  rating: new Array((facebook_rev[ind]['recommendation_type'] === 'positive') ? 5 : 1),
                  text: facebook_rev[ind]['review_text'],
                  created: facebook_rev[ind]['created_time'],
                  url: '',
                  image: '../../assets/images/generic-avatar.png',
                  name: facebook_rev[ind]['reviewer']['name'],
                  profile: '',
                  icon_class: 'fab fa-facebook',
                };
                this.overall_rating += newrev.rating.length;
                this.total_reviews++;
                this.reviews.push(newrev);
              }
            }
          }

          this.overall_rating = (this.total_reviews > 0) ? this.overall_rating / this.total_reviews : 0;
          console.log(this.reviews);
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner2 = false;
            this.spinner4 = false;
          });
        }
      );
  }

  ngAfterViewInit() {
    console.log("asdasd");
    //this.map.invalidateSize();
  }

  onMapReady(map: L.Map) {
    this.map = map;
    setTimeout(() => {
      map.invalidateSize();
    }, 0);
  }

  onResized() {
    if (this.map) {
      setTimeout(() => {
        this.map.invalidateSize();
      }, 0);
    }
  }

}
