import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Country} from "../models/country";
import {environment} from "../models/enviroment";

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Country[]>(environment.appUrl + '/api/countries');
  }
}
