import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {FormBuilder, FormGroup} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {ReviewServiceService} from "../_services/review-service.service";
import {environment} from "../models/enviroment";
import {first} from "rxjs/operators";
import * as $ from "jquery";
import {GoogleChartInterface} from "ng2-google-charts/google-charts-interfaces";
import * as moment from "moment";
import {BusinessesService} from "../_services/businesses.service";

@Component({
  selector: 'app-agency-review-summary',
  templateUrl: './agency-review-summary.component.html',
  styleUrls: ['./agency-review-summary.component.css']
})
export class AgencyReviewSummaryComponent implements OnInit {

  spinner = false;
  reviews = [];
  results = [];
  table = null;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions = {};
  dtTrigger = new Subject();

  public filters5: { [key: string]: Object; }[] = [];
  public localFields5: Object = { text: 'Name', value: 'Code' };
  public localWaterMark5: string = 'Filter by business...';

  public filters: { [key: string]: Object; }[] = [];
  public localFields: Object = { text: 'Name', value: 'Code' };
  public localWaterMark: string = 'Filter by business...';

  public filters2: { [key: string]: Object; }[] = [
    { Name: '1 Star', Code: '1 Star' },
    { Name: '2 Star', Code: '2 Star' },
    { Name: '3 Star', Code: '3 Star' },
    { Name: '4 Star', Code: '4 Star' },
    { Name: '5 Star', Code: '5 Star' },
  ];
  public localFields2: Object = { text: 'Name', value: 'Code' };
  public localWaterMark2: string = 'Filter by rating...';

  public filters3: { [key: string]: Object; }[] = [
    { Name: 'Public', Code: 'Public' },
    { Name: 'Private', Code: 'Private' },
  ];
  public localFields3: Object = { text: 'Name', value: 'Code' };
  public localWaterMark3: string = 'Filter by status...';

  overall_rating = 0;
  total_reviews = 0;
  breakout = {
    rating_1: 0,
    rating_2: 0,
    rating_3: 0,
    rating_4: 0,
    rating_5: 0,
  };
  spinnerDonut = false;
  submmited = false;
  loading = false;

  public pieChart: GoogleChartInterface = {
    chartType: 'PieChart',
    dataTable: [
      ['Customer Feedback', 'Totals'],
      ['Feedback', 6545],
      ['No Feedback Yet', 687],
    ],
    options: {
      title: 'Feedback distribution',
      slices: {
        0: { color: '#5A6875' },
        1: { color: '#00aced' },
      }
    },
  };

  public pieChart2: GoogleChartInterface = {
    chartType: 'PieChart',
    dataTable: [
      ['Customer Feedback', 'Totals'],
      ['Recommended', 2343],
      ['No Recommended', 344],
    ],
    options: {
      title: 'Feedbacks',
      pieHole: 0.4,
      slices: {
        0: { color: '#5A6875' },
        1: { color: '#00aced' },
      }
    },
  };

  public barChart: GoogleChartInterface = {
    chartType: 'ColumnChart',
    dataTable: [
      ['Stars', 'Total'],
      ['5 Stars',  80],
      ['4 Stars',  4],
      ['3 Stars',  3],
      ['2 Stars',  2],
      ['1 Stars',  1],
    ],
    options: {
      tooltip: { isHtml: true },    // CSS styling affects only HTML tooltips.
      legend: { position: 'none' },
      bar: { groupWidth: '90%' },
      colors: ['#5A6875'],
      title: 'Rating Distribution',
      height: 365,
    },
  };

  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  daterange: any = null;

  total_feedback = 0;
  waiting = 0;
  positive = 0;
  negative = 0;
  groups = [];
  public allBusinesses: { [key: string]: Object; }[] = [];
  feedBackBusinessesSelect = [];
  reviewsBusinessesSelect = [];
  private allBusinessesReviews = [];

  constructor(
    private ngZone: NgZone,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private reviewServ: ReviewServiceService,
    private formBuilder: FormBuilder,
    private busService: BusinessesService
  ) { }

  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') );
  }

  ngOnInit() {
    this.loadReviews();
    this.getGroups();
    this.loadStats();
    this.dtOptions = {
      // Declare the use of the extension in the dom parameter
      dom: 'LBlfrtip',
      // Configure the buttons
      buttons: [
        'copy',
        'print',
        {
          extend: 'excelHtml5',
          title: 'email-templates-excel',
        },
      ],
      responsive: true,
    };
  }

  filterReviews2() {


  }

  loadStats(from = '') {
    const groupsArray = [];  let groups = '';
    $('#business_group_id option:selected').each(function () {
      groupsArray.push($(this).val());
      groups += ('groups[]=' + $(this).val() + '&');
    });
    if (from === 'groups') {
      this.feedBackBusinessesSelect = [];
      this.filters5 = (groupsArray.length > 0) ?
        this.allBusinesses.filter((item) => groupsArray.findIndex((group) => String(group) === String(item.business_group_id)) !== -1)
        : this.allBusinesses;
    }
    this.ngZone.run( () => {
      this.spinnerDonut = true;
    });
    let ids = []; let interval = '';
    $('#options_business2 option:selected').each(function () {
      ids.push($(this).val());
    });
    let param = JSON.stringify(ids);
    interval = $('#daterange').val().toString();
    if (this.daterange == null || (this.daterange.startDate === null && this.daterange.endDate === null))
      interval = 'all';
    else
      interval = this.daterange.startDate.format('YYYY-MM-DD') + ' - ' + this.daterange.endDate.format('YYYY-MM-DD');
    console.log(this.daterange);
    console.log('load emp in');
    this.httpClient.get(environment.appUrl + '/api/feedback-summary/stats/' + interval + '/' + param + ((groups !== '') ? ('?' + groups) : '')).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (typeof data['error'] === 'undefined') {
            this.barChart.dataTable = [
              ['Stars', 'Total'],
              ['5 Stars',  (typeof data['ratings']['5 Star'] !== 'undefined') ? data['ratings']['5 Star'].length : 0],
              ['4 Stars',  (typeof data['ratings']['4 Star'] !== 'undefined') ? data['ratings']['4 Star'].length : 0],
              ['3 Stars',  (typeof data['ratings']['3 Star'] !== 'undefined') ? data['ratings']['3 Star'].length : 0],
              ['2 Stars',  (typeof data['ratings']['2 Star'] !== 'undefined') ? data['ratings']['2 Star'].length : 0],
              ['1 Stars',  (typeof data['ratings']['1 Star'] !== 'undefined') ? data['ratings']['1 Star'].length : 0],
            ];
            this.barChart.component.draw();

            this.total_feedback = data['feedbacks'];
            this.waiting = data['waiting'];
            this.pieChart.dataTable = [
              ['Customer Feedback', 'Totals'],
              ['Feedback', data['feedbacks']],
              ['Not Feedback', data['waiting']],
            ];
            this.pieChart.component.draw();

            this.positive = data['pos'];
            this.negative = data['neg'];
            this.pieChart2.dataTable = [
              ['Customer Feedback', 'Totals'],
              ['Recommended', data['pos']],
              ['No Recommended', data['neg']],
            ];
            this.pieChart2.component.draw();
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinnerDonut = false;
          });
        }
      );
  }

  loadReviews() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.httpClient.get(environment.appUrl + '/api/reviews/reforce/all').pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.ngZone.run( () => {
            for (let bus of data['businesses']) {
              this.filters.push({Name: bus['business_name'], Code: bus['business_name'], business_group_id: bus['business_group_id']});
              this.filters5.push({Name: bus['business_name'], Code: bus['id'], business_group_id: bus['business_group_id']});
              this.allBusinesses.push({Name: bus['business_name'], Code: bus['id'], business_group_id: bus['business_group_id']});
              this.allBusinessesReviews.push({Name: bus['business_name'], Code: bus['business_name'], business_group_id: bus['business_group_id']});
            }
          });

          this.reviews = [];
          this.results = [];

          const reforce = data['reforce'];
          if (reforce.length > 0) {
            for (let ind in reforce) {
              let newrev = {
                id: 'reforce|' + reforce[ind]['id'],
                rating: new Array(reforce[ind].rating_star),
                text: reforce[ind].rating_description,
                created: reforce[ind].created_at,
                url: '',
                image: '../../assets/images/generic-avatar.png',
                name: reforce[ind].customer.first_name + ' ' + reforce[ind].customer.last_name,
                profile: '',
                icon_class: 'far fa-comment',
                approved: reforce[ind]['published'] !== null ? 1 : 0,
                answer: 'reforce',
                replies: reforce[ind]['answers'],
                business: reforce[ind]['business']['business_name'],
                business_group_id: reforce[ind]['business']['business_group_id'],
              };
              this.overall_rating += parseInt(reforce[ind].rating_star);
              this.total_reviews ++;
              this.reviews.push(newrev);
              this.results.push(newrev);
              this.breakout['rating_' + reforce[ind].rating_star] ++;
            }
          }

          this.overall_rating = (this.total_reviews > 0) ? this.overall_rating/this.total_reviews : 0;
          console.log(this.reviews);

          if (this.table !== null) {
            this.table.destroy();
          }

          if (this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              // Destroy the table first
              dtInstance.destroy();
              // Call the dtTrigger to rerender again
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
          console.log('dtOptions', this.dtOptions);
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  filterReviews(from = '') {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    let source = []; let stars = []; let text = ''; let status = []; let groups = [];
    $('#business_group_id_2 option:selected').each(function () {
      groups.push($(this).val());
    });

    if (from === 'groups') {
      this.reviewsBusinessesSelect = [];
      this.filters = (groups.length > 0) ?
        this.allBusinessesReviews.filter((item) => groups.findIndex((group) => String(group) === String(item.business_group_id)) !== -1)
        : this.allBusinessesReviews;
    }
    $('#options_business option:selected').each(function () {
      source.push($(this).val());
    });
    $('#options_stars option:selected').each(function () {
      stars.push($(this).val());
    });
    $('#options_status option:selected').each(function () {
      status.push($(this).val());
    });
    text = $('#searchrecord').val().toString();
    console.log('text', text);
    console.log('source', source);
    console.log('stars', stars);
    console.log('groups', groups);

    let aux = []; this.results = [];
    for (let review of this.reviews) {
      let btext = true; let bsource = true; let bstars = true; let bstatus = true;  let bgroups = true;
      if (text !== '') {
        if (typeof review.text === 'undefined' || (review.text.indexOf(text) === -1 && review.name.indexOf(text) === -1))
          btext = false;
      }
      if (source.length > 0) {
        let b = false;
        for (let s of source) {
          if (review.business.toLowerCase().indexOf(s.toLowerCase()) !== -1)
            b = true;
        }
        bsource = b;
      }
      if (groups.length > 0) {
        let b = false;
        if (groups.findIndex((item) => item === String(review.business_group_id)) !== -1)
          b = true;
        bgroups = b;
      }
      if (stars.length > 0) {
        if (stars.indexOf(review.rating.length + ' Star') === -1) {
          bstars = false;
        }
      }
      if (status.length > 0) {
        if ((status.indexOf('Public') !== -1 && status.indexOf('Private') === -1 && !review.approved) || (status.indexOf('Public') === -1 && status.indexOf('Private') !== -1 && review.approved)) {
          bstatus = false;
        }
      }
      if (bstars && bsource && btext && bstatus && bgroups) {
        aux.push(review);
      } else {
        console.log(bstars+' '+bsource+' '+btext+' '+bstatus + ' ' + bgroups);
      }
    }
    this.ngZone.run( () => {
      console.log('aux', aux);
      this.results = aux;
      this.spinner = false;

      if (this.table !== null) {
        this.table.destroy();
      }

      if (this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      } else {
        this.dtTrigger.next();
      }
    });
  }

  getGroups() {
    this.busService.getGroups().subscribe(
      (response) => {
        this.groups = response['groups'];
      },
      (error) => { console.log('getGroups', error); }
    );
  }

}
