import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {User} from "../models/user";
import {Subject, Subscription} from "rxjs";
import {AuthenticationService} from "../_services/authentication.service";
import {UserService} from "../_services/user.service";
import {first} from "rxjs/operators";
import * as $ from 'jquery';
import {Router} from "@angular/router";
import * as moment from 'moment';
import {HttpClient} from "@angular/common/http";
import {environment} from "../models/enviroment";
import {GoogleChartInterface} from "ng2-google-charts/google-charts-interfaces";
import {DataTableDirective} from "angular-datatables";
import Swal from "sweetalert2";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions = {};
  dtTrigger = new Subject();
  reviews = [];
  results = [];

  //stats
  feedbacks = 0;
  positive = 0;
  negative = 0;
  rating = 0;

  //donut
  reforce = 0;
  google = 0;
  yelp = 0;
  facebook = 0;
  public pieChart: GoogleChartInterface = {
    chartType: 'PieChart',
    dataTable: [
      ['Feedbacks', 'Totals'],
      ['Reforce', 0],
      ['Google', 0],
      ['Yelp', 0],
      ['Facebook', 0],
    ],
    //opt_firstRowIsData: true,
    options: {
      title: 'Feedback distribution',
      pieHole: 0.5,
      slices: {
        0: { color: '#5A6875' },
        1: { color: '#00c292' },
        2: { color: '#964F51' },
        3: { color: '#00aced' },
      }
    },
  };

  //history
  public lineChart: GoogleChartInterface = {
    chartType: 'LineChart',
    dataTable: [
      ['Year', 'Reforce', 'Google', 'Yelp', 'Facebook'],
      ['2019', 0, 0, 0, 0 ],
    ],
    options: {
      title: 'Feedback Behavior',
      curveType: 'function',
      legend: { position: 'right' },
      colors: ['#5A6875', '#00c292', '#964F51', '#00aced'],
    }
  };

  //emails
  public emailChart: GoogleChartInterface = {
    chartType: 'AreaChart',
    dataTable: [
      ['Year', 'Emails'],
      ['2016', 0],
      ['2017', 0],
      ['2018', 0],
      ['2019', 0],
    ],
    options: {
      height: '150',
      title: 'Email Records',
      curveType: 'function',
      legend: { position: 'bottom' },
      colors: ['#5A6875'],
    }
  };

  //sms
  public smsChart: GoogleChartInterface = {
    chartType: 'AreaChart',
    dataTable: [
      ['Year', 'SMS'],
      ['2016', 0],
      ['2017', 0],
      ['2018', 0],
      ['2019', 0],
    ],
    options: {
      height: '150',
      title: 'SMS Records',
      curveType: 'function',
      legend: { position: 'bottom' },
      colors: ['#964F51'],
    }
  };

  //convert
  public convChart: GoogleChartInterface = {
    chartType: 'AreaChart',
    dataTable: [
      ['Year', 'Converted Rate'],
      ['2016', 0],
      ['2017', 0],
      ['2018', 0],
      ['2019', 0],
    ],
    options: {
      height: '150',
      title: 'Rate',
      curveType: 'function',
      legend: { position: 'bottom' },
      colors: ['#00c292'],
    }
  };


  currentUser: User;
  currentUserSubscription: Subscription;
  users: User[] = [];
  selected: any;

  selectedDonut: any;
  selectedHistory: any;
  selectedEmails: any;
  selectedSMS: any;
  selectedConvert: any;
  selectedRecent: any;
  selectedRatings: any;

  spinnerStats = false;
  spinnerDonut = false;
  spinnerHistory = false;
  spinnerEmails = false;
  spinnerSMS = false;
  spinnerConvert = false;
  spinnerRecent = false;
  spinnerRatings = false;
  spinnerSearch = false;

  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  overall_rating = 0;
  total_reviews = 0;

  recommended = 0;
  notrecommended = 0;

  public filters: { [key: string]: Object; }[] = [
    { Name: 'Reforce', Code: 'Reforce' },
    { Name: 'Google', Code: 'Google' },
    { Name: 'Yelp', Code: 'Yelp' },
    { Name: 'Facebook', Code: 'Facebook' },
  ];
  public localFields: Object = { text: 'Name', value: 'Code' };
  public localWaterMark: string = 'Filter by source...';

  public filters2: { [key: string]: Object; }[] = [
    { Name: '1 Star', Code: '1 Star' },
    { Name: '2 Star', Code: '2 Star' },
    { Name: '3 Star', Code: '3 Star' },
    { Name: '4 Star', Code: '4 Star' },
    { Name: '5 Star', Code: '5 Star' },
  ];
  public localFields2: Object = { text: 'Name', value: 'Code' };
  public localWaterMark2: string = 'Filter by rating...';

  breakout = {
    rating_1: 0,
    rating_2: 0,
    rating_3: 0,
    rating_4: 0,
    rating_5: 0,
  };
  breakout_sum = {
    reforce: 0,
    yelp: 0,
    google: 0,
    facebook: 0,
  };
  breakout_count = {
    reforce: 0,
    yelp: 0,
    google: 0,
    facebook: 0,
  };

  email_success = 0;
  email_failed = 0;
  sms_success = 0;
  sms_failed = 0;
  feed_success = 0;
  feed_failed = 0;
  feed_never = 0;

  agency_feedbacks = 0;
  businesses_feed = 0;
  agency_rating = 0;
  agency_pos_feed = 0;

  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') );
  }

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private ngZone: NgZone,
    private httpClient: HttpClient,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
      console.log('this.currentUser', this.currentUser);
    });
    this.alwaysShowCalendars = true;
    this.dtOptions = {
      // Declare the use of the extension in the dom parameter
      dom: 'LBlfrtip',
      // Configure the buttons
      buttons: [
        'copy',
        'print',
        'excel',
      ],
      responsive: true,
    };
  }

  ngOnInit() {
    console.log('dashboard in');
    //this.loadAllUsers();

    $(function () {
      $('.preloader').fadeOut();
    });
    this.loadStats();
    this.loadEmails();
    this.loadSMS();
    this.loadConvertion();
    if (this.currentUser['business']) {
      this.loadDonut();
      //this.loadLineChart();
      this.loadReviews();
      //this.loadRatings();
    } else {
      this.loadAgencyStats();
    }
  }

  loadStats() {
    this.ngZone.run( () => {
      this.spinnerStats = true;
    });
    console.log('load emp in');
    this.httpClient.get(environment.appUrl + '/api/dashboard/stats').pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && typeof data['feedbacks'] !== 'undefined') {
            this.feedbacks = parseInt(data['feedbacks']);
            this.positive = parseInt(data['positive']);
            this.negative = parseInt(data['negative']);
            this.rating = data['rating'];

            this.recommended = data['recom'];
            this.notrecommended = data['nrecom'];
          } else {
            this.agency_feedbacks = parseInt(data['agency']['feedbacks']);
            this.agency_pos_feed = parseInt(data['agency']['positive']);
            this.agency_rating = data['agency']['rating'];
            this.businesses_feed = parseInt(data['agency']['business_feed']);
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinnerStats = false;
          });
        }
      );
  }

  loadAgencyStats() {

  }

  loadDonut() {
    this.ngZone.run( () => {
      this.spinnerDonut = true;
    });
    console.log('load emp in');
    this.httpClient.get(environment.appUrl + '/api/dashboard/donut').pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['reforce']) {
            console.log(data['reforce'].length);
            if (typeof this.pieChart !== 'undefined') {
              this.pieChart.dataTable[1][1] = data['reforce'].length;
              this.pieChart.dataTable[2][1] = (typeof data['google'] !== 'undefined') ? data['google'].length : 0;
              this.pieChart.dataTable[4][1] = (typeof data['facebook'] !== 'undefined') ? data['facebook'].length : 0;
              this.pieChart.dataTable[3][1] = (data['yelp'] !== null && typeof data['yelp']['reviews'] !== 'undefined') ? data['yelp']['reviews'].length : 0;

              this.reforce = this.pieChart.dataTable[1][1];
              this.google = this.pieChart.dataTable[2][1];
              this.yelp = this.pieChart.dataTable[3][1];
              this.facebook = this.pieChart.dataTable[4][1];
            }
            if (typeof data['history'] !== 'undefined') {
              if (typeof this.lineChart !== 'undefined') {
                this.lineChart.dataTable = [['Year', 'Reforce', 'Google', 'Yelp', 'Facebook']];
                for (let y of data['indexes']) {
                  this.lineChart.dataTable.push([y.toString(), data['history'][y]['reforce'], data['history'][y]['google'], data['history'][y]['yelp'], data['history'][y]['facebook']]);
                }
                this.ngZone.run( () => {
                  this.spinnerDonut = false;
                });
                this.lineChart.component.draw();
              }
            }
            if (typeof this.pieChart !== 'undefined') {
              this.pieChart.component.draw();
              console.log(this.pieChart);
            }
          }
        },
        error => {
          console.log(error);
          this.ngZone.run( () => {
            this.spinnerDonut = false;
          });
        },
        () => {
          this.ngZone.run( () => {
            this.spinnerDonut = false;
          });
        }
      );
  }

  loadRatings() {
    this.ngZone.run( () => {
      this.spinnerRatings = true;
    });
    console.log('load emp in');
    this.httpClient.get(environment.appUrl + '/api/dashboard/ratings').pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['data']) {
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinnerRatings = false;
          });
        }
      );
  }

  filterReviews() {
    this.ngZone.run( () => {
      this.spinnerSearch = true;
    });
    let source = []; let stars = []; let text = '';
    $('#options_source option:selected').each(function () {
      source.push($(this).val());
    });
    $('#options_stars option:selected').each(function () {
      stars.push($(this).val());
    });
    text = $('#searchrecord').val().toString();
    console.log('text', text);
    console.log('source', source);
    console.log('stars', stars);

    let aux = []; this.results = [];
    for(let review of this.reviews) {
      let btext = true; let bsource = true; let bstars = true;
      if (text !== '') {
        if (review.text.indexOf(text) === -1 && review.name.indexOf(text) === -1)
          btext = false;
      }
      if (source.length > 0) {
        let b = false;
        for (let s of source) {
          if (review.icon_class.indexOf(s.toLowerCase()) !== -1 || (review.icon_class.indexOf('comment') !== -1 && s === 'Reforce'))
            b = true;
        }
        bsource = b;
      }
      if (stars.length > 0) {
        if (stars.indexOf(review.rating.length + ' Star') === -1) {
          bstars = false;
        }
      }
      if (bstars && bsource && btext) {
        aux.push(review);
      } else {
        console.log(bstars+' '+bsource+' '+btext);
      }
    }

    this.ngZone.run( () => {
      console.log('aux', aux);
      this.results = aux;
      this.spinnerSearch = false;
    });
  }

  loadReviews() {
    this.ngZone.run( () => {
      this.spinnerRecent = true;
    });
    this.httpClient.get(environment.appUrl + '/api/reviews/all').pipe(first())
      .subscribe(
        data => {
          console.log(data);
          let yelp = (data['yelp_reviews'][0] !== null && typeof data['yelp_reviews'][0] !== 'undefined') ? data['yelp_reviews'][0]['reviews'] : [];
          this.reviews = [];
          this.results = [];
          for (let ind in yelp) {
            let newrev = {
              rating: new Array(yelp[ind].rating),
              text: yelp[ind].text,
              created: yelp[ind].time_created,
              url: yelp[ind].url,
              image: yelp[ind].user.image_url,
              name: yelp[ind].user.name,
              profile: yelp[ind].user.profile_url,
              icon_class: 'fab fa-yelp',
            };
            this.overall_rating += parseInt(yelp[ind].rating);
            this.total_reviews ++;
            this.reviews.push(newrev);
            this.results.push(newrev);
            this.breakout['rating_' + yelp[ind].rating] ++;
            this.breakout_sum['yelp'] += yelp[ind].rating;
            this.breakout_count['yelp'] ++;
          }

          const reforce = data['reforce'];
          if (reforce.length > 0) {
            for (let ind in reforce) {
              let newrev = {
                rating: new Array(reforce[ind].rating_star),
                text: reforce[ind].rating_description,
                created: reforce[ind].created_at,
                url: '',
                image: '../../assets/images/generic-avatar.png',
                name: (reforce[ind].customer !== null && typeof reforce[ind].customer !== 'undefined') ? (reforce[ind].customer.first_name + ' ' + reforce[ind].customer.last_name) : '',
                profile: '',
                icon_class: 'far fa-comment',
              };
              this.overall_rating += parseInt(reforce[ind].rating_star);
              this.total_reviews ++;
              this.reviews.push(newrev);
              this.results.push(newrev);
              this.breakout['rating_' + reforce[ind].rating_star] ++;
              this.breakout_sum['reforce'] += reforce[ind].rating_star;
              this.breakout_count['reforce'] ++;
            }
          }

          let google_rev = data['allreviews'];
          if(typeof google_rev[0] !== 'undefined') {
            for (let ind in google_rev) {
              let newrev = {
                rating: new Array((google_rev[ind]['starRating'] === 'FIVE') ? 5 : (google_rev[ind]['starRating'] === 'FOUR' ? 4 : (google_rev[ind]['starRating'] === 'THREE' ? 3 : (google_rev[ind]['starRating'] === 'TWO' ? 2 : 1)))),
                text: google_rev[ind].comment,
                created: google_rev[ind]['createTime'],
                url: '',
                image: google_rev[ind]['reviewer']['profilePhotoUrl'],
                name: google_rev[ind]['reviewer']['displayName'],
                profile: '',
                icon_class: 'fab fa-google',
              };
              this.overall_rating += newrev.rating.length;
              this.total_reviews++;
              this.reviews.push(newrev);
              this.results.push(newrev);
              this.breakout['rating_' + newrev.rating.length]++;
              this.breakout_sum['google'] += newrev.rating.length;
              this.breakout_count['google']++;
            }
          }

          let facebook_rev = data['facebook'];
          if (typeof facebook_rev !== 'undefined' && typeof facebook_rev[0] !== 'undefined') {
            for (let ind in facebook_rev) {
              let newrev = {
                rating:  new Array((facebook_rev[ind]['recommendation_type'] === 'positive') ? 5 : 1),
                text: facebook_rev[ind]['review_text'],
                created: facebook_rev[ind]['created_time'],
                url: '',
                image: '../../assets/images/generic-avatar.png',
                name: facebook_rev[ind]['reviewer']['name'],
                profile: '',
                icon_class: 'fab fa-facebook',
              };
              this.overall_rating += newrev.rating.length;
              this.total_reviews++;
              this.reviews.push(newrev);
              this.results.push(newrev);
              this.breakout['rating_' + newrev.rating.length]++;
              this.breakout_sum['facebook'] += newrev.rating.length;
              this.breakout_count['facebook']++;
            }
          }

          this.overall_rating = (this.total_reviews > 0) ? this.overall_rating/this.total_reviews : 0;
          console.log(this.reviews);
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinnerRecent = false;
          });
        }
      );
  }

  loadLineChart() {
    this.ngZone.run( () => {
      this.spinnerHistory = true;
    });
    console.log('load emp in');
    this.httpClient.get(environment.appUrl + '/api/dashboard/linechart').pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['data']) {
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinnerHistory = false;
          });
        }
      );
  }

  loadEmails() {
    this.ngZone.run( () => {
      this.spinnerEmails = true;
    });
    console.log('load emp in');
    this.httpClient.get(environment.appUrl + '/api/records/emails/dashboard').pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['line']) {
            this.email_success = data['success'];
            this.email_failed = data['failed'];
            if (data['indexes'].length > 0) {
              if (typeof this.emailChart !== 'undefined') {
                this.emailChart.dataTable = [['Year', 'Emails']];
                for (let y of data['indexes']) {
                  this.emailChart.dataTable.push([y.toString(), data['line'][y].length]);
                }
                this.ngZone.run( () => {
                  this.spinnerEmails = false;
                });
                this.emailChart.component.draw();
              }
            }
          }
        },
        error => {
          console.log(error);
          this.ngZone.run( () => {
            this.spinnerEmails = false;
          });
        },
        () => {
          this.ngZone.run( () => {
            this.spinnerEmails = false;
          });
        }
      );
  }

  loadSMS() {
    this.ngZone.run( () => {
      this.spinnerSMS = true;
    });
    console.log('load emp in');
    this.httpClient.get(environment.appUrl + '/api/records/sms/dashboard').pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['line']) {
            this.sms_success = data['success'];
            this.sms_failed = data['failed'];
            if (data['indexes'].length > 0) {
              if (typeof this.smsChart !== 'undefined') {
                this.smsChart.dataTable = [['Year', 'SMS']];
                for (let y of data['indexes']) {
                  this.smsChart.dataTable.push([y.toString(), data['line'][y].length]);
                }
                this.ngZone.run( () => {
                  this.spinnerSMS = false;
                });
                this.smsChart.component.draw();
              }
            }
          }
        },
        error => {
          console.log(error);
          this.ngZone.run( () => {
            this.spinnerSMS = false;
          });
        },
        () => {
          this.ngZone.run( () => {
            this.spinnerSMS = false;
          });
        }
      );
  }

  loadConvertion() {
    this.ngZone.run( () => {
      this.spinnerConvert = true;
    });
    console.log('load emp in');
    this.httpClient.get(environment.appUrl + '/api/customers/dashboard').pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['line']) {
            this.feed_success = data['success'];
            this.feed_failed = data['failed'];
            this.feed_never = data['never'];
            if (data['indexes'].length > 0) {
              if (typeof this.convChart !== 'undefined') {
                this.convChart.dataTable = [['Year', 'Feedbacks']];
                for (let y of data['indexes']) {
                  this.convChart.dataTable.push([y.toString(), data['line'][y].length]);
                }
                this.ngZone.run( () => {
                  this.spinnerConvert = false;
                });
                this.convChart.component.draw();
              }
            }
          }
        },
        error => {
          console.log(error);
          this.ngZone.run( () => {
            this.spinnerConvert = false;
          });
        },
        () => {
          this.ngZone.run( () => {
            this.spinnerConvert = false;
          });
        }
      );
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
  }

  deleteUser(id: number) {
    this.userService.delete(id).pipe(first()).subscribe(() => {
      this.loadAllUsers();
    });
  }

  private loadAllUsers() {
    this.userService.getAll().pipe(first()).subscribe(users => {
      this.users = users;
    });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

}
