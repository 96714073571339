import {Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../_services/authentication.service";
import {AlertService} from "../_services/alert.service";
import * as $ from "jquery";
import {first} from "rxjs/operators";
import {UserService} from "../_services/user.service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup;
  loading =  false;
  submmited =  false;
  returnUrl: string;
  showLogin = true;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    console.log("login init");
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get f() { return this.resetForm.controls; }

  onSubmit() {
    this.submmited = true;

    if (this.resetForm.invalid) {
      return;
    }
    this.loading = true;
    console.log(this.resetForm.getRawValue());

    this.userService.resetPsw(this.resetForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Reset password link has been send!', false);
          this.resetForm.reset();
          this.loading = false;
        },
        error => {
          console.log(error);
          this.alertService.error('Incorrect Email!');
          this.loading = false;
        },
        () => {
          this.ngZone.run( () => {
            this.loading = false;
            this.submmited = false;
          });
        }
      );
  }

}
