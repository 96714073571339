import {Component, Input, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertService} from "../_services/alert.service";
import {HttpClient} from "@angular/common/http";
import {CustomersComponent} from "../customers/customers.component";
import {UploadAdapter} from "../models/uploadAdapter";
import {environment} from "../models/enviroment";
import {first} from "rxjs/operators";
import {BusinessesService} from "../_services/businesses.service";
import * as $ from "jquery";
import {EmailTemplatesService} from "../_services/email-templates.service";

@Component({
  selector: 'app-positive-review-alert',
  templateUrl: './positive-review-alert.component.html',
  styleUrls: ['./positive-review-alert.component.css']
})
export class PositiveReviewAlertComponent implements OnInit {

  private _parent: string;
  @Input() set parent(value: string ) {
    this._parent = value;
  }

  loading = false;
  submmited = false;
  alertForm: FormGroup;
  ckeConfig: any;
  spinner = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private busService: BusinessesService,
    private ngZone: NgZone,
    private tmplService: EmailTemplatesService,
  ) { }

  public onReady( editor ) {
    console.log('TheUploadAdapterPlugin called');

  }

  ngOnInit() {
    this.ckeConfig = {
      extraPlugins: 'justify',
      filebrowserImageBrowseUrl: environment.appUrl + '/ckfinder/browser?command=QuickUpload&type=Images',
      filebrowserImageUploadUrl: environment.appUrl + '/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images'
    };
    this.alertForm = this.formBuilder.group({
      business_id: ['', []],
      type: [this.parent, []],
      subject: ['', Validators.required],
      content: ['', []],
      sendername: ['', Validators.required],
      senderemail: ['', [Validators.required, Validators.email]],
      recipientemail: ['', [Validators.required, Validators.email]],
      ccemail: ['', [Validators.email]],
    });
    console.log(this.parent);
    this.loadAlert();
  }

  onChange($event: any): void {
    console.log("onChange");

  }

  get f() { return this.alertForm.controls; }

  get self(): PositiveReviewAlertComponent {
    return this;
  }

  get parent(): string {
    return this._parent;
  }

  loadAlert() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.busService.loadAlert(this.parent).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['alert']) {
            if (data['business'] !== null) {
              this.alertForm.controls.business_id.setValue(data['business']['id']);
            }
            if (data['alert'] !== null) {
              this.alertForm.controls.type.setValue(this.parent);
              this.alertForm.controls.subject.setValue(data['alert']['subject']);
              this.alertForm.controls.sendername.setValue(data['alert']['sender_name']);
              this.alertForm.controls.senderemail.setValue(data['alert']['sender_email']);
              this.alertForm.controls.recipientemail.setValue(data['alert']['recipient_email']);
              this.alertForm.controls.ccemail.setValue(data['alert']['cc_email']);
              this.alertForm.controls.content.setValue(data['alert']['content']);
            }
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  openMergeFieldsModal() {
    $('.merge-fields-modal').modal('show');
  }

  openPreviewModal() {
    let html = this.alertForm.controls.content.value;
    this.ngZone.run( () => {
      this.spinner = true;
    });
    const uploadData = new FormData();
    uploadData.append('html', html);
    this.tmplService.replaceHtml(uploadData).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && typeof data['html'] !== 'undefined') {
            const previewOpen = window.open("", "previewOpen", "width=700, height=500");
            previewOpen.document.body.innerHTML = data['html'];
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  onSubmit() {
    this.submmited = true;
    if (this.alertForm.invalid) {
      return;
    }
    this.loading = true;
    console.log(this.alertForm.getRawValue());
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.busService.storeAlert(this.alertForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Registration Successful', false);
          this.loading = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error['error']);
          this.loading = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );

  }

}
