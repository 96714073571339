import {Component, Input, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import * as $ from "jquery";
import {AgencyService} from "../_services/agency.service";
import {first} from "rxjs/operators";
import {AgenciesComponent} from "../agencies/agencies.component";

@Component({
  selector: 'app-new-agency',
  templateUrl: './new-agency.component.html',
  styleUrls: ['./new-agency.component.css'],
})
export class NewAgencyComponent implements OnInit {

  private _parent: AgenciesComponent ;
  @Input() set parent(value: AgenciesComponent ) {
    this._parent = value;
  }

  loading = false;
  submmited = false;
  agencyForm: FormGroup;
  formData = {};
  public spinner = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private agencyService: AgencyService,
    private agenciesComp: AgenciesComponent,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.agencyForm = this.formBuilder.group({
      agency_id: ['', []],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      agencyname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confpassword: ['', Validators.required],
      status: ['', []],
      allow_sub_agencies: ['', []],
    }, {
      validator: this.MustMatch('password', 'confpassword')
    });
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  get parent(): AgenciesComponent {
    return this._parent;
  }

  loadAgency(id: number) {
    if (id >= 0 && id.toString() !== '') {
      this.ngZone.run( () => {
        this.spinner = true;
      });
      console.log('load user in', id);
      this.agencyService.show(id).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data && data['agency']) {
              this.agencyForm.controls.agency_id.setValue(id);
              this.agencyForm.controls.firstname.setValue(data['agency']['first_name']);
              this.agencyForm.controls.lastname.setValue(data['agency']['last_name']);
              this.agencyForm.controls.agencyname.setValue(data['agency']['agency_name']);
              this.agencyForm.controls.email.setValue(data['agency']['email']);
              this.agencyForm.controls.password.setValue('123456789');
              this.agencyForm.controls.confpassword.setValue('123456789');
              this.agencyForm.controls.status.setValue(data['agency']['status']);
              this.agencyForm.controls.allow_sub_agencies.setValue(data['agency']['allow_sub_agencies']);
            }
          },
          error => {
            console.log(error);
          },
          () => {
            this.ngZone.run( () => {
              this.spinner = false;
            });
          }
        );
    }
  }

  get f() { return this.agencyForm.controls; }

  onSubmit() {
    this.alertService.clear();
    this.submmited = true;
    if (this.agencyForm.invalid) {
      return;
    }
    this.loading = true;
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.agencyService.register(this.agencyForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Registration Successful', false);
          this.parent.loadAgencies();
          this.closeModal();
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.submmited = false;
          console.log('error: ', error);
          this.ngZone.run( () => {
            this.spinner = false;
          });
          let message = 'We can\'t edit your profile. Please check all required fields and the email shouldn\'t be in use.';
          if (error.indexOf('users_email_unique') !== -1) {
            message = 'We can\'t edit your profile. The email already exists. Please use a different email address.';
          }
          console.log(message);
          this.alertService.error(message);

        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  openModal() {
    this.alertService.clear();
    $('.new-agency-modal').modal('show');
  }

  closeModal() {
    this.alertService.clear();
    $('.new-agency-modal').modal('hide');
    this.submmited = false;
    this.agencyForm.reset();
  }

}
