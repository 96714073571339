import {Component, Input, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {AgencyService} from "../_services/agency.service";
import {UserService} from "../_services/user.service";
import {first} from "rxjs/operators";
import * as $ from "jquery";
import {UsersComponent} from "../users/users.component";
import {DashboardComponent} from "../dashboard/dashboard.component";
import {AppComponent} from "../app.component";

@Component({
  selector: 'app-user-profile-modal',
  templateUrl: './user-profile-modal.component.html',
  styleUrls: ['./user-profile-modal.component.css']
})
export class UserProfileModalComponent implements OnInit {

  private _parent: AppComponent ;
  @Input() set parent(value: AppComponent ) {
    this._parent = value;
  }

  private _userid: number ;
  @Input() set userid(value: number ) {
    this._userid = value;
  }

  public spinner:boolean = false;
  loading = false;
  submmited = false;
  addForm: FormGroup;

  public mode: string;
  public height: string = '200px';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private agencyService: AgencyService,
    private userService: UserService,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.mode = 'CheckBox';
    this.addForm = this.formBuilder.group({
      user_id: ['', []],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      passwordconf: ['', Validators.required],
    }, {
      validator: this.MustMatch('password', 'passwordconf')
    });
  }

  loadUser(id: number) {
    if (id >= 0 && id.toString() !== '') {
      this.ngZone.run( () => {
        this.spinner = true;
      });
      console.log('load user in', id);
      this.userService.getUser(id).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data && data['user']) {
              this.addForm.controls.firstname.setValue(data['user']['firstname']);
              this.addForm.controls.lastname.setValue(data['user']['lastname']);
              this.addForm.controls.email.setValue(data['user']['email']);
              this.addForm.controls.password.setValue('');
              this.addForm.controls.passwordconf.setValue('');
              console.log("spinner false", this);
            }
          },
          error => {
            console.log('We can\'t edit your profile. Please check all required fields and the email shouldn\'t be in use.');
            this.alertService.error('We can\'t edit your profile. Please check all required fields and the email shouldn\'t be in use.');
            this.submmited = false;
          },
          () => {
            this.ngZone.run( () => {
              this.spinner = false;
            });
          }
        );
    }
  }

  get f() { return this.addForm.controls; }

  onSubmit() {
    this.submmited = true;

    if (this.addForm.invalid) {
      return;
    }
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.loading = true;
    console.log(this.addForm.getRawValue());

    this.userService.updateProfile(this.addForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Update profile Successful', false);
          this.closeModal();
          this.loading = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  openModal() {
    $('.edit-user-modal').modal('show');
  }

  closeModal() {
    $('.edit-user-modal').modal('hide');
    this.submmited = false;
    this.addForm.reset();
  }

  get parent(): AppComponent {
    return this._parent;
  }

  get userid(): number {
    return this._userid;
  }

}
