import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import {first} from "rxjs/operators";
import {UserService} from "../_services/user.service";
import {Customer} from "../models/customer";
import {User} from "../models/user";
import {NewUserComponent} from "../new-user/new-user.component";
import Swal from "sweetalert2";
import {environment} from "../models/enviroment";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, OnDestroy {

  @ViewChild(NewUserComponent, null) newUserModal: NewUserComponent;

  users = [];
  table = null;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions = {};
  dtTrigger = new Subject();

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    this.loadUsers();
    this.loadWindowFunctions();
    this.dtOptions = {
      // Declare the use of the extension in the dom parameter
      dom: 'LBlfrtip',
      // Configure the buttons
      buttons: [
        'copy',
        'print',
        'excel',
      ],
      responsive: true,
    };
  }

  resendCredentials(id: string) {
    console.log('business_id', id);
    Swal.queue([{
      title: 'Are you sure?',
      text: 'You are about to send a reset password link to the current user!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, send it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/users/reset-password', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({selected: id}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            Swal.insertQueueStep({
              type: 'success',
              title: 'The reset link has been send it.'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to send reset link.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0]) {

      }
    });
  }

  deleteAll() {
    let todelete = [];
    $('.user_check:checkbox:checked').each(function() {
      todelete.push(parseInt(this.id.split('_')[1]));
    });
    console.log(todelete);
    Swal.queue([{
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/users/delete-sel', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({selected: todelete}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your users has been deleted.'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to delete your users.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0])
        this.loadUsers();
    });
  }

  checkAll() {
    console.log('in');
    if ($('#checkallusers')[0]['checked']) {
      console.log('in true');
      $('.user_check').each(function(){
        $(this).attr('checked', 'true');
      });
    } else {
      console.log('false');
      $('.user_check').each(function(){
        $(this).removeAttr('checked');
      });
    }
  }

  deleteUser(id: string) {
    console.log('delete in', id);

    Swal.queue([{
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/users/delete/' + id, {
          method: 'get',
          headers: new Headers({
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
        }).then(data => {
          console.log(data);
          console.log(JSON.parse(localStorage.currentUser).token);
          Swal.insertQueueStep({
            type: 'success',
            title: 'Your user has been deleted.'
          });
        })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to delete your user.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0])
        this.loadUsers();
    });
  }

  editUser(id: number) {
    console.log('edit in', id);
    this.newUserModal.addForm.controls.user_id.setValue(id);
    this.newUserModal.loadAccesses(id);
    //this.newUserModal.loadUser(id);
    $('.add-user-modal').modal('show');
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  loadWindowFunctions() {
    window['deleteUser'] = (id: string) => {
      console.log('delete in', id);

      Swal.queue([{
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(environment.appUrl + '/api/users/delete/' + id, {
            method: 'get',
            headers: new Headers({
              Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
            }),
          }).then(data => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your user has been deleted.'
            });
          })
            .catch((error) => {
              console.log(error);
              Swal.insertQueueStep({
                type: 'error',
                title: 'Unable to delete your user.'
              });
            });
        }
      }]).then((result) => {
        if (result.value[0])
          this.loadUsers();
      });

    };
    window['editUser'] = (id: number) => {
      console.log('edit in', id);
      this.newUserModal.addForm.controls.user_id.setValue(id);
      this.newUserModal.loadAccesses(id);
      //this.newUserModal.loadUser(id);
      $('.add-user-modal').modal('show');
    };
  }

  openNewModal() {
    $('.add-user-modal').modal('show');
  }

  get self(): UsersComponent {
    return this;
  }

  loadUsers() {
    $('.preloader').show();
    this.userService.getAllAgency().pipe(first())
      .subscribe(
        data => {
          if (data && data['data'])
            this.users = data['data'];
            console.log('success', this.users);

          if (this.table !== null) {
            this.table.destroy();
          }

          if (this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              // Destroy the table first
              dtInstance.destroy();
              // Call the dtTrigger to rerender again
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
          console.log('dtOptions', this.dtOptions);
          $('.preloader').hide();
        },
        error => {
          console.log('error', error);
          $('.preloader').hide();
        }
      );
  }

}
