import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {first} from "rxjs/operators";
import {CustomersService} from "../_services/customers.service";

@Component({
  selector: 'app-customer-review-page',
  templateUrl: './customer-review-page.component.html',
  styleUrls: ['./customer-review-page.component.css']
})
export class CustomerReviewPageComponent implements OnInit {

  token = '';
  public spinner = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private ngZone: NgZone,
    private custService: CustomersService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.token = params['token'];
      console.log(this.token);
    });
  }

  sendReview(option: string) {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    console.log('option', option);
    console.log('token', this.token);
    this.custService.sendReview(option, this.token).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['customer_token']) {
            this.router.navigate([`/businesses/${option}/${data['customer_token']}`]);
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

}
