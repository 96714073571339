import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {ReviewServiceService} from "../_services/review-service.service";
import {FormBuilder} from "@angular/forms";
import {environment} from "../models/enviroment";
import {first} from "rxjs/operators";
import * as $ from "jquery";
import {GoogleChartInterface} from "ng2-google-charts/google-charts-interfaces";
import {BusinessesService} from "../_services/businesses.service";

@Component({
  selector: 'app-agency-online-reviews',
  templateUrl: './agency-online-reviews.component.html',
  styleUrls: ['./agency-online-reviews.component.css']
})
export class AgencyOnlineReviewsComponent implements OnInit, OnDestroy {

  spinner = false;
  reviews = [];
  results = [];
  table = null;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions = {};
  dtTrigger = new Subject();

  public filters: { [key: string]: Object; }[] = [
    { Name: 'Google', Code: 'Google' },
    { Name: 'Yelp', Code: 'Yelp' },
    { Name: 'Facebook', Code: 'Facebook' },
  ];

  public localFields: Object = { text: 'Name', value: 'Code' };
  public localWaterMark: string = 'Filter by source...';

  public filters2: { [key: string]: Object; }[] = [
    { Name: '1 Star', Code: '1 Star' },
    { Name: '2 Star', Code: '2 Star' },
    { Name: '3 Star', Code: '3 Star' },
    { Name: '4 Star', Code: '4 Star' },
    { Name: '5 Star', Code: '5 Star' },
  ];
  public localFields2: Object = { text: 'Name', value: 'Code' };
  public localWaterMark2: string = 'Filter by rating...';

  public filters3: { [key: string]: Object; }[] = [
    { Name: 'Public', Code: 'Public' },
    { Name: 'Private', Code: 'Private' },
  ];
  public localFields3: Object = { text: 'Name', value: 'Code' };
  public localWaterMark3: string = 'Filter by status...';

  public filtersB: { [key: string]: Object; }[] = [];
  public localFieldsB: Object = { text: 'Name', value: 'Code' };
  public localWaterMarkB: string = 'Select the business to list online reviews ...';

  breakout = {
    rating_1: 0,
    rating_2: 0,
    rating_3: 0,
    rating_4: 0,
    rating_5: 0,
  };
  breakout_sum = {
    reforce: 0,
    yelp: 0,
    google: 0,
    facebook: 0,
  };
  breakout_count = {
    reforce: 0,
    yelp: 0,
    google: 0,
    facebook: 0,
  };
  overall_rating = 0;
  total_reviews = 0;
  submmited = false;

  google = 0;
  yelp = 0;
  facebook = 0;
  public pieChart: GoogleChartInterface = {
    chartType: 'PieChart',
    dataTable: [
      ['Feedbacks', 'Totals'],
      ['Google', 0],
      ['Yelp', 0],
      ['Facebook', 0],
    ],
    options: {
      title: 'Review distribution',
      pieHole: 0.5,
      slices: {
        0: { color: '#00c292' },
        1: { color: '#964F51' },
        2: { color: '#00aced' },
      }
    },
  };

  public barChart: GoogleChartInterface = {
    chartType: 'ColumnChart',
    dataTable: [
      ['Stars', 'Total'],
      ['5 Stars',  80],
      ['4 Stars',  4],
      ['3 Stars',  3],
      ['2 Stars',  2],
      ['1 Stars',  1],
    ],
    options: {
      tooltip: { isHtml: true },    // CSS styling affects only HTML tooltips.
      legend: { position: 'none' },
      bar: { groupWidth: '90%' },
      colors: ['#5A6875'],
      title: 'Rating Distribution',
      height: 365,
    },
  };
  groups: any;
  businessesSelcted = [];
  allBusinesses = [];

  constructor(
    private ngZone: NgZone,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private reviewServ: ReviewServiceService,
    private formBuilder: FormBuilder,
    private busService: BusinessesService
  ) { }

  ngOnInit() {
    this.getGroups();
    this.loadBusinesses();
    this.dtOptions = {
      // Declare the use of the extension in the dom parameter
      dom: 'LBlfrtip',
      // Configure the buttons
      buttons: [
        'copy',
        'print',
        {
          extend: 'excelHtml5',
          title: 'email-templates-excel',
        },
      ],
      responsive: true,
    };
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  updateBusinessesFilter(event) {
    this.businessesSelcted = [];
    this.filtersB = (event.value.length > 0) ?
      this.allBusinesses.filter((item) => event.value.findIndex((group) => String(group) === String(item.business_group_id)) !== -1)
      : this.allBusinesses;
  }

  loadBusinesses() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.httpClient.get(environment.appUrl + '/api/agencies/businesses').pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['data'][0] && data['data'][0]['businesses']) {
            this.ngZone.run( () => {
              for (let bus of data['data'][0]['businesses']) {
                this.filtersB.push({Name: bus['business_name'], Code: bus['id'], business_group_id: bus['business_group_id']});
                this.allBusinesses.push({Name: bus['business_name'], Code: bus['id'], business_group_id: bus['business_group_id']});
              }
            });
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  loadReviews() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    let ids = [];
    $('#options_business option:selected').each(function () {
      ids.push($(this).val());
    });
    if(ids.length > 0) {
      const id = ids[0];
      this.httpClient.get(environment.appUrl + '/api/reviews/online/' + id).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            let yelp = (data['yelp_reviews'][0] !== null) ? data['yelp_reviews'][0]['reviews'] : [];
            this.reviews = [];
            this.results = [];
            for (let ind in yelp) {
              let newrev = {
                id: 'yelp|' + yelp[ind]['id'],
                rating: new Array(yelp[ind].rating),
                text: yelp[ind].text,
                created: yelp[ind].time_created,
                url: yelp[ind].url,
                image: yelp[ind].user.image_url,
                name: yelp[ind].user.name,
                profile: yelp[ind].user.profile_url,
                icon_class: 'fab fa-yelp',
                approved: yelp[ind].approved,
                answer: yelp[ind].url,
              };
              this.overall_rating += parseInt(yelp[ind].rating);
              this.total_reviews ++;
              this.reviews.push(newrev);
              this.results.push(newrev);
              this.breakout['rating_' + yelp[ind].rating] ++;
              this.breakout_sum['yelp'] += yelp[ind].rating;
              this.breakout_count['yelp']++;
            }

            let google_rev = data['allreviews'];
            if (typeof google_rev[0] !== 'undefined') {
              for (let ind in google_rev) {
                let newrev = {
                  id: 'google|' + google_rev[ind]['reviewId'],
                  rating: new Array((google_rev[ind]['starRating'] === 'FIVE') ? 5 : (google_rev[ind]['starRating'] === 'FOUR' ? 4 : (google_rev[ind]['starRating'] === 'THREE' ? 3 : (google_rev[ind]['starRating'] === 'TWO' ? 2 : 1)))),
                  text: google_rev[ind].comment,
                  created: google_rev[ind]['createTime'],
                  url: '',
                  image: google_rev[ind]['reviewer']['profilePhotoUrl'],
                  name: google_rev[ind]['reviewer']['displayName'],
                  profile: '',
                  icon_class: 'fab fa-google',
                  approved: google_rev[ind].approved,
                  answer: google_rev[ind].name,
                  reply: (typeof google_rev[ind].reviewReply !== 'undefined' && typeof google_rev[ind].reviewReply.comment !== 'undefined') ? google_rev[ind].reviewReply.comment : '',
                  replyUpd: (typeof google_rev[ind].reviewReply !== 'undefined' && typeof google_rev[ind].reviewReply.comment !== 'undefined') ? google_rev[ind].reviewReply.updateTime : '',
                };
                this.overall_rating += newrev.rating.length;
                this.total_reviews++;
                this.reviews.push(newrev);
                this.results.push(newrev);
                this.breakout['rating_' + newrev.rating.length]++;
                this.breakout_sum['google'] += newrev.rating.length;
                this.breakout_count['google']++;
              }
            }

            let facebook_rev = data['facebook'];
            if (typeof facebook_rev !== 'undefined' && typeof facebook_rev[0] !== 'undefined') {
              for (let ind in facebook_rev) {
                let newrev = {
                  id: 'facebook|' + facebook_rev[ind]['reviewer']['name'] + '|' + facebook_rev[ind]['created_time'],
                  rating: new Array((facebook_rev[ind]['recommendation_type'] === 'positive') ? 5 : 1),
                  text: facebook_rev[ind]['review_text'],
                  created: facebook_rev[ind]['created_time'],
                  url: '',
                  image: '../../assets/images/generic-avatar.png',
                  name: facebook_rev[ind]['reviewer']['name'],
                  profile: '',
                  icon_class: 'fab fa-facebook',
                  approved: facebook_rev[ind].approved,
                  answer: facebook_rev[ind]['open_graph_story']['id'],
                };
                this.overall_rating += newrev.rating.length;
                this.total_reviews++;
                this.reviews.push(newrev);
                this.results.push(newrev);
                this.breakout['rating_' + newrev.rating.length]++;
                this.breakout_sum['facebook'] += newrev.rating.length;
                this.breakout_count['facebook']++;
              }
            }

            this.overall_rating = (this.total_reviews > 0) ? this.overall_rating / this.total_reviews : 0;
            console.log(this.reviews);

            if (this.table !== null) {
              this.table.destroy();
            }

            if (this.dtElement.dtInstance) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.dtTrigger.next();
              });
            } else {
              this.dtTrigger.next();
            }
            console.log('dtOptions', this.dtOptions);
            this.ngZone.run(() => {
              this.submmited = true;
            });
          },
          error => {
            console.log(error);
          },
          () => {
            this.ngZone.run(() => {
              this.spinner = false;
            });
            this.pieChart.dataTable = [
              ['Reviews', 'Totals'],
              ['Google', this.breakout_count.google],
              ['Yelp', this.breakout_count.yelp],
              ['Facebook', this.breakout_count.facebook],
            ];
            this.facebook = this.breakout_count.facebook;
            this.google = this.breakout_count.google;
            this.yelp = this.breakout_count.yelp;

            this.barChart.dataTable = [
              ['Stars', 'Total'],
              ['5 Stars',  this.breakout.rating_5],
              ['4 Stars',  this.breakout.rating_4],
              ['3 Stars',  this.breakout.rating_3],
              ['2 Stars',  this.breakout.rating_2],
              ['1 Stars',  this.breakout.rating_1],
            ];
          }
        );
    } else {
      this.reviews = [];
      this.results = [];
      if (this.table !== null) {
        this.table.destroy();
      }

      if (this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      } else {
        this.dtTrigger.next();
      }
      this.ngZone.run(() => {
        this.spinner = false;
        this.submmited = false;
      });
    }
  }

  filterReviews() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    let source = []; let stars = []; let text = ''; let status = [];
    $('#options_source option:selected').each(function () {
      source.push($(this).val());
    });
    $('#options_stars option:selected').each(function () {
      stars.push($(this).val());
    });
    $('#options_status option:selected').each(function () {
      status.push($(this).val());
    });
    text = $('#searchrecord').val().toString();
    console.log('text', text);
    console.log('source', source);
    console.log('stars', stars);

    let aux = []; this.results = [];
    for (let review of this.reviews) {
      let btext = true; let bsource = true; let bstars = true; let bstatus = true;
      if (text !== '') {
        if (typeof review.text === 'undefined' || (review.text.indexOf(text) === -1 && review.name.indexOf(text) === -1))
          btext = false;
      }
      if (source.length > 0) {
        let b = false;
        for (let s of source) {
          if (review.icon_class.indexOf(s.toLowerCase()) !== -1 || (review.icon_class.indexOf('comment') !== -1 && s === 'Reforce'))
            b = true;
        }
        bsource = b;
      }
      if (stars.length > 0) {
        if (stars.indexOf(review.rating.length + ' Star') === -1) {
          bstars = false;
        }
      }
      if (status.length > 0) {
        if ((status.indexOf('Public') !== -1 && status.indexOf('Private') === -1 && !review.approved) || (status.indexOf('Public') === -1 && status.indexOf('Private') !== -1 && review.approved)) {
          bstatus = false;
        }
      }
      if (bstars && bsource && btext && bstatus) {
        aux.push(review);
      } else {
        console.log(bstars+' '+bsource+' '+btext+' '+bstatus);
      }
    }
    this.ngZone.run( () => {
      console.log('aux', aux);
      this.results = aux;
      this.spinner = false;

      if (this.table !== null) {
        this.table.destroy();
      }

      if (this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      } else {
        this.dtTrigger.next();
      }
    });
  }

  getGroups() {
    this.busService.getGroups().subscribe(
      (response) => {
        this.groups = response['groups'];
      },
      (error) => { console.log('getGroups', error); }
    );
  }

}
