import {Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry} from "ngx-file-drop";
import {Country} from "../models/country";
import {Category} from "../models/category";
import {State} from "../models/state";
import {City} from "../models/city";
import {first} from "rxjs/operators";
import * as $ from "jquery";
import {CategoriesService} from "../_services/categories.service";
import {CountriesService} from "../_services/countries.service";
import {StatesService} from "../_services/states.service";
import {CitiesService} from "../_services/cities.service";
import {BusinessesService} from "../_services/businesses.service";
import {environment} from "../models/enviroment";

@Component({
  selector: 'app-business-form',
  templateUrl: './business-form.component.html',
  styleUrls: ['./business-form.component.css']
})
export class BusinessFormComponent implements OnInit {

  public spinner = false;

  loading = false;
  submmited = false;
  businessForm: FormGroup;
  file = null;
  filesubmmit = false;
  fileprecent = 0;

  countries: Country[] = [];
  categories: Category[] = [];
  states: State[] = [];
  cities: City[] = [];

  public imagePath;
  imgURL: any;
  public message: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private catService: CategoriesService,
    private countService: CountriesService,
    private stateService: StatesService,
    private cityervice: CitiesService,
    private busService: BusinessesService,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    console.log('business info in');
    this.businessForm = this.formBuilder.group({
      business_id: ['', Validators.required],
      businessname: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      category: ['', Validators.required],
      streetaddress: ['', Validators.required],
      streetaddress2: ['', []],
      country: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      phone: ['', Validators.required],
      webaddress: ['', []],
      email: ['', [Validators.required, Validators.email]],
      intfeedback: ['', []],
      extrevurl: ['', []],
      logo: ['', []],
      logohiden: ['', []],
    });

    this.loadCategories();
    //this.loadCountries();
    this.loadForm();
  }

  loadCoords() {
    const fulladdress = this.businessForm.controls.streetaddress.value + ' ' + this.businessForm.controls.streetaddress2.value;
    this.busService.getCoords(fulladdress).pipe(first())
      .subscribe(
        data => {
          console.log(data);

        },
        error => {
          console.log(error);
        }
      );
  }

  loadForm() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.busService.getCurrent().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['business']) {
            let business = data['business'];
            this.businessForm.controls.business_id.setValue(business.id);
            this.businessForm.controls.businessname.setValue(business.business_name);
            this.businessForm.controls.firstname.setValue(business.first_name);
            this.businessForm.controls.lastname.setValue(business.last_name);
            this.businessForm.controls.streetaddress.setValue(business.address_line1);
            this.businessForm.controls.streetaddress2.setValue(business.address_line2);
            this.businessForm.controls.zip.setValue(business.zip);
            this.businessForm.controls.phone.setValue(business.phone);
            this.businessForm.controls.webaddress.setValue(business.company_web_address);
            this.businessForm.controls.email.setValue(business.email);
            this.businessForm.controls.extrevurl.setValue(business.external_review_url);
            this.businessForm.controls.country.setValue(business.country);
            //this.loadStates();
            this.businessForm.controls.state.setValue(business.state);
            //this.loadCities();
            this.businessForm.controls.city.setValue(business.city);
            this.businessForm.controls.zip.setValue(business.zip);
            this.businessForm.controls.intfeedback.setValue(business.choice_for_online_review);
            this.loadCategories();
            this.businessForm.controls.category.setValue(business.business_category_id);
            this.businessForm.controls.logohiden.setValue(business.business_logo);
            this.imgURL = business.business_logo;
            //this.loadCoords();
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  onFileChanged(event) {
    this.file = event.target.files[0];
    console.log(this.file);

    if (this.file.type.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    } else {
      this.message = null;
    }

    let reader = new FileReader();
    this.imagePath = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
    this.filesubmmit = true;
    const uploadData = new FormData();
    uploadData.append('myFile', this.file, this.file.name);
    this.httpClient.post(environment.appUrl + '/api/businesses/logo-upload', uploadData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(event => {
      this.fileprecent += 25;
      $('.logoupload').html('<div class="progress-bar bg-info logoupload" style="width: '+ this.fileprecent +'%; height:10px;" role="progressbar">'+ this.fileprecent +'%</div>');
      console.log(event); // handle event here
      if (event['body'] && event['body'].logo) {
        this.businessForm.controls.logohiden.setValue(event['body'].logo);
        this.filesubmmit = false;
        this.fileprecent = 0;
      }
    });
  }

  loadCategories() {
    this.catService.getAll().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['data'])
            this.categories = data['data'];
        },
        error => {
          console.log(error);
        }
      );
  }

  loadCountries() {
    this.countService.getAll().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['data'])
            this.countries = data['data'];
        },
        error => {
          console.log(error);
        }
      );
  }

  loadStates() {
    if (this.businessForm.controls.country.value !== '') {
      this.stateService.getAll(this.businessForm.controls.country.value).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data && data['data'])
              this.states = data['data'];
          },
          error => {
            console.log(error);
          }
        );
    }
  }

  loadCities() {
    if (this.businessForm.controls.state.value !== '') {
      this.cityervice.getAll(this.businessForm.controls.state.value).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data && data['data'])
              this.cities = data['data'];
          },
          error => {
            console.log(error);
          }
        );
    }
  }

  loadZipCode() {
    if (typeof $(".cities option:selected").attr('datazip') !== 'undefined') {
      console.log($(".cities option:selected").attr('datazip'));
      this.businessForm.controls.zip.setValue($(".cities option:selected").attr('datazip'));
    } else {
      this.businessForm.controls.zip.setValue('');
    }
  }

  onSubmit() {
    this.submmited = true;
    if (this.businessForm.invalid) {
      return;
    }
    this.loading = true;
    console.log(this.businessForm.getRawValue());
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.busService.update(this.businessForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (typeof data['business'] !== 'undefined') {
            let aux = JSON.parse(localStorage.currentUser);
            aux.business = JSON.stringify(data['business']);
            localStorage.currentUser = JSON.stringify(aux);
          }
          this.alertService.success('Update Successful', false);
          this.loading = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );

  }

  get f() { return this.businessForm.controls; }



}

/*
* extposrevpage: ['', Validators.required],
      extnegrevpage: ['', Validators.required],
* */
