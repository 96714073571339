import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as $ from "jquery";
import {first} from "rxjs/operators";
import {CustomersService} from "../_services/customers.service";
import {Customer} from "../models/customer";
import {NewAgencyComponent} from "../new-agency/new-agency.component";
import {NewCustomerComponent} from "../new-customer/new-customer.component";
import Swal from "sweetalert2";
import {environment} from "../models/enviroment";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {collect} from "collect.js";
import {AlertService} from "../_services/alert.service";
import {FormBuilder} from "@angular/forms";
import * as moment from 'moment';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnDestroy, OnInit {

  @ViewChild(NewCustomerComponent, null) newCustomerModal: NewCustomerComponent;

  public customers: Customer[] = [];
  public auxCustomers: Customer[] = [];
  table = null;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions = {};
  dtTrigger = new Subject();
  spinner = false;
  daterange: any = null;

  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  public filters: { [key: string]: Object; }[] = [
    { Name: '1 Star', Code: '1 Star' },
    { Name: '2 Star', Code: '2 Star' },
    { Name: '3 Star', Code: '3 Star' },
    { Name: '4 Star', Code: '4 Star' },
    { Name: '5 Star', Code: '5 Star' },
    { Name: 'No feedback left', Code: 'No feedback left' },
    { Name: 'Not in feedback sequence', Code: 'Not in feedback sequence' },
    { Name: 'In feedback sequence', Code: 'in feedback sequence' },
    { Name: 'Opt-Out', Code: 'Opt-Out' },
  ];

  public localFields: Object = { text: 'Name', value: 'Code' };
  public localWaterMark: string = 'Filter by status ...';

  constructor(
    private custService: CustomersService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.loadCustomers();
    this.loadWindowFunctions();
    this.dtOptions = {
      lengthChange: true,
      // Declare the use of the extension in the dom parameter
      dom: 'LBlfrtip',
      // Configure the buttons
      buttons: [
        'copy',
        'print',
        'excel',
      ],
      responsive: true,
    };
  }

  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') );
  }

  openModal() {
    $('.new-customer-modal').modal('show');
  }

  openImportModal() {
    $('.import-customers-modal').modal('show');
  }

  openExportModal() {
    $('.export-customers-modal').modal('show');
  }

  openQuickAddModal() {
    $('.quick-customer-modal').modal('show');
  }

  addToRevSequence() {
    let sequence = [];
    $('.customer_check:checkbox:checked').each(function() {
      let id = parseInt(this.id.split('_')[1]);
      if($(this).hasClass('notrevsequence'))
        sequence.push(id);
    });
    console.log('sequence', sequence);

    if (sequence.length === 0) {
      Swal.fire('Oops...', 'Please select at least one customer with status<br> "Not in feedback sequence"!', 'error');
    } else {
      let auxForm = this.formBuilder.group({
        sequence: ['', []],
      });
      this.ngZone.run( () => {
        this.spinner = true;
      });
      auxForm.controls.sequence.setValue(JSON.stringify(sequence));
      console.log(auxForm.value);
      this.custService.addToRevSequence(auxForm.value).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            this.alertService.success( sequence.length + ' customers added to review sequence!', false);
            this.loadCustomers();
          },
          error => {
            console.log(error);
            this.alertService.error(error);
          },
          () => {
            this.ngZone.run( () => {
              this.spinner = false;
            });
          }
        );
    }
  }

  deleteAll() {
    let todelete = [];
    $('.customer_check:checkbox:checked').each(function() {
      todelete.push(parseInt(this.id.split('_')[1]));
    });
    console.log(todelete);
    Swal.queue([{
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/customers/delete-sel', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({selected: todelete}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your customers has been deleted.'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to delete your customers.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0])
        this.loadCustomers();
    });
  }

  checkAll() {
    console.log('in');
    if ($('#checkallcustomers')[0]['checked']) {
      console.log('in true');
      $('.customer_check').each(function(){
        $(this).attr('checked', 'true');
      });
    } else {
      console.log('false');
      $('.customer_check').each(function(){
        $(this).removeAttr('checked');
      });
    }
  }

  deleteCustomer(id: number) {
    console.log('delete in', id);

    Swal.queue([{
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/customers/delete/' + id, {
          method: 'get',
          headers: new Headers({
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
        }).then(data => {
          console.log(data);
          console.log(JSON.parse(localStorage.currentUser).token);
          Swal.insertQueueStep({
            type: 'success',
            title: 'Your customer has been deleted.'
          });
        })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to delete your customer.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0])
        this.loadCustomers();
    });
  }

  editCustomer(id: number) {
    console.log('edit in', id);

    this.newCustomerModal.loadCustomer(id);
    this.openModal();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  loadWindowFunctions() {
    window['deleteCustomer'] = (id: number) => {
      console.log('delete in', id);

      Swal.queue([{
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(environment.appUrl + '/api/customers/delete/' + id, {
            method: 'get',
            headers: new Headers({
              Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
            }),
          }).then(data => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your customer has been deleted.'
            });
          })
            .catch((error) => {
              console.log(error);
              Swal.insertQueueStep({
                type: 'error',
                title: 'Unable to delete your customer.'
              });
            });
        }
      }]).then((result) => {
        if (result.value[0])
          this.loadCustomers();
      });
    };
    window['editCustomer'] = (id: number) => {
      console.log('edit in', id);

      this.newCustomerModal.loadCustomer(id);
      this.openModal();
    };
  }

  get self(): CustomersComponent {
    return this;
  }

  myArray(s: string) {
    let n = parseInt(s.split(' ')[0]);
    return new Array(n);
  }

  refreshCustomers() {
    let status = []; let text = '';
    $('#options_source option:selected').each(function () {
      status.push($(this).val());
    });
    text = $('#searchrecord').val().toString();
    let filters = {
      text: text,
      dates: this.daterange,
      status: status,
    };

    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.custService.getAllFilter(filters).pipe(first())
      .subscribe(
        data => {
          console.log(data['data']);
          if (data && data['data']) {
            this.customers = data['data'];
          }
          console.log('success', this.customers);

          if (this.table !== null) {
            this.table.destroy();
          }

          if (this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              // Destroy the table first
              dtInstance.destroy();
              // Call the dtTrigger to rerender again
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
          console.log('dtOptions', this.dtOptions);
          $('.preloader').hide();
        },
        error => {
          console.log('error', error);
          $('.preloader').hide();
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  loadCustomers() {
    $('.preloader').show();
    this.custService.getAll().pipe(first())
      .subscribe(
        data => {
          if (data && data['data']) {
            this.customers = data['data'];
          }
          console.log('success', this.customers);

          if (this.table !== null) {
            this.table.destroy();
          }

          if (this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              // Destroy the table first
              dtInstance.destroy();
              // Call the dtTrigger to rerender again
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
          console.log('dtOptions', this.dtOptions);
          $('.preloader').hide();
        },
        error => {
          console.log('error', error);
          $('.preloader').hide();
        }
      );
  }

}
