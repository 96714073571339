import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {RegisterComponent} from "./register/register.component";
import {AuthGuard} from "./_helpers/auth.guard";
import {ToolsComponent} from "./tools/tools.component";
import {ThemesComponent} from "./themes/themes.component";
import {UsersComponent} from "./users/users.component";
import {BusinessComponent} from "./business/business.component";
import {AgenciesComponent} from "./agencies/agencies.component";
import {UserProfileComponent} from "./user-profile/user-profile.component";
import {BusinessInfoComponent} from "./business-info/business-info.component";
import {BusinessToolsComponent} from "./business-tools/business-tools.component";
import {SocialSharingComponent} from "./social-sharing/social-sharing.component";
import {EmailAlertSettingsComponent} from "./email-alert-settings/email-alert-settings.component";
import {CustomersComponent} from "./customers/customers.component";
import {TemplatesComponent} from "./templates/templates.component";
import {CustomerReviewPageComponent} from "./customer-review-page/customer-review-page.component";
import {CustomerUnsuscribeComponent} from "./customer-unsuscribe/customer-unsuscribe.component";
import {CustomerAfterReviewPageComponent} from "./customer-after-review-page/customer-after-review-page.component";
import {PublicMicroPageComponent} from "./public-micro-page/public-micro-page.component";
import {PublicReviewFormComponent} from "./public-review-form/public-review-form.component";
import {EmailRecordsComponent} from "./email-records/email-records.component";
import {SmsRecordsComponent} from "./sms-records/sms-records.component";
import {ReviewsReforceComponent} from "./reviews-reforce/reviews-reforce.component";
import {PublicReviewsComponent} from "./public-reviews/public-reviews.component";
import {ReviewSequencesComponent} from "./review-sequences/review-sequences.component";
import {CustomerSuggestionsComponent} from "./customer-suggestions/customer-suggestions.component";
import {CustomerFeedbackComponent} from "./customer-feedback/customer-feedback.component";
import {AgencyReviewSummaryComponent} from "./agency-review-summary/agency-review-summary.component";
import {AgencyOnlineReviewsComponent} from "./agency-online-reviews/agency-online-reviews.component";
import {ResetPasswordComponent} from "./reset-password/reset-password.component";
import {NewPasswordComponent} from "./new-password/new-password.component";
import {CustomerStarReviewComponent} from "./customer-star-review/customer-star-review.component";


const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home'} },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'tools', component: ToolsComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> Tools'} },
  { path: 'themes', component: ThemesComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> My Themes'} },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> Users'} },
  { path: 'clients', component: BusinessComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> Clients'} },
  { path: 'agencies', component: AgenciesComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> My Agencies'} },
  { path: 'profile', component: UserProfileComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> My Profile'} },
  { path: 'business', component: BusinessInfoComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> Business Info'} },
  { path: 'business-tools', component: BusinessToolsComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> Business Tools'} },
  { path: 'socialsharing', component: SocialSharingComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> Social Sharing'} },
  { path: 'emailsettings', component: EmailAlertSettingsComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> Email Settings'} },
  { path: 'customers', component: CustomersComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> Customers'} },
  { path: 'templates', component: TemplatesComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> Email Templates'} },
  { path: 'suggestions', component: CustomerSuggestionsComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> Customer Suggestions'} },
  { path: 'email-records', component: EmailRecordsComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> Email Records'} },
  { path: 'sms-records', component: SmsRecordsComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> SMS Records'} },
  { path: 'reviews/local', component: ReviewsReforceComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> Reforce Reviews'} },
  { path: 'reviews/sequences', component: ReviewSequencesComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> Review Sequences'} },
  { path: 'agency/reviews', component: AgencyReviewSummaryComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> Review Summary'} },
  { path: 'agency/reviews/online', component: AgencyOnlineReviewsComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Home -> Online Reviews'} },
  { path: 'public/reviews/:token', component: PublicReviewsComponent },
  { path: 'review/:token', component: CustomerReviewPageComponent },
  { path: 'stars/:token', component: CustomerStarReviewComponent },
  { path: 'customers/unsubscribe/:token', component: CustomerUnsuscribeComponent },
  { path: 'businesses/:option/:token', component: CustomerAfterReviewPageComponent },
  { path: 'public/micropage/:token', component: PublicMicroPageComponent },
  { path: 'public/review/:token', component: PublicReviewFormComponent },
  { path: 'public/feedback/:token', component: CustomerFeedbackComponent },
  { path: 'forgot', component: ResetPasswordComponent },
  { path: 'password/reset/:token/:email', component: NewPasswordComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
