import {Component, Input, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import * as $ from "jquery";
import {CustomersComponent} from "../customers/customers.component";
import {first} from "rxjs/operators";
import {CustomersService} from "../_services/customers.service";
import {Employee} from "../models/employee";
import {EmployeesService} from "../_services/employees.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-quick-add-customer',
  templateUrl: './quick-add-customer.component.html',
  styleUrls: ['./quick-add-customer.component.css']
})
export class QuickAddCustomerComponent implements OnInit {

  private _parent: CustomersComponent ;
  @Input() set parent(value: CustomersComponent ) {
    this._parent = value;
  }

  spinner = false;
  loading = false;
  submmited = false;
  addForm: FormGroup;
  employees: Employee[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private custService: CustomersService,
    private emplService: EmployeesService,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
        employee: ['', Validators.required],
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        phone: ['', []],
        email: ['', [Validators.email]],
        revsequence: ['', []],
        emailpermission: ['', []],
    });
    this.loadEmployees();
  }

  loadEmployees() {
    this.emplService.getAll().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['data'])
            this.employees = data['data'];
        },
        error => {
          console.log(error);
        }
      );
  }

  get f() { return this.addForm.controls; }

  onSubmit() {
    this.submmited = true;

    if (this.addForm.invalid) {
      return;
    }
    this.loading = true;
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.custService.register(this.addForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Registration Successful', false);
          this.parent.loadCustomers();
          this.closeModal();
          if (typeof data['result'] !== 'undefined') {
            if (data['result'] < 0) {
              Swal.fire('Ops...', 'We was was unable to deliver the SMS. Please check the phone number and the sms length has the right format and length.', 'warning');
            }
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  get parent(): CustomersComponent {
    return this._parent;
  }

  openModal() {
    $('.quick-customer-modal').modal('show');
  }

  closeModal() {
    $('.quick-customer-modal').modal('hide');
    this.submmited = false;
    this.addForm.reset();
  }

  showOrHideEmailField() {
    console.log('in email');
    if ($('.cust-email').css('display') === 'none')
      $('.cust-email').show();
    else
      $('.cust-email').hide();
  }

  showOrHidePhoneField() {
    console.log('in phone');
    if ($('.cust-phone').css('display') === 'none')
      $('.cust-phone').show();
    else
      $('.cust-phone').hide();
  }

}
