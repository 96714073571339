import {Component, Input, OnInit} from '@angular/core';
import {TemplatesComponent} from "../templates/templates.component";
import {NewEmailTemplateComponent} from "../new-email-template/new-email-template.component";
import {default_templates} from "../models/default_templates";

@Component({
  selector: 'app-template-designs',
  templateUrl: './template-designs.component.html',
  styleUrls: ['./template-designs.component.css']
})
export class TemplateDesignsComponent implements OnInit {

  private _parent: NewEmailTemplateComponent ;
  @Input() set parent(value: NewEmailTemplateComponent ) {
    this._parent = value;
  }

  subject = '';

  constructor() { }

  ngOnInit() {
  }

  closeModal() {
    $('#template-designs-modal').modal('hide');
  }

  get parent(): NewEmailTemplateComponent {
    return this._parent;
  }

  get self(): TemplateDesignsComponent {
    return this;
  }

  loadTemplates() {
    if (this.parent.templForm.controls.emailtemplate.value === 'initial_feedback') {
      const feedopt = (typeof JSON.parse(localStorage.currentUser).business === 'string') ? JSON.parse(JSON.parse(localStorage.currentUser).business).choice_for_online_review : JSON.parse(localStorage.currentUser).business.choice_for_online_review;
      if (feedopt) {
        $('#home22').html(default_templates.design_1.before + default_templates.initial_feedback.content_internal_on + default_templates.design_1.after);
        $('#profile22').html(default_templates.design_2.before + default_templates.initial_feedback.content_internal_on + default_templates.design_2.after);
        $('#messages22').html(default_templates.design_3.before + default_templates.initial_feedback.content_internal_on + default_templates.design_3.after);
      } else {
        $('#home22').html(default_templates.design_1.before + default_templates.initial_feedback.content_internal_off + default_templates.design_1.after);
        $('#profile22').html(default_templates.design_2.before + default_templates.initial_feedback.content_internal_off + default_templates.design_2.after);
        $('#messages22').html(default_templates.design_3.before + default_templates.initial_feedback.content_internal_off + default_templates.design_3.after);
      }
      this.subject =  default_templates.initial_feedback.subject;
    } else if (this.parent.templForm.controls.emailtemplate.value === 'feedback_reminder') {
      const feedopt = (typeof JSON.parse(localStorage.currentUser).business === 'string') ? JSON.parse(JSON.parse(localStorage.currentUser).business).choice_for_online_review : JSON.parse(localStorage.currentUser).business.choice_for_online_review;
      if (feedopt) {
        $('#home22').html(default_templates.design_1.before + default_templates.feedback_reminder.content_internal_on + default_templates.design_1.after);
        $('#profile22').html(default_templates.design_2.before + default_templates.feedback_reminder.content_internal_on + default_templates.design_2.after);
        $('#messages22').html(default_templates.design_3.before + default_templates.feedback_reminder.content_internal_on + default_templates.design_3.after);
      } else {
        $('#home22').html(default_templates.design_1.before + default_templates.feedback_reminder.content_internal_off + default_templates.design_1.after);
        $('#profile22').html(default_templates.design_2.before + default_templates.feedback_reminder.content_internal_off + default_templates.design_2.after);
        $('#messages22').html(default_templates.design_3.before + default_templates.feedback_reminder.content_internal_off + default_templates.design_3.after);
      }
      this.subject =  default_templates.feedback_reminder.subject;
    } else {
      $('#home22').html(default_templates.design_1.before + default_templates[this.parent.templForm.controls.emailtemplate.value].content + default_templates.design_1.after);
      $('#profile22').html(default_templates.design_2.before + default_templates[this.parent.templForm.controls.emailtemplate.value].content + default_templates.design_2.after);
      $('#messages22').html(default_templates.design_3.before + default_templates[this.parent.templForm.controls.emailtemplate.value].content + default_templates.design_3.after);
      this.subject =  default_templates[this.parent.templForm.controls.emailtemplate.value].subject;
    }
  }


  saveTemplate(name: string) {
    if (name === 'design_1') {
      this.parent.templForm.controls.emailcontent.setValue($('#home22').html());
    } else if (name === 'design_2') {
      this.parent.templForm.controls.emailcontent.setValue($('#profile22').html());
    } else {
      this.parent.templForm.controls.emailcontent.setValue($('#messages22').html());
    }
    this.parent.templForm.controls.subject.setValue(this.subject);
    this.closeModal();
  }
}
