import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import 'bootstrap';
import {Agency} from "../models/agency";
import {AgencyService} from "../_services/agency.service";
import {first} from "rxjs/operators";
import {NewBusinessComponent} from "../new-business/new-business.component";
import {NewAgencyComponent} from "../new-agency/new-agency.component";
import Swal from "sweetalert2";
import {environment} from "../models/enviroment";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";

@Component({
  selector: 'app-agencies',
  templateUrl: './agencies.component.html',
  styleUrls: ['./agencies.component.css']
})
export class AgenciesComponent implements OnInit, OnDestroy {

  @ViewChild(NewAgencyComponent, null) newAgencyModal: NewAgencyComponent;

  agencies = [];
  public table = null;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions = {};
  dtTrigger = new Subject();

  constructor(
    private agencyService: AgencyService,
  ) { }

  ngOnInit() {
    this.loadAgencies();
    this.loadWindowFunctions();
    this.dtOptions = {
      // Declare the use of the extension in the dom parameter
      dom: 'LBlfrtip',
      // Configure the buttons
      buttons: [
        'copy',
        'print',
        'excel',
      ],
      responsive: true,
    };
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  openModal() {
    $('.new-agency-modal').modal('show');
  }

  get self(): AgenciesComponent {
    return this;
  }

  resendCredentials(id: string) {
    console.log('business_id', id);
    Swal.queue([{
      title: 'Are you sure?',
      text: 'You are about to send a reset password link to the current agency!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, send it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/agencies/reset-password', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({selected: id}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            Swal.insertQueueStep({
              type: 'success',
              title: 'The reset link has been send it.'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to send reset link.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0]) {

      }
    });
  }

  deleteAgency(id: string) {
    console.log('delete in', id);

    Swal.queue([{
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/agencies/delete/' + id, {
          method: 'get',
          headers: new Headers({
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
        }).then(data => {
          console.log(data);
          console.log(JSON.parse(localStorage.currentUser).token);
          Swal.insertQueueStep({
            type: 'success',
            title: 'Your agency has been deleted.'
          });
        })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to delete your agency.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0])
        this.loadAgencies();
    });
  }

  editAgency(id: number) {
    console.log('edit in', id);

    this.newAgencyModal.loadAgency(id);
    this.openModal();
  }

  manageAgency(id: string) {

  }

  loadWindowFunctions() {
    window['deleteAgency'] = (id: string) => {
      console.log('delete in', id);

      Swal.queue([{
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(environment.appUrl + '/api/agencies/delete/' + id, {
            method: 'get',
            headers: new Headers({
              Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
            }),
          }).then(data => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your agency has been deleted.'
            });
          })
            .catch((error) => {
              console.log(error);
              Swal.insertQueueStep({
                type: 'error',
                title: 'Unable to delete your agency.'
              });
            });
        }
      }]).then((result) => {
        if (result.value[0])
          this.loadAgencies();
      });

    };
    window['editAgency'] = (id: number) => {
      console.log('edit in', id);

      this.newAgencyModal.loadAgency(id);
      this.openModal();
    };
    window['manageAgency'] = (id: string) => {
      console.log('edit in', id);

    };
  }

  deleteAll() {
    let todelete = [];
    $('.agency_check:checkbox:checked').each(function() {
      todelete.push(parseInt(this.id.split('_')[1]));
    });
    console.log(todelete);
    Swal.queue([{
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/agencies/delete-sel', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({selected: todelete}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your agencies has been deleted.'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to delete your agencies.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0])
        this.loadAgencies();
    });
  }

  checkAll() {
    console.log('in');
    if ($('#checkallagencies')[0]['checked']) {
      console.log('in true');
      $('.agency_check').each(function(){
        $(this).attr('checked', 'true');
      });
    } else {
      console.log('false');
      $('.agency_check').each(function() {
        $(this).removeAttr('checked');
      });
    }
  }

  loadAgencies() {
    $('.preloader').show();
    this.agencyService.getAll().pipe(first())
      .subscribe(
        data => {
          if (data && data['data'])
            this.agencies = data['data'];
          console.log('success', this.agencies);

          if (this.table !== null) {
            this.table.destroy();
          }

          if (this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              // Destroy the table first
              dtInstance.destroy();
              // Call the dtTrigger to rerender again
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
          console.log('dtOptions', this.dtOptions);
          $('.preloader').hide();
        },
        error => {
          console.log('error', error);
          $('.preloader').hide();
        }
      );
  }

}
