import {Component, Input, NgZone, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NewEmailTemplateComponent} from "../new-email-template/new-email-template.component";
import {ReviewSetupComponent} from "../review-setup/review-setup.component";
import {environment} from "../models/enviroment";
import * as $ from "jquery";

@Component({
  selector: 'app-yelp-search-business',
  templateUrl: './yelp-search-business.component.html',
  styleUrls: ['./yelp-search-business.component.css']
})
export class YelpSearchBusinessComponent implements OnInit {

  private _parent: ReviewSetupComponent ;
  @Input() set parent(value: ReviewSetupComponent ) {
    this._parent = value;
  }

  spinner = false;
  submmited = false;
  loading = false;
  rows = [];

  constructor(
    private ngZone: NgZone,
    private httpClient: HttpClient,
    private alertService: AlertService,
  ) { }

  ngOnInit() {}

  myBusiness(alias: string) {
    console.log(alias);
    this.parent.revForm.controls.yelp.setValue(alias);
    $('.yelp-search-business').modal('hide');
    this.rows = [];
  }

  loadYelpBusinesses() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.httpClient.get(environment.appUrl + '/api/businesses/yelp-search')
      .subscribe(
        data => {
          console.log(data);

          if (typeof data['businesses']['businesses'] !== 'undefined') {
            this.rows = data['businesses']['businesses'];
            console.log(this.rows);
          }

          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  get parent(): ReviewSetupComponent {
    return this._parent;
  }

}
