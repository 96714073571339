import {Component, Input, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import * as $ from "jquery";
import {CountriesService} from "../_services/countries.service";
import {StatesService} from "../_services/states.service";
import {CitiesService} from "../_services/cities.service";
import {first} from "rxjs/operators";
import {Country} from "../models/country";
import {Category} from "../models/category";
import {State} from "../models/state";
import {City} from "../models/city";
import {BusinessComponent} from "../business/business.component";
import {CustomersComponent} from "../customers/customers.component";
import {Employee} from "../models/employee";
import {EmployeesService} from "../_services/employees.service";
import {CustomersService} from "../_services/customers.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.css']
})
export class NewCustomerComponent implements OnInit {

  private _parent: CustomersComponent ;
  @Input() set parent(value: CustomersComponent ) {
    this._parent = value;
  }

  public spinner = false;

  loading = false;
  submmited = false;
  custForm: FormGroup;

  countries: Country[] = [];
  states: State[] = [];
  cities: City[] = [];
  employees: Employee[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private countService: CountriesService,
    private stateService: StatesService,
    private cityervice: CitiesService,
    private emplService: EmployeesService,
    private custService: CustomersService,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.custForm = this.formBuilder.group({
      id: ['', []],
      employee: [''],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.email]],
      emailpermission: ['', []],
      phone: ['', []],
      address: ['', []],
      country: ['', []],
      state: ['', []],
      city: ['', []],
      zip: ['', []],
      revsequence: ['', []],
      notes: ['', []],
    });
    //this.loadCountries();
    this.loadEmployees();
  }

  loadCustomer(id: number) {
    if (id >= 0 && id.toString() !== '') {
      this.ngZone.run( () => {
        this.spinner = true;
      });
      console.log('load user in', id);
      this.custService.show(id).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data && data['customer']) {
              this.custForm.controls.id.setValue(id);
              this.custForm.controls.firstname.setValue(data['customer']['first_name']);
              this.custForm.controls.lastname.setValue(data['customer']['last_name']);
              this.custForm.controls.employee.setValue(data['customer']['employee_id']);
              this.loadEmployees();
              this.custForm.controls.email.setValue(data['customer']['email']);
              this.custForm.controls.emailpermission.setValue(data['customer']['permission_to_email']);
              this.custForm.controls.phone.setValue(data['customer']['phone_number']);
              this.custForm.controls.address.setValue(data['customer']['address_line1']);
              this.custForm.controls.country.setValue(data['customer']['country_name']);
              this.custForm.controls.state.setValue(data['customer']['state_name']);
              this.custForm.controls.city.setValue(data['customer']['city_name']);
              this.custForm.controls.zip.setValue(data['customer']['zip']);
              this.custForm.controls.notes.setValue(data['customer']['notes']);
              //this.loadCountries();

            }
          },
          error => {
            console.log(error);
          },
          () => {
            this.ngZone.run( () => {
              this.spinner = false;
            });
          }
        );
    }
  }

  loadEmployees() {
    this.emplService.getAllAdv().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if(data && data['data'])
            this.employees = data['data'];
        },
        error => {
          console.log(error);
        }
      );
  }

  loadCountries() {
    this.countService.getAll().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if(data && data['data'])
            this.countries = data['data'];
          this.loadStates();
        },
        error => {
          console.log(error);
        }
      );
  }

  loadStates() {
    if (this.custForm.controls.country.value !== '') {
      this.stateService.getAll(this.custForm.controls.country.value).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if(data && data['data'])
              this.states = data['data'];
            this.loadCities();
          },
          error => {
            console.log(error);
          }
        );
    }
  }

  loadCities() {
    if (this.custForm.controls.state.value !== '') {
      this.cityervice.getAll(this.custForm.controls.state.value).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if(data && data['data'])
              this.cities = data['data'];
            if(this.custForm.controls.zip.value === '')
              this.custForm.controls.zip.setValue('');
          },
          error => {
            console.log(error);
            this.custForm.controls.zip.setValue('');
          }
        );
    }
  }

  loadZipCode() {
    if (typeof $(".cities option:selected").attr('datazip') !== 'undefined') {
      console.log($(".cities option:selected").attr('datazip'));
      this.custForm.controls.zip.setValue($(".cities option:selected").attr('datazip'));
    } else {
      this.custForm.controls.zip.setValue('');
    }
  }

  get f() { return this.custForm.controls; }

  onSubmit() {
    this.submmited = true;

    if (this.custForm.invalid) {
      return;
    }
    this.loading = true;

    console.log(this.custForm.getRawValue());
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.custService.register(this.custForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Registration Successful', false);
          this.parent.loadCustomers();
          this.closeModal();
          if (typeof data['result'] !== 'undefined') {
            if (data['result'] < 0) {
              Swal.fire('Ops...', 'We was was unable to deliver the SMS. Please check the phone number and the sms length has the right format and length.', 'warning');
            }
          }
          this.loading = false;
        },
        error => {
          console.log('We can\'t edit your profile. Please check all required fields and the email shouldn\'t be in use.');
          this.alertService.error('We can\'t submit the form. Please check all required fields and the email shouldn\'t be in use.');
          this.loading = false;
          this.submmited = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );

  }

  get parent(): CustomersComponent {
    return this._parent;
  }

  openModal() {
    $('.new-customer-modal').modal('show');
  }

  closeModal() {
    $('.new-customer-modal').modal('hide');
    this.submmited = false;
    this.custForm.reset();
  }

}
