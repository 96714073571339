import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RegisterComponent } from './register/register.component';
import { AlertComponent } from './alert/alert.component';
import {FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {JwtInterceptor} from "./_helpers/jwt.interceptor";
import {ErrorInterceptor} from "./_helpers/error.interceptor";
import {fakeBackendProvider} from "./_helpers/fake-backend";
import { ToolsComponent } from './tools/tools.component';
import { ThemesComponent } from './themes/themes.component';
import { UsersComponent } from './users/users.component';
import {DataTablesModule} from "angular-datatables";
import { BusinessComponent } from './business/business.component';
import { AgenciesComponent } from './agencies/agencies.component';
import { EmailSettingsComponent } from './email-settings/email-settings.component';
import { LogoSettingsComponent } from './logo-settings/logo-settings.component';
import {NgxFileDropModule} from "ngx-file-drop";
import { AgencySettingsComponent } from './agency-settings/agency-settings.component';
import { WhiteLabelSettingsComponent } from './white-label-settings/white-label-settings.component';
import {ColorPickerModule} from "ngx-color-picker";
import { CustomCssComponent } from './custom-css/custom-css.component';
import * as RX from "rxjs/Observable";
import {AngularMonacoEditorModule} from "angular-monaco-editor";
import {AceEditorComponent, AceEditorModule} from "ng2-ace-editor";
import { UserProfileComponent } from './user-profile/user-profile.component';
import * as $ from "jquery";
import { BusinessInfoComponent } from './business-info/business-info.component';
import { BusinessFormComponent } from './business-form/business-form.component';
import { BusinessRevPagesComponent } from './business-rev-pages/business-rev-pages.component';
import { BusinessToolsComponent } from './business-tools/business-tools.component';
import { MicroPageSetupComponent } from './micro-page-setup/micro-page-setup.component';
import { FeedbackThresholdComponent } from './feedback-threshold/feedback-threshold.component';
import { ReviewSetupComponent } from './review-setup/review-setup.component';
import { SocialSharingComponent } from './social-sharing/social-sharing.component';
import { EmailAlertSettingsComponent } from './email-alert-settings/email-alert-settings.component';
import { PositiveReviewAlertComponent } from './positive-review-alert/positive-review-alert.component';
import { CustomersComponent } from './customers/customers.component';
import { TemplatesComponent } from './templates/templates.component';
import { NewBusinessComponent} from './new-business/new-business.component';
import { NewAgencyComponent } from './new-agency/new-agency.component';
import { NewEmailTemplateComponent } from './new-email-template/new-email-template.component';
import { NewCustomerComponent } from './new-customer/new-customer.component';
import { ImportCsvCustomersComponent } from './import-csv-customers/import-csv-customers.component';
import { ExportCustomersComponent } from './export-customers/export-customers.component';
import { MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { QuickAddCustomerComponent } from './quick-add-customer/quick-add-customer.component';
import { NewUserComponent } from './new-user/new-user.component';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { BusinessEmployeesComponent } from './business-employees/business-employees.component';
import { NewEmployeeComponent } from './new-employee/new-employee.component';
import { ModalMergeFieldsComponent } from './modal-merge-fields/modal-merge-fields.component';
import {NgxLoadingModule} from "ngx-loading";
import {NgxSpinnerModule} from "ngx-spinner";
import { CustomerReviewPageComponent } from './customer-review-page/customer-review-page.component';
import { CustomerUnsuscribeComponent } from './customer-unsuscribe/customer-unsuscribe.component';
import { CustomerAfterReviewPageComponent } from './customer-after-review-page/customer-after-review-page.component';
import { PreviewEmailPageComponent } from './preview-email-page/preview-email-page.component';
import { PublicMicroPageComponent } from './public-micro-page/public-micro-page.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { PublicReviewFormComponent } from './public-review-form/public-review-form.component';
import { CKEditorModule } from 'ckeditor4-angular';
import {FormModalComponent} from "./form-modal/form-modal.component";
import {BreadcrumbsModule} from "ng6-breadcrumbs";
import {SocialLoginModule, AuthServiceConfig, LoginOpt} from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { AgmCoreModule } from '@agm/core';
import { YelpSearchBusinessComponent } from './yelp-search-business/yelp-search-business.component';
import {MorrisJsModule} from "angular-morris-js";
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { EmailRecordsComponent } from './email-records/email-records.component';
import { SmsRecordsComponent } from './sms-records/sms-records.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { ReviewsReforceComponent } from './reviews-reforce/reviews-reforce.component';
import { PublicReviewsComponent } from './public-reviews/public-reviews.component';
import { ReviewSequencesComponent } from './review-sequences/review-sequences.component';
import { CustomerSuggestionsComponent } from './customer-suggestions/customer-suggestions.component';
import { CustomerFeedbackComponent } from './customer-feedback/customer-feedback.component';
import { AgencyReviewSummaryComponent } from './agency-review-summary/agency-review-summary.component';
import { AgencyOnlineReviewsComponent } from './agency-online-reviews/agency-online-reviews.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { UserProfileModalComponent } from './user-profile-modal/user-profile-modal.component';
import { TemplateDesignsComponent } from './template-designs/template-designs.component';
import { CustomerStarReviewComponent } from './customer-star-review/customer-star-review.component';
import {RatingModule} from "ng-starrating";

const fbLoginOptions: LoginOpt = {
  scope: 'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages',
  return_scopes: true,
  enable_profile_selector: true,
  offline_access: true,
  prompt: 'consent',
}; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

const googleLoginOptions: LoginOpt = {
  scope: 'profile email https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/plus.business.manage',
  offline_access: true,
  prompt: 'consent',
}; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig


// Configs
const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('47303362769-64957dqgdhs9qh9atuokmjt8dj8e6kdf.apps.googleusercontent.com', googleLoginOptions)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('296628491209737', fbLoginOptions)
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    RegisterComponent,
    AlertComponent,
    ToolsComponent,
    ThemesComponent,
    UsersComponent,
    BusinessComponent,
    AgenciesComponent,
    EmailSettingsComponent,
    LogoSettingsComponent,
    AgencySettingsComponent,
    WhiteLabelSettingsComponent,
    CustomCssComponent,
    UserProfileComponent,
    BusinessInfoComponent,
    BusinessFormComponent,
    BusinessRevPagesComponent,
    BusinessToolsComponent,
    MicroPageSetupComponent,
    FeedbackThresholdComponent,
    ReviewSetupComponent,
    SocialSharingComponent,
    EmailAlertSettingsComponent,
    PositiveReviewAlertComponent,
    CustomersComponent,
    TemplatesComponent,
    NewBusinessComponent,
    NewAgencyComponent,
    NewEmailTemplateComponent,
    NewCustomerComponent,
    ImportCsvCustomersComponent,
    ExportCustomersComponent,
    QuickAddCustomerComponent,
    NewUserComponent,
    BusinessEmployeesComponent,
    NewEmployeeComponent,
    ModalMergeFieldsComponent,
    CustomerReviewPageComponent,
    CustomerUnsuscribeComponent,
    CustomerAfterReviewPageComponent,
    PreviewEmailPageComponent,
    PublicMicroPageComponent,
    PublicReviewFormComponent,
    FormModalComponent,
    YelpSearchBusinessComponent,
    EmailRecordsComponent,
    SmsRecordsComponent,
    ReviewsReforceComponent,
    PublicReviewsComponent,
    ReviewSequencesComponent,
    CustomerSuggestionsComponent,
    CustomerFeedbackComponent,
    AgencyReviewSummaryComponent,
    AgencyOnlineReviewsComponent,
    ResetPasswordComponent,
    NewPasswordComponent,
    UserProfileModalComponent,
    TemplateDesignsComponent,
    CustomerStarReviewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    NgxFileDropModule,
    ColorPickerModule,
    CKEditorModule,
    FormsModule,
    AceEditorModule,
    MultiSelectAllModule,
    NgbModule,
    NgxLoadingModule.forRoot({}),
    NgxSpinnerModule,
    LeafletModule.forRoot(),
    BreadcrumbsModule,
    SocialLoginModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC2FSeGvQewWVlBvsnfz7E4m3HIu_acodU',
      libraries: ['places']
    }),
    MorrisJsModule,
    NgxDaterangepickerMd.forRoot(),
    Ng2GoogleChartsModule,
    RatingModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
