import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {Emailtemplate} from "../models/emailtemplate";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import * as $ from "jquery";
import {first} from "rxjs/operators";
import {environment} from "../models/enviroment";
import Swal from "sweetalert2";
import {ReviewServiceService} from "../_services/review-service.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-reviews-reforce',
  templateUrl: './reviews-reforce.component.html',
  styleUrls: ['./reviews-reforce.component.css']
})
export class ReviewsReforceComponent implements OnInit, OnDestroy {

  spinner = false;
  reviews = [];
  results = [];
  table = null;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions = {};
  dtTrigger = new Subject();

  public filters: { [key: string]: Object; }[] = [
    { Name: 'Reforce', Code: 'Reforce' },
    { Name: 'Google', Code: 'Google' },
    { Name: 'Yelp', Code: 'Yelp' },
    { Name: 'Facebook', Code: 'Facebook' },
  ];

  public localFields: Object = { text: 'Name', value: 'Code' };
  public localWaterMark: string = 'Filter by source...';

  public filters2: { [key: string]: Object; }[] = [
    { Name: '1 Star', Code: '1 Star' },
    { Name: '2 Star', Code: '2 Star' },
    { Name: '3 Star', Code: '3 Star' },
    { Name: '4 Star', Code: '4 Star' },
    { Name: '5 Star', Code: '5 Star' },
  ];
  public localFields2: Object = { text: 'Name', value: 'Code' };
  public localWaterMark2: string = 'Filter by rating...';

  public filters3: { [key: string]: Object; }[] = [
    { Name: 'Public', Code: 'Public' },
    { Name: 'Private', Code: 'Private' },
  ];
  public localFields3: Object = { text: 'Name', value: 'Code' };
  public localWaterMark3: string = 'Filter by status...';

  overall_rating = 0;
  total_reviews = 0;
  breakout = {
    rating_1: 0,
    rating_2: 0,
    rating_3: 0,
    rating_4: 0,
    rating_5: 0,
  };
  breakout_sum = {
    reforce: 0,
    yelp: 0,
    google: 0,
    facebook: 0,
  };
  breakout_count = {
    reforce: 0,
    yelp: 0,
    google: 0,
    facebook: 0,
  };

  submmited = false;
  loading = false;
  googleReplyForm: FormGroup;
  spinner2 = false;
  userimg = '';
  userreview = '';
  userrating = [];
  username = '';
  replyTxt = '';
  replyDate = '';
  created = '';

  submmited2 = false;
  loading2 = false;
  reforceReplyForm: FormGroup;
  spinner3 = false;
  userimg2 = '';
  userreview2 = '';
  userrating2 = [];
  username2 = '';
  replyTxt2 = '';
  replyDate2 = '';
  created2 = '';
  replies = [];

  constructor(
    private ngZone: NgZone,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private reviewServ: ReviewServiceService,
    private formBuilder: FormBuilder,
  ) { }

  onSubmit() {
    this.submmited = true;
    if(!this.googleReplyForm.valid)
      return;
    this.ngZone.run( () => {
      this.loading = true;
      this.submmited = true;
      this.spinner2 = true;
    });

    this.reviewServ.answerGoogleReview(this.googleReplyForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (typeof data['data'] !== 'undefined') {
            this.alertService.success('Answer Submitted', false);
          } else if (data['info'] !== 'undefined') {
            this.alertService.error(data['info'], false);
          } else {
            this.alertService.error(data['error'], false);
          }
          this.googleReplyForm.reset();
          this.closeModal();
          this.loading = false;

          this.loadReviews();
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner2 = false;
            this.submmited = false;
          });
        }
      );

  }

  onSubmit2() {
    this.submmited2 = true;
    if (!this.reforceReplyForm.valid)
      return;
    this.ngZone.run( () => {
      this.loading2 = true;
      this.submmited2 = true;
      this.spinner3 = true;
    });

    this.reviewServ.answerReforceReview(this.reforceReplyForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (typeof data['data'] !== 'undefined') {
            this.alertService.success('Answer Submitted', false);
          } else if (data['info'] !== 'undefined') {
            this.alertService.error(data['info'], false);
          } else {
            this.alertService.error(data['error'], false);
          }
          this.reforceReplyForm.reset();
          this.closeModal2();
          this.loading2 = false;

          this.loadReviews();
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading2 = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner3 = false;
            this.submmited2 = false;
          });
        }
      );

  }

  closeModal() {
    $('.new-google-reply').modal('hide');
  }

  closeModal2() {
    $('.new-reforce-reply').modal('hide');
  }

  get f() {
    return this.googleReplyForm.controls;
  }

  get fr() {
    return this.reforceReplyForm.controls;
  }

  ngOnInit() {
    this.googleReplyForm = this.formBuilder.group({
      reply: ['', [Validators.required, Validators.maxLength(4000)]],
      link: ['', []],
    });
    this.reforceReplyForm = this.formBuilder.group({
      reply: ['', [Validators.required, Validators.maxLength(4000)]],
      link: ['', []],
    });
    this.loadReviews();
    this.dtOptions = {
      // Declare the use of the extension in the dom parameter
      dom: 'LBlfrtip',
      // Configure the buttons
      buttons: [
        'copy',
        'print',
        {
          extend: 'excelHtml5',
          title: 'email-templates-excel',
        },
      ],
      responsive: true,
    };
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  get self(): ReviewsReforceComponent {
    return this;
  }

  checkAll() {
    console.log('in');
    if ($('#checkallreviews')[0]['checked']) {
      console.log('in true');
      $('.review_check').each(function() {
        $(this).attr('checked', 'true');
      });
    } else {
      console.log('false');
      $('.review_check').each(function() {
        $(this).removeAttr('checked');
      });
    }
  }

  filterReviews() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    let source = []; let stars = []; let text = ''; let status = [];
    $('#options_source option:selected').each(function () {
      source.push($(this).val());
    });
    $('#options_stars option:selected').each(function () {
      stars.push($(this).val());
    });
    $('#options_status option:selected').each(function () {
      status.push($(this).val());
    });
    text = $('#searchrecord').val().toString();
    console.log('text', text);
    console.log('source', source);
    console.log('stars', stars);

    let aux = []; this.results = [];
    for (let review of this.reviews) {
      let btext = true; let bsource = true; let bstars = true; let bstatus = true;
      if (text !== '') {
        if (typeof review.text === 'undefined' || (review.text.indexOf(text) === -1 && review.name.indexOf(text) === -1))
          btext = false;
      }
      if (source.length > 0) {
        let b = false;
        for (let s of source) {
          if (review.icon_class.indexOf(s.toLowerCase()) !== -1 || (review.icon_class.indexOf('comment') !== -1 && s === 'Reforce'))
            b = true;
        }
        bsource = b;
      }
      if (stars.length > 0) {
        if (stars.indexOf(review.rating.length + ' Star') === -1) {
          bstars = false;
        }
      }
      if (status.length > 0) {
        if ((status.indexOf('Public') !== -1 && status.indexOf('Private') === -1 && !review.approved) || (status.indexOf('Public') === -1 && status.indexOf('Private') !== -1 && review.approved)) {
          bstatus = false;
        }
      }
      if (bstars && bsource && btext && bstatus) {
        aux.push(review);
      } else {
        console.log(bstars+' '+bsource+' '+btext+' '+bstatus);
      }
    }
    this.ngZone.run( () => {
      console.log('aux', aux);
      this.results = aux;
      this.spinner = false;

      if (this.table !== null) {
        this.table.destroy();
      }

      if (this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      } else {
        this.dtTrigger.next();
      }
    });
  }

  publishSelected() {
    let rows = [];
    $('.review_check:checkbox:checked').each(function() {
      rows.push(this.id.split('<>')[1]);
    });
    console.log(rows);
    Swal.queue([{
      title: 'Are you sure?',
      text: "You are about to make public all selected reviews!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, publish them!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/reviews/publish', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({selected: rows}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your reviews has been published.'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to publish your reviews.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0])
        this.loadReviews();
    });
  }

  publishAll() {
    let rows = [];
    for(let review of this.results)
      rows.push(review.id);
    console.log(rows);
    Swal.queue([{
      title: 'Are you sure?',
      text: "You are about to make public all the reviews listed!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, publish them!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/reviews/publish', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({selected: rows}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your reviews has been published.'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to publish your reviews.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0])
        this.loadReviews();
    });
  }

  hideAll() {
    let rows = [];
    for(let review of this.results)
      rows.push(review.id);
    console.log(rows);
    Swal.queue([{
      title: 'Are you sure?',
      text: "You are about to make private all listed reviews!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, hide them!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/reviews/hide', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({selected: rows}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your reviews has been unpublished.'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to hide your reviews.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0])
        this.loadReviews();
    });
  }

  hideSelected() {
    let rows = [];
    $('.review_check:checkbox:checked').each(function() {
      rows.push(this.id.split('<>')[1]);
    });
    console.log(rows);
    Swal.queue([{
      title: 'Are you sure?',
      text: "You are about to make private all selected reviews!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, hide them!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/reviews/hide', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({selected: rows}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your reviews has been unpublished.'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to hide your reviews.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0])
        this.loadReviews();
    });
  }

  publishOne(id: string) {
    Swal.queue([{
      title: 'Are you sure?',
      text: "You are about to make public this review!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, publish it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/reviews/publish/one', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({id: id}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your review has been published.'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to publish your review.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0])
        this.loadReviews();
    });
  }

  hideOne(id: string) {
    Swal.queue([{
      title: 'Are you sure?',
      text: "You are about to make private this review!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, hide it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch(environment.appUrl + '/api/reviews/hide/one', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
          }),
          body: JSON.stringify({id: id}),
        }).then(response => response.json())
          .then((data) => {
            console.log(data);
            console.log(JSON.parse(localStorage.currentUser).token);
            Swal.insertQueueStep({
              type: 'success',
              title: 'Your review has been unpublished.'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.insertQueueStep({
              type: 'error',
              title: 'Unable to hide your review.'
            });
          });
      }
    }]).then((result) => {
      if (result.value[0])
        this.loadReviews();
    });
  }

  loadReviews() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.httpClient.get(environment.appUrl + '/api/reviews/all').pipe(first())
      .subscribe(
        data => {
          console.log(data);
          let yelp = (data['yelp_reviews'][0] !== null && typeof data['yelp_reviews'][0] !== 'undefined') ? data['yelp_reviews'][0]['reviews'] : [];
          this.reviews = [];
          this.results = [];
          for (let ind in yelp) {
            let newrev = {
              id: 'yelp|' + yelp[ind]['id'],
              rating: new Array(yelp[ind].rating),
              text: yelp[ind].text,
              created: yelp[ind].time_created,
              url: yelp[ind].url,
              image: yelp[ind].user.image_url,
              name: yelp[ind].user.name,
              profile: yelp[ind].user.profile_url,
              icon_class: 'fab fa-yelp',
              approved: yelp[ind].approved,
              answer: yelp[ind].url,
            };
            this.overall_rating += parseInt(yelp[ind].rating);
            this.total_reviews ++;
            this.reviews.push(newrev);
            this.results.push(newrev);
            this.breakout['rating_' + yelp[ind].rating] ++;
            this.breakout_sum['yelp'] += yelp[ind].rating;
            this.breakout_count['yelp'] ++;
          }

          const reforce = data['reforce'];
          if (reforce.length > 0) {
            for (let ind in reforce) {
              let newrev = {
                id: 'reforce|' + reforce[ind]['id'],
                rating: new Array(reforce[ind].rating_star),
                text: reforce[ind].rating_description,
                created: reforce[ind].created_at,
                url: '',
                image: '../../assets/images/generic-avatar.png',
                name: reforce[ind].customer.first_name + ' ' + reforce[ind].customer.last_name,
                profile: '',
                icon_class: 'far fa-comment',
                approved: reforce[ind]['published'] !== null ? 1 : 0,
                answer: 'reforce',
                replies: reforce[ind]['answers'],
              };
              this.overall_rating += parseInt(reforce[ind].rating_star);
              this.total_reviews ++;
              this.reviews.push(newrev);
              this.results.push(newrev);
              this.breakout['rating_' + reforce[ind].rating_star] ++;
              this.breakout_sum['reforce'] += reforce[ind].rating_star;
              this.breakout_count['reforce'] ++;
            }
          }

          let google_rev = data['allreviews'];
          if(typeof google_rev[0] !== 'undefined') {
            for (let ind in google_rev) {
              let newrev = {
                id: 'google|' + google_rev[ind]['reviewId'],
                rating: new Array((google_rev[ind]['starRating'] === 'FIVE') ? 5 : (google_rev[ind]['starRating'] === 'FOUR' ? 4 : (google_rev[ind]['starRating'] === 'THREE' ? 3 : (google_rev[ind]['starRating'] === 'TWO' ? 2 : 1)))),
                text: google_rev[ind].comment,
                created: google_rev[ind]['createTime'],
                url: '',
                image: google_rev[ind]['reviewer']['profilePhotoUrl'],
                name: google_rev[ind]['reviewer']['displayName'],
                profile: '',
                icon_class: 'fab fa-google',
                approved: google_rev[ind].approved,
                answer: google_rev[ind].name,
                reply: (typeof google_rev[ind].reviewReply !== 'undefined' && typeof google_rev[ind].reviewReply.comment !== 'undefined') ? google_rev[ind].reviewReply.comment : '',
                replyUpd: (typeof google_rev[ind].reviewReply !== 'undefined' && typeof google_rev[ind].reviewReply.comment !== 'undefined') ? google_rev[ind].reviewReply.updateTime : '',
              };
              this.overall_rating += newrev.rating.length;
              this.total_reviews++;
              this.reviews.push(newrev);
              this.results.push(newrev);
              this.breakout['rating_' + newrev.rating.length]++;
              this.breakout_sum['google'] += newrev.rating.length;
              this.breakout_count['google']++;
            }
          }

          let facebook_rev = data['facebook'];
          if (typeof facebook_rev !== 'undefined' && typeof facebook_rev[0] !== 'undefined') {
            for (let ind in facebook_rev) {
              let newrev = {
                id: 'facebook|' + facebook_rev[ind]['reviewer']['name'] + '|' + facebook_rev[ind]['created_time'],
                rating:  new Array((facebook_rev[ind]['recommendation_type'] === 'positive') ? 5 : 1),
                text: facebook_rev[ind]['review_text'],
                created: facebook_rev[ind]['created_time'],
                url: '',
                image: '../../assets/images/generic-avatar.png',
                name: facebook_rev[ind]['reviewer']['name'],
                profile: '',
                icon_class: 'fab fa-facebook',
                approved: facebook_rev[ind].approved,
                answer: facebook_rev[ind]['open_graph_story']['id'],
              };
              this.overall_rating += newrev.rating.length;
              this.total_reviews++;
              this.reviews.push(newrev);
              this.results.push(newrev);
              this.breakout['rating_' + newrev.rating.length]++;
              this.breakout_sum['facebook'] += newrev.rating.length;
              this.breakout_count['facebook']++;
            }
          }

          this.overall_rating = (this.total_reviews > 0) ? this.overall_rating/this.total_reviews : 0;
          console.log(this.reviews);

          if (this.table !== null) {
            this.table.destroy();
          }

          if (this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              // Destroy the table first
              dtInstance.destroy();
              // Call the dtTrigger to rerender again
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
          console.log('dtOptions', this.dtOptions);
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  answerReview(review) {
    const link = review.answer;
    const type =  review.id.split('|')[0];
    const text = review.text;
    if (link !== '') {
      if ( type === 'facebook') {
        const previewOpen = window.open('https://www.facebook.com/' + link, "previewOpen", "width=700, height=500");
      } else if ( type === 'google') {
        this.ngZone.run( () => {
          this.userimg = review.image;
          this.userreview = text;
          this.userrating = review.rating;
          this.username = review.name;
          this.replyTxt = review.reply;
          this.replyDate = review.replyUpd;
          this.created = review.created;
        });
        this.googleReplyForm.controls.link.setValue(review.answer);
        $('.new-google-reply').modal('show');
      } else if (type === 'yelp') {
        const previewOpen = window.open(link, "previewOpen", "width=700, height=500");
      } else {
        this.ngZone.run( () => {
          this.userimg2 = review.image;
          this.userreview2 = text;
          this.userrating2 = review.rating;
          this.username2 = review.name;
          this.replies = review.replies;
          this.created2 = review.created;
          console.log(this.replies);
        });
        this.reforceReplyForm.controls.link.setValue(review.id.split('|')[1]);
        $('.new-reforce-reply').modal('show');
      }
    }
  }

}
