import {Component, NgZone, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AlertService} from "../_services/alert.service";
import {ReviewServiceService} from "../_services/review-service.service";
import {ActivatedRoute, Router} from "@angular/router";
import {first} from "rxjs/operators";
import Swal from "sweetalert2";

@Component({
  selector: 'app-customer-feedback',
  templateUrl: './customer-feedback.component.html',
  styleUrls: ['./customer-feedback.component.css']
})
export class CustomerFeedbackComponent implements OnInit {

  spinner = false;
  loading = false;
  submmited = false;
  feedbForm: FormGroup;
  token = '';

  constructor(
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private reviewServ: ReviewServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.token = params['token'];
    });
    this.feedbForm = this.formBuilder.group({
      wrong: ['', Validators.required],
      avoid: ['', Validators.required],
      comments: ['', []],
      customer: ['', Validators.required],
    });
  }

  onSubmit() {
    this.feedbForm.controls.customer.setValue(this.token);
    this.submmited = true;
    if (!this.feedbForm.valid || this.token === '') {
      return;
    }
    this.loading = true;
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.reviewServ.registerFeedback(this.feedbForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          Swal.fire('We got it ..', 'We appreciated your feedback.', 'success');
          this.feedbForm.reset();
          this.loading = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
            this.submmited = false;
          });
        }
      );
  }

  get f(){
    return this.feedbForm.controls;
  }

}
