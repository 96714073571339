import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Category} from "../models/category";
import {State} from "../models/state";
import {environment} from "../models/enviroment";

@Injectable({
  providedIn: 'root'
})
export class StatesService {

  constructor(private http: HttpClient) { }

  getAll(country_id: number) {
    return this.http.get<State[]>(environment.appUrl + `/api/states/${country_id}`);
  }
}
