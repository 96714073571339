import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {CustomersService} from "../_services/customers.service";
import {first} from "rxjs/operators";
import {StarRatingComponent} from "ng-starrating";

@Component({
  selector: 'app-customer-star-review',
  templateUrl: './customer-star-review.component.html',
  styleUrls: ['./customer-star-review.component.css']
})
export class CustomerStarReviewComponent implements OnInit {

  token = '';
  public spinner = false;
  stars = 1;
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private ngZone: NgZone,
    private custService: CustomersService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.token = params['token'];
      console.log(this.token);
    });
  }

  onRate($event: {oldValue: number, newValue: number, starRating: StarRatingComponent}) {
    // alert(`Old Value:${$event.oldValue},
    //   New Value: ${$event.newValue},
    //   Checked Color: ${$event.starRating.checkedcolor},
    //   Unchecked Color: ${$event.starRating.uncheckedcolor}`);
    this.stars = $event.newValue;
  }

  submitReview(): void {
    this.loading = true;
    this.ngZone.run( () => {
      this.spinner = true;
    });
    const starForm = {
      token: this.token,
      stars: this.stars,
      comment: $('#review_comments').val()
    };
    this.custService.sendStarReview(starForm).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['customer_token']) {
            if (this.stars > 3) {
              this.router.navigate([`/businesses/yes/${data['customer_token']}`]);
            } else {
              this.router.navigate([`/businesses/no/${data['customer_token']}`]);
            }
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
          this.loading = false;
        }
      );
  }

  sendReview(option: string) {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    console.log('option', option);
    console.log('token', this.token);
    this.custService.sendReview(option, this.token).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['customer_token']) {
            this.router.navigate([`/businesses/${option}/${data['customer_token']}`]);
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

}
