import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry} from "ngx-file-drop";
import {first} from "rxjs/operators";
import * as $ from "jquery";
import {CategoriesService} from "../_services/categories.service";
import {CountriesService} from "../_services/countries.service";
import {StatesService} from "../_services/states.service";
import {CitiesService} from "../_services/cities.service";
import {Country} from "../models/country";
import {Category} from "../models/category";
import {State} from "../models/state";
import {City} from "../models/city";
import {AgencyService} from "../_services/agency.service";
import {environment} from "../models/enviroment";

@Component({
  selector: 'app-agency-settings',
  templateUrl: './agency-settings.component.html',
  styleUrls: ['./agency-settings.component.css']
})
export class AgencySettingsComponent implements OnInit {

  file = null;
  public spinner = false;

  countries: Country[] = [];
  categories: Category[] = [];
  states: State[] = [];
  cities: City[] = [];

  filesubmmit = false;
  filepercent = 0;

  public imagePath;
  imgURL: any;
  public message: string;

  loading = false;
  submmited = false;
  settingsForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private catService: CategoriesService,
    private countService: CountriesService,
    private stateService: StatesService,
    private cityervice: CitiesService,
    private agencyService: AgencyService,
  ) { }

  ngOnInit() {
    console.log('agency settings in');

    this.settingsForm = this.formBuilder.group({
        agency_id: ['', []],
        firstname: ['', [Validators.required]],
        lastname: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        secondemail: ['', [Validators.email]],
        name: ['', [Validators.required]],
        addressline1: ['', [Validators.required]],
        addressline2: ['', []],
        city: ['', [Validators.required]],
        zip: ['', [Validators.required]],
        country: ['', [Validators.required]],
        state: ['', [Validators.required]],
        phone: ['', [Validators.required]],
        webaddress: ['', []],
        subdomain: ['', []],
        domain: ['', []],
        logo: ['', []],
        hidenlogo: ['', []],
    });

    //this.loadCountries();
    this.loadForm();
  }

  onFileChanged(event) {
    this.file = event.target.files[0];
    console.log(this.file);

    if (this.file.type.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    } else {
      this.message = null;
    }

    let reader = new FileReader();
    this.imagePath = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }

    this.filesubmmit = true;
    const uploadData = new FormData();
    uploadData.append('myFile', this.file, this.file.name);
    this.httpClient.post(environment.appUrl + '/api/businesses/logo-upload', uploadData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(event => {
      this.filepercent += 25;
      $('.logoupload').html('<div class="progress-bar bg-primary logoupload" style="width: '+ this.filepercent +'%; height:10px;" role="progressbar">'+ this.filepercent +'%</div>');
      console.log(event); // handle event here
      if (event['body'] && event['body'].logo) {
        this.settingsForm.controls.hidenlogo.setValue(event['body'].logo);
        this.filesubmmit = false;
        this.filepercent = 0;
      }
    });
  }

  loadForm() {
    this.spinner = true;
    this.agencyService.getCurrent().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['agency']) {
            let agency = data['agency'];
            this.settingsForm.controls.agency_id.setValue(agency.id);
            this.settingsForm.controls.name.setValue(agency.agency_name);
            this.settingsForm.controls.firstname.setValue(agency.first_name);
            this.settingsForm.controls.lastname.setValue(agency.last_name);
            this.settingsForm.controls.addressline1.setValue(agency.address_line1);
            this.settingsForm.controls.addressline2.setValue(agency.address_line2);
            this.settingsForm.controls.zip.setValue(agency.zip);
            this.settingsForm.controls.phone.setValue(agency.phone);
            this.settingsForm.controls.webaddress.setValue(agency.company_web_address);
            this.settingsForm.controls.email.setValue(agency.email);
            this.settingsForm.controls.domain.setValue(agency.domain_name);
            this.settingsForm.controls.subdomain.setValue(agency.subdomain_name);
            this.settingsForm.controls.country.setValue(agency.country_name);
            //this.loadStates();
            this.settingsForm.controls.state.setValue(agency.state_name);
            //this.loadCities();
            this.settingsForm.controls.city.setValue(agency.city_name);
            this.settingsForm.controls.secondemail.setValue(agency.aditional_email);
            this.settingsForm.controls.hidenlogo.setValue(agency.agency_logo);
            this.imgURL = agency.agency_logo;
            this.spinner = false;
          }
        },
        error => {
          console.log(error);
          this.spinner = false;
        }
      );
  }

  get f() { return this.settingsForm.controls; }

  onSubmit() {
    console.log('logo settings submit in');

    this.submmited = true;
    if (this.settingsForm.invalid) {
      return;
    }
    this.spinner = true;
    this.loading = true;
    // next service to save the settings
    console.log(this.settingsForm.getRawValue());

    this.agencyService.updateInfo(this.settingsForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Update Successful', false);
          this.loading = false;
          this.spinner = false;
        },
        error => {
          console.log(error);
          this.alertService.error(error);
          this.loading = false;
          this.spinner = false;
        }
      );
  }

  loadCategories() {
    this.spinner = true;
    this.catService.getAll().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['data'])
            this.categories = data['data'];
          this.spinner = false;
        },
        error => {
          console.log(error);
          this.spinner = false;
        }
      );
  }

  loadCountries() {
    this.spinner = true;
    this.countService.getAll().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['data'])
            this.countries = data['data'];
          this.spinner = false;
        },
        error => {
          console.log(error);
          this.spinner = false;
        }
      );
  }

  loadStates() {
    this.spinner = true;
    if (this.settingsForm.controls.country.value !== '') {
      this.stateService.getAll(this.settingsForm.controls.country.value).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data && data['data'])
              this.states = data['data'];
            this.spinner = false;
          },
          error => {
            console.log(error);
            this.spinner = false;
          }
        );
    }
  }

  loadCities() {
    this.spinner = true;
    if (this.settingsForm.controls.state.value !== '') {
      this.cityervice.getAll(this.settingsForm.controls.state.value).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data && data['data'])
              this.cities = data['data'];
            this.spinner = false;
          },
          error => {
            console.log(error);
            this.spinner = false;
          }
        );
    }
  }

  loadZipCode() {
    if (typeof $(".cities option:selected").attr('datazip') !== 'undefined') {
      console.log($(".cities option:selected").attr('datazip'));
      this.settingsForm.controls.zip.setValue($(".cities option:selected").attr('datazip'));
    } else {
      this.settingsForm.controls.zip.setValue('');
    }
  }

}
