import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SocialMedia} from "../models/SocialMedia";
import {environment} from "../models/enviroment";

@Injectable({
  providedIn: 'root'
})
export class ReviewServiceService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<SocialMedia[]>(environment.appUrl + `/api/reviews/all`);
  }

  getFromSource(source: string) {
    return this.http.get<SocialMedia[]>(environment.appUrl + `/api/reviews/${source}/all`);
  }

  register(review) {
    return this.http.post(environment.appUrl + `/api/reviews/store`, review);
  }

  registerFeedback(feedback) {
    return this.http.post(environment.appUrl + `/api/reviews/store/negative-feedback`, feedback);
  }

  publishOne(id: string) {
    return this.http.get(environment.appUrl + `/api/reviews/publish/${id}`);
  }

  hidehOne(id: string) {
    return this.http.get(environment.appUrl + `/api/reviews/hide/${id}`);
  }

  answerGoogleReview(answer) {
    return this.http.post(environment.appUrl + `/api/reviews/googleanswer`, answer);
  }

  answerReforceReview(answer) {
    return this.http.post(environment.appUrl + `/api/reviews/reforceanswer`, answer);
  }

}
