import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../_services/authentication.service";
import {AlertService} from "../_services/alert.service";
import {first} from "rxjs/operators";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading =  false;
  submmited =  false;
  returnUrl: string;
  showLogin = true;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
  ) {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {
    console.log("login init");
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
    console.log('this.returnUrls', this.returnUrl);

    $(function () {
      $('.preloader').fadeOut();
    });
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submmited = true;

    if (this.loginForm.invalid) {
      return;
    }
    console.log('login comp in');
    this.loading = true;
    this.authenticationService.login2(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          console.log('login comp data in');
          console.log('this.returnUrl', this.returnUrl);
          //this.router.navigate([this.returnUrl]);
          location.href = this.returnUrl;
          },
        error => {
          console.log('login comp data out');
          this.alertService.error(error);
        }
      );
  }


}
