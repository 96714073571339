import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertService} from "../_services/alert.service";
import {UploadAdapter} from "../models/uploadAdapter";
import {environment} from "../models/enviroment";
import {AgencyService} from "../_services/agency.service";
import {first} from "rxjs/operators";

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.css']
})
export class EmailSettingsComponent implements OnInit {

  settingsForm: FormGroup;
  loading = false;
  submmited = false;

  // editor
  // name = 'ng2-ckeditor';
  ckeConfig: any;
  // mycontent: string;
  log: string = '';
  // @ViewChild('myckeditor', null) ckeditor: any;
  public spinner = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private agencyService: AgencyService
  ) {
    // this.mycontent = ``;
  }

  public onReady( editor ) {
    console.log('TheUploadAdapterPlugin called');

  }

  ngOnInit() {
    console.log('email settings init');
    this.ckeConfig = {
      extraPlugins: 'justify',
      filebrowserImageBrowseUrl: environment.appUrl + '/ckfinder/browser?command=QuickUpload&type=Images',
      filebrowserImageUploadUrl: environment.appUrl + '/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images'
    };

    this.settingsForm = this.formBuilder.group({
        username: ['', Validators.required],
        senderName: ['', Validators.required],
        senderEmail: ['', [Validators.required, Validators.email]],
        emailBody: ['', Validators.required],
    });
    this.loadForm();
  }

  loadForm() {
    this.spinner = true;
    this.agencyService.getEmailSettings().pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['agencyEmailSettings']) {
            const agencyEmailSettings = data['agencyEmailSettings'];
            this.settingsForm.controls.username.setValue(agencyEmailSettings.username);
            this.settingsForm.controls.senderName.setValue(agencyEmailSettings.sender_name);
            this.settingsForm.controls.senderEmail.setValue(agencyEmailSettings.sender_email);
            this.settingsForm.controls.emailBody.setValue(agencyEmailSettings.email_body);
          }
          this.spinner = false;
        },
        error => {
          console.log(error);
          this.spinner = false;
        }
      );
  }

  onChange($event: any): void {
    console.log('onChange');

  }

  onSubmit() {
    console.log('settings submit in');

    this.submmited = true;
    if (this.settingsForm.invalid) {
      return;
    }
    this.agencyService.updateEmailSettings(this.settingsForm.value).subscribe(response => {
      this.alertService.success('Update Successful', false);
    });

    this.loading = true;
    // next service to save the settings

  }

  get f() { return this.settingsForm.controls; }

}
