import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {first} from "rxjs/operators";
import {BusinessesService} from "../_services/businesses.service";
import Swal from "sweetalert2";
import {ReviewServiceService} from "../_services/review-service.service";
import {AlertService} from "../_services/alert.service";
import {environment} from "../models/enviroment";

@Component({
  selector: 'app-public-review-form',
  templateUrl: './public-review-form.component.html',
  styleUrls: ['./public-review-form.component.css']
})
export class PublicReviewFormComponent implements OnInit {

  reviewForm: FormGroup;
  loading = false;
  submmited = false;
  public token = '';
  spinner = false;

  business = null;
  city = null;
  state = null;
  country = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private busService: BusinessesService,
    private reviewService: ReviewServiceService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.token = params['token'];
      console.log(this.token);
    });
    this.reviewForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', []],
      full_address: ['', []],
      rating: ['', Validators.required],
      comments: ['', []],
      authorize: ['', []],
      business: [this.token, []],
      suggestion: ['', []],
    });
    this.loadBusiness();
  }

  get f() { return this.reviewForm.controls; }

  goToMicroPage() {
    this.router.navigate(['public/micropage/' + this.token]);
  }

  loadBusiness() {
    this.ngZone.run( () => {
      this.spinner = true;
    });
    console.log('token', this.token);
    this.busService.getByKey(this.token).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data && data['business']) {
            this.business = data['business'];
            this.state = data['state_name'];
            this.city = data['city_name'];
            this.country = data['country_name'];
          }
          this.test();
        },
        error => {
          console.log(error);
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );
  }

  onSubmit() {
    this.submmited = true;
    if (this.reviewForm.invalid) {
      return;
    }
    const agree = this.reviewForm.controls.authorize.value;
    console.log('agree', agree);
    if (typeof  agree === 'undefined' || !agree || agree === 'false') {
      Swal.fire('Ops...','Please check the option authorizing to post your review online and confirming we have done business.','warning');
      return;
    }
    this.loading = true;
    console.log(this.reviewForm.getRawValue());
    this.ngZone.run( () => {
      this.spinner = true;
    });
    this.reviewService.register(this.reviewForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (this.reviewForm.controls.rating.value < 4) {
            Swal.fire({
              title: 'Do you have any suggestion for our service?',
              input: 'textarea',
              inputAttributes: {
                autocapitalize: 'off'
              },
              showCancelButton: true,
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: (login) => {
                console.log('login', login);
                console.log('customer', data['customer']);
                return fetch(environment.appUrl + '/api/suggestions/new', {
                  method: 'POST',
                  headers: new Headers({
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${JSON.parse(localStorage.currentUser).token}`,
                  }),
                  body: JSON.stringify({suggestion: login, customer: data['customer']['id'], name: (data['customer']['first_name'] + ' ' + data['customer']['last_name'])}),
                }).then(response => {
                    if (!response.ok) {
                      throw new Error(response.statusText);
                    }
                    return response.json();
                  })
                  .catch(error => {
                    Swal.showValidationMessage(
                      `Request failed: ${error}`
                    );
                  });
              },
              allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              if (result) {
                Swal.fire('We got it ...', 'Thanks for your feedback!', 'success');
              }
            });
          } else {
            Swal.fire('We got it ...', 'Thanks for your feedback!', 'success');
          }
          this.reviewForm.reset();
          this.loading = false;
          this.submmited = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        },
        () => {
          this.ngZone.run( () => {
            this.spinner = false;
          });
        }
      );

  }

  test() {

  }

}
